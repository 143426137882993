import { useEffect } from "react";
import { deleteVideoLink, getVideoLinks, openVideoCreationModal, openVideoDeleteModal } from "../../store/slices/Videos/VideoLinkSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

export const useVideos = () => {
    const dispatch = useDispatch()
    const { resultData, status, videoActions, isDeletingLink } = useSelector((state) => state?.videos);
    const [openPreview, setOpenPreview] = useState(false)
    const [previewActions, setPreviewActions] = useState({
        isOpen: false,
        url: ""
    })


    useEffect(() => {
        if (status == "idle") {
            let obj = {
                page: "1",
                slug: "",
            }
            getAllImages(obj)
        }
    }, []);

    const getAllImages = async (obj) => {
        const resultAction = await dispatch(getVideoLinks(obj));
        if (getVideoLinks.fulfilled.match(resultAction)) {
            loadMore(resultAction?.payload?.data?.result);
        }
    }

    const loadMore = async (video) => {
        console.log(video, "hey laod moore...")
        if (video?.current_page < video?.last_page) {
            let obj = {
                page: video?.current_page + 1,
                slug: "desc",
            };

            const resultAction = await dispatch(getVideoLinks(obj));
            if (getVideoLinks.fulfilled.match(resultAction)) {
                loadMore(resultAction?.payload?.data?.result);
            }


        }
        // console.log(video,"hey laod moore...")

    }


    const handleOpenPreview = (item) => {
        setPreviewActions({
            isOpen: true,
            url: item.video_url
        })
    }


    const handleClosePreview = (item) => {
        setPreviewActions({
            isOpen: false,
            url: ""
        })
    }

    const handleDeleteVideoLink = async () => {
        let video_ids = videoActions.selected_Ids
        console.log(video_ids, videoActions, "hey delette")
        const resultAction = await dispatch(deleteVideoLink({ video_id: video_ids }));
        if (deleteVideoLink.fulfilled.match(resultAction)) {
            handleCloseDeleteModal()
        }
    }


    const handleCloseDeleteModal = () => {
        dispatch(openVideoDeleteModal({
            isOpen: false,
            selected_Ids: []
        }))
    }

    const handleDelete = async (data) => {
        dispatch(openVideoDeleteModal({
            isOpen: true,
            id: data.id
        }))
    }

    const handleCloseVideoModal = () => {
        dispatch(openVideoCreationModal({
            isOpen: false,
            mode: "all",
            currentData: {}
        }))
    }

    const handleEdit = (data) => {
        dispatch(openVideoCreationModal({
            isOpen: true,
            mode: "edit",
            currentData: data
        }))
    }

    const NodataContent = {
        title: `You haven’t added any Video Links`,
        description: `Add your Links now to bring your family story to life.`,

    };



    return {
        resultData,
        status,
        videoActions,
        isDeletingLink,
        openPreview,
        previewActions,
        NodataContent,
        handleOpenPreview,
        handleClosePreview,
        setOpenPreview,
        handleEdit,
        handleDelete,
        handleCloseDeleteModal,
        handleCloseVideoModal,
        handleDeleteVideoLink
    }
}