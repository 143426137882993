import React, { useState, useEffect, useRef } from "react";
import Style from "../EditProfilePage.module.scss";
import Assets from "../../Layouts/Assets";
import { ErrorMessage, useFormik } from "formik";
import { Formik, Field, Form } from "formik";
import { usePhoneUpdate } from "../../../Logic/usePhoneUpdate";
import CountryCodeInput from "../../../utils/CountryCodeInput";
import * as Yup from "yup";
import { useSelector } from "react-redux";


let hasErrorsPhone = false;

const ComponentPhoneUpdateForm = ({ ClosePopup }) => {

  const [NewPhone, setNewPhone] = useState("");
  const [ConfirmPhone, setConfirmPhone] = useState("");
  const [Password, setPassword] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [isKeyDown, setIsKeyDown] = useState(false);


  const { currentAuthData } = useSelector(
    (state) => state.auth
  );

  const {
    PhoneChangeSubmit,
    capitalizeFirstLetter,
    success,
    error_message,
    formError,
    submitButtonLabel,
    setMessage
  } = usePhoneUpdate();

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const SignupSchema = Yup.object().shape({
    phone_number: Yup.string()
      .matches(phoneRegExp, "Invalid mobile number")
      .required("Mobile number is required")
      .min(10, "Digits in phone number should be minimum 10")
      .max(10, "Digits in phone Number should be maximum 10"),
    password: Yup.string()
      .required("Password is required.")
      .min(8, "Password lentgh must be a minimum of eight."),
  });


  function NewValidateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  const handleVisibility = () => {
    setIsVisible(!isVisible);
    setPasswordType(isVisible ? "text" : "password");
    // setTimeout(() => {
    //   setPasswordType("password");
    //   setIsVisible(false);
    // }, 3000);
  };

  function ConfirmValidateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address.";
    } else if (NewPhone !== ConfirmPhone) {
      error = "Please check your email fields.";
    }
    return error;
  }


  return (
    <Formik
      validationSchema={SignupSchema}
      initialValues={{
        phone_number: "",
        password: "",
        country_dial_code: "91",
        country_code: "IN",
      }}

      onSubmit={(values, { resetForm }) => {
        // same shape as initial values
        PhoneChangeSubmit(values, resetForm);
      }}
      enableReinitialize={true}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty,
      }) => {
        hasErrorsPhone = Object.keys(errors).length == 0;
        return (
          <form
            onSubmit={handleSubmit}
            className={Style.signin_modal_form}
          >
            <div className={Style.edit_profile_info_form}>
              <h3 className={Style.edit_profile_info_title}>
                Update Phone Number
              </h3>
              <CountryCodeInput
                handleChangeHandler={(e) => {
                  if (e.target.value.length < 15) {
                    setFieldValue("phone_number", e.target.value);
                    if (!values?.country_dial_code) {
                      setFieldValue("country_dial_code", "+91");
                      setFieldValue("country_code", "IN");
                      setMessage({
                        success: "",
                        error: ""
                      })
                    }
                  }
                }}
                handleChangeCode={(c) =>
                  setFieldValue("country_dial_code", c)
                }
                handleChangeCountryCode={(c) => {
                  setFieldValue("country_code", c);
                }}
                currentAuthData={currentAuthData}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                values={values}
                touched={touched?.phone_number}
                errors={errors?.phone_number}
                placeholder={"Enter New Mobile Number"}
              />

              <div className="form-group">
                <label className={Style.contact_form_label} for="contact-phone">
                  Password*
                </label>
                <div className="input-holder">
                  <input
                    name="password"
                    className="form-control"
                    placeholder={"Enter Your Password"}
                    type={isVisible ? "text" : "password"}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("password", e.target.value);
                      setMessage({
                        success: "",
                        error: ""
                      })
                    }}
                    onKeyDown={(e) => {
                      if (e.code == "Enter") {
                        setIsKeyDown(true);
                        handleSubmit();
                      }
                    }}
                  />
                  <button
                    className={`eye ${isVisible ? "show-pwd" : ""}`}
                    onClick={(e) => handleVisibility(e)}
                  >
                    {isVisible ? (
                      <>
                        <img
                          src={Assets.icon_eye_visible}
                          alt="icon-eye"
                        />
                      </>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.192"
                          height="15.192"
                          viewBox="0 0 15.192 15.192"
                        >
                          <g
                            id="Icon_feather-eye-off"
                            data-name="Icon feather-eye-off"
                            transform="translate(1.061 1.061)"
                          >
                            <path
                              id="Path_68039"
                              data-name="Path 68039"
                              d="M11.564,14.282a5.983,5.983,0,0,1-3.529,1.224c-4.159,0-6.535-4.753-6.535-4.753A10.961,10.961,0,0,1,4.506,7.224M6.788,6.143A5.418,5.418,0,0,1,8.035,6c4.159,0,6.535,4.753,6.535,4.753a10.991,10.991,0,0,1-1.283,1.9m-3.992-.636A1.782,1.782,0,1,1,6.776,9.493"
                              transform="translate(-1.5 -4.218)"
                              fill="none"
                              stroke="#a2a4ac"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_68040"
                              data-name="Path 68040"
                              d="M1.5,1.5,14.57,14.57"
                              transform="translate(-1.5 -1.5)"
                              fill="none"
                              stroke="#a2a4ac"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </>
                    )}
                  </button>
                  <ErrorMessage
                    name="password"
                    className="field_error_message"
                  >
                    {(msg) => (
                      <div
                        style={{
                          color: "#FF6261",
                          fontSize: "13px",
                          marginTop: "5px",
                        }}
                        className="error-msg"
                      >
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
              </div>

            </div>
            <div className={Style.edit_profile_info_btn_wrap}>
              <button type="submit" className="btn btn-tertiary" disabled={!hasErrorsPhone || !dirty}>
                {submitButtonLabel}
              </button>
              <button onClick={() => { ClosePopup() }} className="btn btn-border-grey">Cancel</button>
            </div>
            <p className={Style.message}>{error_message?.error && error_message?.error}</p>
          </form>
        );
      }}
    </Formik>
  );
}

export default ComponentPhoneUpdateForm;



