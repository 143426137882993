import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileDetails,
  getFamilyMembersData,
  updateCoverPhotoObj,
} from "../store/slices/userprofileSlice";

export const useProfile = () => {
  var connections_store,
    tabRouteData = [];
  const dispatch = useDispatch();
  const { user_id } = useSelector((store) => store.auth.registerData);
  const { userdata } = useSelector((store) => store.userprofile);
  const [isReadMore, setIsReadMore] = useState(true);

  const connections_array = [
    { parents: ["mother", "father"] },
    "sibling",
    "partner",
    "children",
    "pet",
  ];

  const [Title, setTitle] = useState();
  const [hasGotData, setHasGotData] = useState();

  useEffect(() => {
    hasGotData ? setTitle("Parents") : setTitle("");
  }, [hasGotData]);

  const [familyMembersSelectedTab, setfamilyMembersSelectedTab] =
    useState(connections_array);

  const [TabRouteData, setTabRouteData] = useState([]);

  const familyMembers = useSelector(
    (state) => state.userprofile.familyMembers.data
  );

  const triggeruseEffectFamilyMembers = () => {
    if (familyMembers?.length == 0) {
      dispatch(getFamilyMembersData());
    }
  };

  const triggeruseEffectGetProfileDetails = () => {
    if (Object.keys(userdata).length === 0) {
      dispatch(getProfileDetails());
    }
  };

  useEffect(() => {
    tabRouteDataGenerator();
  }, [familyMembers]);
  // Click Event for section tab Family Members

  const replaceCoverPhotoObj = (obj) => {
    return dispatch(updateCoverPhotoObj({ obj: obj }));
  };

  const family_filter = (data, connection, attr) => {
    let result = false;
    data?.forEach((data) => {
      if (data.connection === connection) {
        result = data[attr];
      }
    });
    return result;
  };

  const family_filter_array = (data, connection) => {
    return data?.filter((item) =>
      item.connection == connection ? item : false
    );
  };

  const goFirstInArrayObject = function (d, daFirst) {
    var r = [];

    d?.forEach((e) => {
      if (e["name"] === daFirst) r.unshift(e);
      else r.push(e);
    });

    return r;
  };

  const tabRouteDataOrderCorrection = (data) => {
    data = goFirstInArrayObject(data, "Childrens");
    data = goFirstInArrayObject(data, "Partners");
    data = goFirstInArrayObject(data, "Siblings");
    data = goFirstInArrayObject(data, "Parents");
    data = goFirstInArrayObject(data, "All");

    return data;
  };

  const tabRouteDataGenerator = () => {
    /**
     * Dashboard -> User Profile -> Family Members Section
     * Family Members Section tab data generates from this function
     */

    connections_store = [
      ...new Set(familyMembers?.map((item) => item.connection)),
    ];

    var temp_store = [];

    /*  All tab data */

    if (temp_store.indexOf("all") === -1) {
      /* Checking all tab data was inserted it or not */
      var obj = {
        name: "All",
        path: "",
        relation: [],
      };

      var relationOrderTemp = connections_store.filter(
        // Getting Connections Array Excluding Unwated
        (e) => e !== "user" && e !== "mother" && e !== "father"
      );

      obj.relation = obj.relation.concat(relationOrderTemp);

      tabRouteData.push(obj);
      temp_store.push("all");
    }
    setTabRouteData(tabRouteDataOrderCorrection(tabRouteData));
  };

  const capitalizeFirstLetter = function (str) {
    // converting first letter to uppercase
    if (str != "" && str != undefined && str != null) {
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
      return capitalized;
    } else {
      return;
    }
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const is_relation_exist = (data, connection) => {
    // Checking relation checking in array
    let is_exist = false;
    data?.forEach((data) => {
      if (data.connection === connection) {
        is_exist = true;
      }
    });
    return is_exist;
  };

  const is_parents_exist = (data) => {
    // Checking parents exists in array
    let is_exist = [];
    data?.forEach((data) => {
      if (data.connection === "father" && data.name != "") {
        is_exist.push("father");
      }
      if (data.connection === "mother" && data.name != "") {
        is_exist.push("mother");
      }
    });

    return is_exist.length > 1 ? true : false;
  };

  const isDataExist = (data) => {
    // Checking any connections  exists in array

    let count = 0;

    data?.forEach((data) => {
      if (data.connection !== "user" && data.name != "") {
        count++;
      }
    });

    return count > 0 ? true : false;
  };

  return {
    userdata,
    isReadMore,
    setIsReadMore,
    toggleReadMore,
    familyMembers,
    family_filter,
    is_relation_exist,
    is_parents_exist,
    family_filter_array,
    capitalizeFirstLetter,
    isDataExist,
    familyMembersSelectedTab,
    TabRouteData,
    replaceCoverPhotoObj,
    Title,
    setTitle,
    hasGotData,
    setHasGotData,
    triggeruseEffectFamilyMembers,
    triggeruseEffectGetProfileDetails,
  };
};
