import { replace } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { verifyOtpForPhoneUpdate } from "../store/slices/phoneUpdateOtpSlice";
import { UpdatePhone } from "../store/slices/profileInfoUpdateSlice";

export const usePhoneUpdateOtp = () => {
  const [showOtpResponse, setShowOtpResponse] = useState({
    message: "",
    status: null,
  });

  const [verifyOtpStatus, setVerifyOtpStatus] = useState(false);
  const [code, setCode] = useState("");
  const [resetStatus, setResetStatus] = useState("");

  const { phoneUpdateformSubmittedDetails } = useSelector(
    (state) => state.phoneUpdateSlice
  );
  const dispatch = useDispatch();

  const verifyForgotOtp = async (code) => {
    setShowOtpResponse({
      message: "",
      status: null,
    });
    setCode(code);
    if (code.length == 4) {
      const formData = new FormData();
      formData.append("otp", code);
      formData.append("token", phoneUpdateformSubmittedDetails.token);
      formData.append("number", phoneUpdateformSubmittedDetails.phone_number);
      formData.append("dial_code", phoneUpdateformSubmittedDetails.dial_code);
      formData.append("country_code", phoneUpdateformSubmittedDetails.country_code);

      dispatch(verifyOtpForPhoneUpdate(formData))
        .unwrap()
        .then((result) => {
          const { success, message, code, data } = result?.data;

          if (success == true) {
            const { phone_number, dial_code } = phoneUpdateformSubmittedDetails;
            dispatch(
              UpdatePhone({
                phone_number: phone_number,
                country_dial_code: dial_code?.replace("+", ""),
              })
            );
            setVerifyOtpStatus(true);

          } else {
            setVerifyOtpStatus(false);
            setShowOtpResponse({
              message: data,
              status: false,
            });
          }
        })
        .catch((e) => {
          // console.log("catch data", e);
        });
    } else {
    }
  };

  return {
    verifyForgotOtp,
    code,
    setShowOtpResponse,
    showOtpResponse,
    verifyOtpStatus,
  };
};
