import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  formSubmittedDetails: [],
  emailUpdateData: {}
};


export const emailUpdateForm = createAsyncThunk(
  "emailupdate/emailUpdateForm",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {

      const { action, value } = obj;

      const packet = new FormData();

      packet.append("mail", obj.new_email);
      packet.append("password", obj.password);


      const response = await api.post(`send-email-otp`, packet);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const emailUpdateSlice = createSlice({
  name: "emailupdate",
  initialState,
  reducers: {
    clearFormResult: (state) => {
      state.formSubmittedDetails = [];
    },
    updateFormSubmitState: (state, action) => {
      state.emailUpdateData = action.payload;
    }
  },
  extraReducers: {
    [emailUpdateForm.pending]: (state) => {
      state.isLoading = true;
    },
    [emailUpdateForm.fulfilled]: (state, action) => {
      state.formSubmittedDetails = action.payload.data.result == null ? [] : action.payload.data.result;
      state.isLoading = false;
    },
    [emailUpdateForm.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});


export const { clearFormResult, updateFormSubmitState } = emailUpdateSlice.actions;

export default emailUpdateSlice.reducer;

