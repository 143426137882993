import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  message: null,
  error: null,
  resultData: {},
};

export const getSlideshows = createAsyncThunk(
  "slideshow/getSlideshows",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `list-slideshow?page=${params.page}&&sort=${params.slug}&per_page=10`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const LoadmoreSlideshows = createAsyncThunk(
  "slideshow/LoadmoreSlideshows",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `list-slideshow?page=${params.page}&&sort=${params.slug}&per_page=10`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSlideShowItems = createAsyncThunk(
  "slideshow/deleteSlideShowItems",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`delete-slideshow`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveSlideShow = createAsyncThunk(
  "slideshow/saveSlideShow",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`create-slideshow`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const slideshowSlice = createSlice({
  name: "slideshow",
  initialState,
  reducers: {
    removeSlideShowFromState: (state, action) => {
      state.resultData.data = state.resultData.data?.filter(
        (item, index) => !action.payload.includes(item.id)
      );
    },
  },

  extraReducers: {
    [getSlideshows.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSlideshows.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData = { ...action?.payload?.data?.result };
    },
    [getSlideshows.rejected]: (state, action) => {
      state.status = "failed";
    },

    [LoadmoreSlideshows.fulfilled]: (state, action) => {
      state.resultData = {
        data: [
          ...state.resultData?.data,
          ...action?.payload?.data?.result?.data,
        ],
        current_page: action?.payload?.data?.result?.current_page,
        total: action?.payload?.data?.result?.total,
        sorting_options: [...state.resultData?.sorting_options],
        last_page: action?.payload?.data?.result?.last_page,
      };
    },

    [saveSlideShow.pending]: (state, action) => {
      state.status = "loading";
    },
    [saveSlideShow.fulfilled]: (state, action) => {
      state.status = "succeed";
      let slideIndex = state.resultData?.data?.findIndex(
        (item) => item.id == action.payload.data.result.id
      );

      if (slideIndex == -1) {
        state.resultData.data = [
          action.payload.data.result,
          ...state.resultData?.data,
        ];
        state.resultData.total = state.resultData.total + 1;
      } else {
        state.resultData.data[slideIndex] = action.payload.data.result;
       
      }
    },
    [saveSlideShow.rejected]: (state, action) => {
      state.status = "failed";
    },
    ["auth/logout"]: (state, action) => {
      state.status = "idle";
      state.resultData = {};
    },
  },
});

export const { removeSlideShowFromState } = slideshowSlice.actions;

export default slideshowSlice.reducer;
