import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  enableImageSelection,
  selectMultAlumSelection,
  swtichGridOption,
} from "../../../../store/slices/GalleryTabSlice";
import {
  clearSlideShow,
  deleteImage,
} from "../../../../store/slices/SlideShowDetailSlice";

export const useSlideThumbList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const swiperThumbRef = useRef(null);
  const [addSlide, setAddSlide] = useState(false);

  useEffect(() => {
    return () => {
      if (!addSlide) {
        dispatch(clearSlideShow());
      }
    };
  }, []);

  const handleDeleteSlide = (img) => {
    dispatch(deleteImage(img));
  };

  const handleAddImage = (slide) => {
    let result = slide?.map((a) => a.id);
    // localStorage.setItem("albumView", "list");
    setAddSlide(true);
    dispatch(
      selectMultAlumSelection({
        tab: "addslideshow",
        selectedImage: result,
        image: slide,
      })
    );

    navigate("/dashboard/slideshow");
  };

  return { handleDeleteSlide, handleAddImage, swiperThumbRef };
};
