import { useState } from "react";

export const useFullscreenPreviewActions = () => {
  const [showFullView, setShowFullView] = useState({
    index: 0,
    isOpen: false,
    imgArry: [],
    albumId: 0,
    album_index: 0,
  });

  const openPreview = (index, id, data, ablumIndex) => {
    setShowFullView({
      imageId: id,
      index: index,
      isOpen: true,
      imgArry: data?.images,
      albumId: data?.id,
      album_index: ablumIndex
    });
  };

  const closePreview = () => {
    setShowFullView({});
  };

  return {
    showFullView,
    setShowFullView,
    openPreview,
    closePreview,
  };
};
