import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  DeSelectImage,
  SelectMultImage,
} from "../../../../store/slices/GalleryTabSlice";
import { addToStared } from "../../../../store/slices/ProfileGallery/profileGallerySlice";

export const useFilter = () => {
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const selections = useSelector((state) => state.galleryTab);

  const profileGallery = useSelector(
    (state) => state.profileGallery.resultData
  );
  const Favorites = useSelector((state) => state.favorite.resultData);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectAll(false);
      dispatch(DeSelectImage({ tab: "user-profile" }));
    } else {
      let ImageIds = Favorites?.data.map((obj) => obj.id);
      dispatch(SelectMultImage({ tab: "user-profile", ImageIds }));
      setSelectAll(true);
    }

    //FIXME when image paginate need to handle
  };

  const handleClose = () => {};

  const handleAddToStaredImages = async () => {
    let image_id = selections.ImageProfileTab.selectedImage;
    const resultAction = await dispatch(addToStared({ image_id }));
    if (addToStared.fulfilled.match(resultAction)) {
      toast.success("add to stared success !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return {
    selectAll,
    handleSelectAll,
    handleClose,
    handleAddToStaredImages,
  };
};
