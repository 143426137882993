const getFraction = (num) => {
    var test = (String(num).split('.')[1] || []).length;
    var num = (num * (10 ** Number(test)))
    var den = (10 ** Number(test))
    function reduce(numerator, denominator) {
        var gcd = function gcd(a, b) {
            return b ? gcd(b, a % b) : a;
        };
        gcd = gcd(numerator, denominator);
        return [numerator / gcd, denominator / gcd];
    }
    return (reduce(num, den)[0] + "/" + reduce(num, den)[1])
}
export default getFraction;
