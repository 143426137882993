import React from 'react';
import Icons from '../Layouts/Icons';
import Style from './PreferenceInnerHeader.module.scss';
import { useNavigate } from 'react-router-dom';

const PreferenceInnerHeader = ({ title, navigatePath }) => {
    let navigate = useNavigate();

    return (
        <div className={Style.preference_inner_header}>
            <button className={Style.btn_back} onClick={() => navigate(navigatePath)}>
                <Icons icon={"prev-arrow"} size={20} />
            </button>
            <h1 className={Style.preference_inner_header_title}>{title}</h1>
        </div>
    )
}

export default PreferenceInnerHeader;