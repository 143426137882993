import React, { createContext, useState } from "react";
export const FileUploadContext = createContext();


const FileUploadProvider = ({ children }) => {
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [duplicateImages, setDuplicateImages] = useState([]);
    const [invalidImages, setInvalidImages] = useState([]);
    const [isUploading, setIsUploading] = useState(false);


    const addToFiles = (data) => {
        setFiles((prev) => [...prev, data])
    }

    const addToImages = (data) => {
        setImages((prev) => [...prev, ...data])
    }

    const addToDuplicateImages = (data) => {
        setDuplicateImages((prev) => [...prev, data])
    }

    const addToInValidImages = (data) => {
        setInvalidImages((prev) => [...prev, data])
    }

    const upDateUploadingStatus = (data) => {
        setIsUploading(data)
    }


    return (
        <FileUploadContext.Provider
            value={{
                files,
                images,
                duplicateImages,
                invalidImages,
                isUploading,
                addToFiles,
                addToImages,
                addToDuplicateImages,
                addToInValidImages,
                upDateUploadingStatus
            }}
        >
            {children}
        </FileUploadContext.Provider>
    )
}

export default FileUploadProvider