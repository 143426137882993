import React, { useEffect, useState } from "react";
import Style from "./FileUploadMinimizedBox.module.scss";
import Icons from "../Layouts/Icons";
import { useDispatch } from "react-redux";
import { openMiniUploadBox } from "../../store/slices/Gallery/fileUploadSlce";
import { useRef } from "react";
import { useDimensions } from "../../Logic/Dimensions";
import { useFileUploadMini } from "./useFileUploadMini";
import ImageThumb from "./ImageThumb";
import { FixedSizeList } from "react-window";
import Assets from "../Layouts/Assets";



const FileUploadMinimizedBox = ({
  images,
  duplicateImages,
  invalidImages,
  uploadIndex,
  allFiles,
  close,
  isLimit
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const dispatch = useDispatch();

  const nameRef = useRef();
  const windowDimensions = useDimensions();

  function ListItem({ data, index, style }) {
    return (
      <div className={Style.fu_body_list} style={style}>
        {data[index]?.duplicate ? (
          <ImageThumb
            image={data?.[index]}
            name={data?.[index]?.name}
            isDupicate
          />
        ) : data[index]?.invalid ? (
          <ImageThumb name={data?.[index]?.name} />
        ) : (
          <ImageThumb
            image={data[index]}
            name={data?.[index]?.file?.name}
            isImageSuccess
          />
        )}
      </div>
    );
  }

  return (
    <div
      className={`${Style.file_upload_minimized} ${isCollapsed && Style.file_upload_collapsed
        }`}
    >
      <div className={Style.file_upload_minimized_head}>
        <h5 className={Style.fu_head_title}>
          {isLimit ?
            <>
              Your storage is full
              <figure className={Style.limitmini}>
                <img src={Assets.limitIcon} alt="file_upload_image" />
              </figure>
            </>
            :
            allFiles?.length > 0 && allFiles?.length == uploadIndex
              ? `Uploaded ${allFiles?.length} Photos`
              : `Uploading ${allFiles?.length - uploadIndex} Photos`}


        </h5>
        <div className={Style.fu_head_actions}>
          <button
            className={Style.collapse_upload}
            onClick={() =>
              isCollapsed ? setIsCollapsed(false) : setIsCollapsed(true)
            }
          >
            <Icons icon={"next-arrow"} size={14} />
          </button>
          <button className={Style.cancel_upload} onClick={() => close()}>
            <Icons icon={"icon-close"} size={10} />
          </button>
        </div>
      </div>
      <div className={`${Style.file_upload_minimized_body} ${isLimit && Style.limit_exceded}`}>
        <FixedSizeList
          itemData={[...images, ...duplicateImages, ...invalidImages]}
          height={isCollapsed ? 0 : 270}
          itemCount={[...images, ...duplicateImages, ...invalidImages].length}
          itemSize={70}
          width={400}
          direction="vertical"
          style={{ flex: "1 0", width: "100%" }}
        >
          {ListItem}
        </FixedSizeList>


      </div>
    </div>
  );
};

export default FileUploadMinimizedBox;

let itemData = [
  {
    id: "batch-1.item-1",
    batchId: "batch-1",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: {
      name: "pexels-el-jusuf-14851859.jpg",
      lastModified: 1677958234903,
    },
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223719yQcsd.jpg",
  },
  {
    id: "batch-2.item-2",
    batchId: "batch-2",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677893957891, name: "pexels-ekateri…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223721hSQQF.jpg",
  },
  {
    id: "batch-3.item-3",
    batchId: "batch-3",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677883711178, name: "pexels-dids-37…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223722E1EVf.jpg",
  },
  {
    id: "batch-4.item-4",
    batchId: "batch-4",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677885817184, name: "pexels-deepu-b…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223723PxOpg.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  "pexels-churckyno-bryant-1546035.jpg",
  "pexels-cup-of-couple-7303979.jpg",
  "pexels-curioso-photography-288100.jpg",
  "pexels-anni-roenkae-2693208.jpg",
  "pexels-anni-roenkae-2693212.jpg",
  "pexels-artūras-kokorevas-14795846.jpg",
];
