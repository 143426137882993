import React, { useState, useRef, useEffect } from "react";
import Icons from "../../Layouts/Icons";
import { useDimensions } from "../../../Logic/Dimensions";
import Style from "./FullViewImage.module.scss";
import moment from "moment";
import useGallery from "../../../Logic/useGallery";
import { useSelector } from "react-redux";
import Img from "react-cool-img";
import { memo } from "react";

const FullViewImage = memo(({
  data,
  onClick,
  imgIndex,
  initialIndex,
  frameData,
}) => {
  let newImg = data?.src?.large_thumbnail ? data?.src?.large_thumbnail : data?.src?.large

  const windowDimensions = useDimensions();
  const [currentImage, setCurrentImage] = useState(newImg);
  const [isFrame, setIsFrame] = useState(frameData?.image && frameData?.name !== "None" ? true : false);
  return (
    <>
      <div className={Style.full_view_slider_img_wrap} key={imgIndex}>
        <div
          className={`${Style.gallery_image_wrap} ${data?.favourite == 1 ? Style.favorite_active : ""
            } 
            ${isFrame && Style.border_active}
            `}
          style={{
            borderImageSource: `url(${!isFrame ? "" : frameData.image})`,
          }}
        >
          <figure
            className={Style.slideshow_image}

          >
            <img
              src={imgIndex == initialIndex ? currentImage : ""}
              alt=""
              onClick={onClick}

            />


          </figure>

          {windowDimensions.width < 992 ? (
            <></>
          ) : (
            <>
              <div className={Style.gallery_image_tray}>
                <div className={Style.tray_details}>
                  <div className={Style.gallery_image_tray_author}>
                    {/* {data?.full_name} */}
                  </div>
                  {data?.metadata?.file_date_time ? (
                    <div className={Style.gallery_image_tray_post_date}>
                      {moment(data?.metadata?.file_date_time).format("DD MMM YYYY hh:mm A")}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={Style.tray_actions}>
                  {/* <button
                    className={`${Style.tray_actions_btn} ${
                      Style.favorite_btn
                    } ${
                      data?.favourite == 0
                        ? Style.tray_actions_btn_liked
                        : Style.tray_actions_btn_no_like
                    }`}
                    onClick={() => handleClickFavorite(data?.id)}
                  >
                  
                    <svg
                      id="heart-svg"
                      viewBox="467 392 58 57"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Group"
                        fill="none"
                        fillRule="evenodd"
                        transform="translate(467 392)"
                      >
                        <path
                          d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z"
                          id="heart"
                          style={{
                            stroke: "rgb(0, 0, 0)",
                            strokeWidth: "2px",
                            fill: "rgba(255, 255, 255, 0)",
                          }}
                        />
                        <circle
                          id="main-circ"
                          fill="#E2264D"
                          opacity="0"
                          cx="29.5"
                          cy="29.5"
                          r="1.5"
                        />

                        <g id="grp7" opacity="0" transform="translate(7 6)">
                          <circle
                            id="oval1"
                            fill="#9CD8C3"
                            cx="2"
                            cy="6"
                            r="2"
                          />
                          <circle
                            id="oval2"
                            fill="#8CE8C3"
                            cx="5"
                            cy="2"
                            r="2"
                          />
                        </g>

                        <g id="grp6" opacity="0" transform="translate(0 28)">
                          <circle
                            id="oval1"
                            fill="#CC8EF5"
                            cx="2"
                            cy="7"
                            r="2"
                          />
                          <circle
                            id="oval2"
                            fill="#91D2FA"
                            cx="3"
                            cy="2"
                            r="2"
                          />
                        </g>

                        <g id="grp3" opacity="0" transform="translate(52 28)">
                          <circle
                            id="oval2"
                            fill="#9CD8C3"
                            cx="2"
                            cy="7"
                            r="2"
                          />
                          <circle
                            id="oval1"
                            fill="#8CE8C3"
                            cx="4"
                            cy="2"
                            r="2"
                          />
                        </g>

                        <g id="grp2" opacity="0" transform="translate(44 6)">
                          <circle
                            id="oval2"
                            fill="#CC8EF5"
                            cx="5"
                            cy="6"
                            r="2"
                          />
                          <circle
                            id="oval1"
                            fill="#CC8EF5"
                            cx="2"
                            cy="2"
                            r="2"
                          />
                        </g>

                        <g id="grp5" opacity="0" transform="translate(14 50)">
                          <circle
                            id="oval1"
                            fill="#91D2FA"
                            cx="6"
                            cy="5"
                            r="2"
                          />
                          <circle
                            id="oval2"
                            fill="#91D2FA"
                            cx="2"
                            cy="2"
                            r="2"
                          />
                        </g>

                        <g id="grp4" opacity="0" transform="translate(35 50)">
                          <circle
                            id="oval1"
                            fill="#F48EA7"
                            cx="6"
                            cy="5"
                            r="2"
                          />
                          <circle
                            id="oval2"
                            fill="#F48EA7"
                            cx="2"
                            cy="2"
                            r="2"
                          />
                        </g>

                        <g id="grp1" opacity="0" transform="translate(24)">
                          <circle
                            id="oval1"
                            fill="#9FC7FA"
                            cx="2.5"
                            cy="3"
                            r="2"
                          />
                          <circle
                            id="oval2"
                            fill="#9FC7FA"
                            cx="7.5"
                            cy="2"
                            r="2"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                  <button
                    className={Style.tray_actions_btn}
                    onClick={() => handleDeleteAction(data?.id)}
                  >
                    <Icons icon={"icon-trash"} size={16} />
                  </button> */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
})
export default FullViewImage;
