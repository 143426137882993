import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { endTabSession } from "../../store/slices/GalleryTabSlice";
import {
  getSlideshows,
  LoadmoreSlideshows,
} from "../../store/slices/slideshow/slideshowSlice";

export const useSlideshow = () => {
  const slideRef = useRef();
  const observer = useRef();
  const location = useLocation();

  const dispatch = useDispatch();
  const SlideShows = useSelector((state) => state?.slideshow);
  const Selections = useSelector((state) => state.galleryTab);
  
  let currentPath = location.pathname
  .replace("/dashboard", "")
  .replaceAll("/", "");


  useEffect(() => {
    if (SlideShows?.status == "idle") {
      let obj = {
        page: "1",
        slug: "",
      };
      dispatch(getSlideshows(obj));
    }
    return () => {
      dispatch(endTabSession({ tab: currentPath }))
    }
  }, []);

  const loadMore = (slug, type) => {
    if (
      SlideShows?.resultData?.current_page < SlideShows?.resultData?.last_page
    ) {
      let obj = {
        page: SlideShows?.resultData?.current_page + 1,
        slug: Selections?.ImageSlideshowTab?.sort,
      };
      dispatch(LoadmoreSlideshows(obj));
    }
  };

  const NodataContent = {
    title: `Your slideshow are empty`,
    description: `The slideshow collections you create are shown here`,
    buttonText: ``,
  };

  useEffect(() => {
    if (slideRef.current) {
      const interceptConfig = {
        root: slideRef.current,
        rootMargin: "0px",
        threshold: 0.1,
      };
      observer.current = new IntersectionObserver((entries) => {
        if (entries.every((entry) => entry.isIntersecting)) {
          loadMore("slideshow");
        }
      });
      observer.current.observe(slideRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [SlideShows?.resultData?.data]);

  return {
    Selections,
    SlideShows,
    loadMore,
    NodataContent,
    slideRef,
    status: SlideShows?.status
  };
};
