import { useDimensions } from "../../../Logic/Dimensions";
import Style from "./FacesDrawer.module.scss";
import ReactDOM from "react-dom";
import BackHeader from "../../BackHeader";
import Assets from "../../Layouts/Assets";
import { useState } from "react";
import { useEffect } from "react";
import { currentSelectedAlbum } from "../../../store/slices/gallerySlice";
import useAlbum from "../../../Logic/useAlbum";
import { useDispatch, useSelector } from "react-redux";
import { useFacesData } from "../../../Logic/FamilyTree/useFacesData";

const FacesDrawer = ({
  showFacesDrawer,
  setShowFacesDrawer,
  handleDropNewImages,
  faces,
}) => {
  const windowDimensions = useDimensions();
  const [isGrabbing, setIsGrabbing] = useState(false);


  return ReactDOM.createPortal(
    <div className={Style.FacesDrawer}>
      <div className={Style.FacesDrawer__wrapper}>
        {windowDimensions.width >= 576 ? (
          <div className={Style.FacesDrawer__closeWrapper}>
            <button onClick={() => setShowFacesDrawer(false)}>x</button>
          </div>
        ) : (
          <BackHeader />
        )}
        <h3 className={Style.FacesDrawer__title}>Faces/Portraits</h3>
        <div className={Style.FacesDrawer__gallery}>
          {faces?.map((face) => {
            return (
              <div
                className={Style.FacesDrawer__galleryImageWrap}
                key={face.id}
              >
                <figure
                  className={`${Style.FacesDrawer__galleryImage} ${
                    isGrabbing ? Style.grabbing : ""
                  }`}
                  onMouseDown={() => setIsGrabbing(true)}
                  onMouseUp={() => setIsGrabbing(false)}
                >
                  <img
                    onDrag={handleDropNewImages}
                    src={face?.src?.encoded}
                    alt="faces/portraits"
                  />
                </figure>
              </div>
            );
          })}
        </div>
      </div>
    </div>,
    document.getElementById("slideshow-root")
  );
};

export default FacesDrawer;

let facesData = [
  {
    url: "https://images.pexels.com/photos/2693212/pexels-photo-2693212.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    url: "https://images.pexels.com/photos/2471234/pexels-photo-2471234.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    url: "https://images.pexels.com/photos/5186869/pexels-photo-5186869.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
];
