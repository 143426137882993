
import React from "react";
import Style from "../../FullScreenPreviewNew.module.scss";
import moment from "moment";
import Icons from "../../../../Layouts/Icons";
import sizeConverter from "../../../../../utils/utilityHooks/sizeConverter";
import getFraction from "../../../../../utils/utilityHooks/getFraction";
import { Accordion } from "react-bootstrap";
import useDarkmode from "../../../../../utils/utilityHooks/useDarkmode";

const Details = ({ data, index }) => {
    const { theme, themeToggler } = useDarkmode();

    let expTime = data[index]?.metadata?.exposure_time
    let evalExp = eval(expTime)
    let expTimecheck = parseFloat(evalExp?.toFixed(3))
    let getExpTime = expTime?.includes("/") ? data[index]?.metadata?.exposure_time : getFraction(expTimecheck)



    let removeCommas = data[index]?.size ? data[index]?.size?.split(",")?.join('') : null
    let removeDecml = removeCommas?.split(".")?.join('')
    var randomNumber = Math.floor(Math.random() * 10);
    let normalFileSize = removeDecml ? removeDecml?.toString() + randomNumber?.toString() : null
    let fileSize = data[index]?.metadata?.file_size ? data[index]?.metadata?.file_size : normalFileSize ? normalFileSize : ""


    console.log(data[index], fileSize, normalFileSize, removeCommas, removeDecml?.toString(), "bbbbbbbbbbb")

    return (
        <div className={`${Style.info_details} ${Style.info_sec}`}>
            <h4
                className={`${Style.info_details_title} ${Style.info_sec_title}`}
            >
                Details
            </h4>
            <Accordion className={`${theme == "dark" ? "acc-dark" : ""}`}>
                <Accordion.Item key={0} eventKey={"0"}>
                    <Accordion.Header>
                        <Icons icon={"icon-image"} size={18} />

                        <h5 className={Style.info_title}>
                            {data[index]?.original_name}
                        </h5>
                    </Accordion.Header>

                    <Accordion.Body>
                        {fileSize ? (

                            <p className={Style.info_description}>
                                {sizeConverter(
                                    fileSize
                                )}
                            </p>

                        ) : (
                            ""
                        )}
                    </Accordion.Body>

                </Accordion.Item>


            </Accordion>
            <table className={Style.info_sec_main_table}>

                {data[index]?.metadata?.file_date_time ? (
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon} colSpan={2}>

                            <Icons icon={"icon-calender"} size={18} />
                            <h5 className={Style.info_title}>
                                {" "}
                                {moment(
                                    data[index]?.metadata?.file_date_time,
                                ).format("DD MMM YYYY hh:mm A")}
                            </h5>
                            <p className={Style.info_description}>
                                {/* Sun 10:14 AM &nbsp;&nbsp; GMT+05:30 */}
                            </p>

                        </td>
                    </tr>
                ) : (
                    ""
                )}

                {/* {data[index]?.original_name ? (
                    <>
  
                        <tr className={Style.info_details_item}>
                            <td className={Style.info_details_icon} colSpan={2}>

                                <Icons icon={"icon-image"} size={18} />

                                <h5 className={Style.info_title}>
                                    {data[index]?.original_name}
                                </h5>

                            </td>
                        </tr>
                        <tr>
                            <td className={Style.info_details_icon} colSpan={2} align="left">

                                {fileSize ? (

                                    <p className={Style.info_description}>
                                        {sizeConverter(
                                            fileSize
                                        )}
                                    </p>

                                ) : (
                                    ""
                                )}
                            </td>

                        </tr>
                    </>
                ) : (
                    ""
                )} */}

                {data[index]?.metadata?.location ? (
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            <Icons icon={"location"} size={18} />
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[index]?.metadata?.location}
                            </h5>
                        </td>
                    </tr>
                ) : (
                    ""
                )}


                {data[index]?.metadata?.make ?
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            Make
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[index]?.metadata?.make}
                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }
                {data[index]?.metadata?.device ?
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            Device
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[index]?.metadata?.device}
                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }

                {data[index]?.width ?
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            Dimensions
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[index]?.width} x {data[index]?.height}
                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }


                {data[index]?.metadata?.exposure_time ?
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            ExposureTime
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {getExpTime}
                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }

                {data[index]?.metadata?.iso ?
                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            ISO
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[index]?.metadata?.iso}
                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }

                {data[index]?.metadata?.aperture ?

                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            Aperture
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {data[index]?.metadata?.aperture}
                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }

                {data[index]?.metadata?.focal_length ?

                    <tr className={Style.info_details_item}>
                        <td className={Style.info_details_icon}>
                            Focal Length
                        </td>
                        <td className={Style.info_details_content}>
                            <h5 className={Style.info_title}>
                                {eval(data[index]?.metadata?.focal_length)} mm

                            </h5>
                        </td>
                    </tr>
                    :
                    ""
                }
            </table>
            <ul
                className={`${Style.info_details_list} ${Style.info_sec_content} ${Style.info_sec_content_table}`}
            >
            </ul>
        </div>
    )
}

export default Details;

