import React, { useEffect } from "react";
// import HomeAdvertisements from "../HomeAdvertisements";
import HomeBanner from "../HomeBanner";
import HomeBrowsePhotos from "../HomeBrowsePhotos";
import HomeExternalLinks from "../HomeExternalLinks";
import HomeChooseAPlan from "../HomeChooseAPlan";
import HomeCreateAndExplore from "../HomeCreateAndExplore";
import HomeLiveStatus from "../HomeLiveStatus";
import HomeMemories from "../HomeMemories";
import HomeTestimonials from "../HomeTestimonials";
import { useLocation } from "react-router-dom";
import CheckOutComponent from "../Payment/CheckOutComponent";
import { usePlans } from "../../Logic/ChoosePlans/usePlans";
import { useSelector } from "react-redux";
import Styles from "./home.module.scss";
import CommonModal from "../Modals/commonModal";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("visible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [location?.pathname]);

  const { data, isOpen } = useSelector((state) => state.payment);
  const { closePaymentModal } = usePlans();

  return (
    <div className="home-page">
      <HomeBanner />
      <HomeBrowsePhotos />
      {/* <HomeAdvertisements /> */}
      <HomeExternalLinks />
      <HomeCreateAndExplore />
      <HomeMemories />
      <HomeTestimonials />
      <HomeChooseAPlan />

      <HomeLiveStatus />
      <CommonModal
        showModal={isOpen}
        hide={closePaymentModal}
        className={Styles.payment_modal}
        content={<CheckOutComponent />}
      />
    </div>
  );
};

export default Home;
