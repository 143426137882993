import { useState } from "react"

export const usePDFViewer = () => {
    const [pdfOptions, setPdfOptions] = useState({
        isOpen: false,
        url: "",
        currentId: null
    })

    const handleOpenPdf = (item) => {
        console.log(item, "open pdf...")
        setPdfOptions({
            ...pdfOptions,
            isOpen: true,
            url: item.src.normal,
            currentId: item?.id
        })
    }

    const handleClosePdf = () => {
        setPdfOptions({
            ...pdfOptions,
            isOpen: false,
            url: "",
            currentId: null
        })
    }


    return {
        pdfOptions,
        setPdfOptions,
        handleOpenPdf,
        handleClosePdf
    }
}