import Style from "./DeleteMemberWarning.module.scss";
import CommonModal from "../../Modals/commonModal";

const DeleteMemberWarning = ({ setAcceptedRemoval }) => {
  return (
    <CommonModal
      showModal={true}
      hide={() => {
        window.closeModal();
      }}
      className="modal-width remove-person-modal"
      content={
        <>
          <div className={Style.remove_person}>
            <h3 className={Style.remove_person_title}>Delete this Person</h3>
            <p className={Style.remove_person_description}>
              Are you sure you want to delete this person from your family tree?
            </p>
            <div className={Style.remove_person_btn_wrap}>
              <button
                className="btn btn-red"
                onClick={() => {
                  setAcceptedRemoval(true);
                  window.removePerson();
                }}
              >
                Remove
              </button>
              <button
                className="btn btn-border btn-border-grey"
                onClick={() => {
                  window.closeModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      }
    ></CommonModal>
  );
};

export default DeleteMemberWarning;
