import React from 'react'
import { memo } from 'react'

const ThumbImage = memo(({ id, url }) => {
    return (
        <figure key={id}>
            <img src={url} alt="" />
        </figure>
    )
})

export default ThumbImage