const countries = [
    {
        name: "Afghanistan",
        code: "AF",
        country_dial_code: "93",
        label: "Afghanistan",
        value: "Afghanistan"
    },
    {
        name: "Albania",
        code: "AL",
        country_dial_code: "355",
        label: "Albania",
        value: "Albania"
    }, {

        name: "ALGERIA",
        code: "DZ",
        country_dial_code: "213",
        label: "ALGERIA",
        value: "ALGERIA"
    }, {

        name: "American Samoa",
        code: "AS",
        country_dial_code: "1684",
        label: "American Samoa",
        value: "American Samoa"
    }, {

        name: "Andorra",
        code: "AD",
        country_dial_code: "376",
        label: "Andorra",
        value: "Andorra"
    }, {

        name: "ANGOLA",
        code: "AO",
        country_dial_code: "244",
        label: "ANGOLA",
        value: "ANGOLA"
    }, {

        name: "Anguilla",
        code: "AI",
        country_dial_code: "1264",
        label: "Anguilla",
        value: "Anguilla"
    }, {

        name: "ANTARCTICA",
        code: "AQ",
        country_dial_code: "0",
        label: "ANTARCTICA",
        value: "ANTARCTICA"
    }, {

        name: "Antigua and Barbuda",
        code: "AG",
        country_dial_code: "1268",
        label: "Antigua and Barbuda",
        value: "Antigua and Barbuda"
    }, {

        name: "ARGENTINA",
        code: "AR",
        country_dial_code: "54",
        label: "ARGENTINA",
        value: "ARGENTINA"
    }, {

        name: "Armenia",
        code: "AM",
        country_dial_code: "374",
        label: "Armenia",
        value: "Armenia"
    }, {

        name: "ARUBA",
        code: "AW",
        country_dial_code: "297",
        label: "ARUBA",
        value: "ARUBA"
    }, {

        name: "AUSTRALIA",
        code: "AU",
        country_dial_code: "61",
        label: "AUSTRALIA",
        value: "AUSTRALIA"
    }, {

        name: "AUSTRIA",
        code: "AT",
        country_dial_code: "43",
        label: "AUSTRIA",
        value: "AUSTRIA"
    }, {

        name: "Azerbaijan",
        code: "AZ",
        country_dial_code: "994",
        label: "Azerbaijan",
        value: "Azerbaijan"
    }, {

        name: "Bahamas",
        code: "BS",
        country_dial_code: "1242",
        label: "Bahamas",
        value: "Bahamas"
    }, {

        name: "BAHARAIN",
        code: "BH",
        country_dial_code: "973",
        label: "BAHARAIN",
        value: "BAHARAIN"
    }, {

        name: "BANGLADESH",
        code: "BD",
        country_dial_code: "880",
        label: "BANGLADESH",
        value: "BANGLADESH"
    }, {

        name: "BARBADOS",
        code: "BB",
        country_dial_code: "1246",
        label: "BARBADOS",
        value: "BARBADOS"
    }, {

        name: "Belarus",
        code: "BY",
        country_dial_code: "375",
        label: "Belarus",
        value: "Belarus"
    }, {

        name: "BELGIUM",
        code: "BE",
        country_dial_code: "32",
        label: "BELGIUM",
        value: "BELGIUM"
    }, {

        name: "BELIZE",
        code: "BZ",
        country_dial_code: "501",
        label: "BELIZE",
        value: "BELIZE"
    }, {

        name: "Benin",
        code: "BJ",
        country_dial_code: "229",
        label: "Benin",
        value: "Benin"
    }, {

        name: "BERMUDA",
        code: "BM",
        country_dial_code: "1441",
        label: "BERMUDA",
        value: "BERMUDA"
    }, {

        name: "BHUTAN",
        code: "BT",
        country_dial_code: "975",
        label: "BHUTAN",
        value: "BHUTAN"
    }, {

        name: "BOLIVIA",
        code: "BO",
        country_dial_code: "591",
        label: "BOLIVIA",
        value: "BOLIVIA"
    }, {

        name: "BOSNIA AND HERZEGOVINA",
        code: "BA",
        country_dial_code: "387",
        label: "BOSNIA AND HERZEGOVINA",
        value: "BOSNIA AND HERZEGOVINA"
    }, {

        name: "BOTSWANA",
        code: "BW",
        country_dial_code: "267",
        label: "BOTSWANA",
        value: "BOTSWANA"
    }, {

        name: "Bouvet Island",
        code: "BV",
        country_dial_code: "0",
        label: "Bouvet Island",
        value: "Bouvet Island"
    }, {

        name: "BRAZIL",
        code: "BR",
        country_dial_code: "55",
        label: "BRAZIL",
        value: "BRAZIL"
    }, {

        name: "British Indian Ocean Territory",
        code: "IO",
        country_dial_code: "246",
        label: "British Indian Ocean Territory",
        value: "British Indian Ocean Territory"
    }, {

        name: "British Virgin Islands",
        code: "VG",
        country_dial_code: "1284",
        label: "British Virgin Islands",
        value: "British Virgin Islands"
    }, {

        name: "BRUNEI DARUSSALAM",
        code: "BN",
        country_dial_code: "673",
        label: "BRUNEI DARUSSALAM",
        value: "BRUNEI DARUSSALAM"
    }, {

        name: "Bulgaria",
        code: "BG",
        country_dial_code: "359",
        label: "Bulgaria",
        value: "Bulgaria"
    }, {

        name: "Burkina Faso ",
        code: "BF",
        country_dial_code: "226",
        label: "Burkina Faso ",
        value: "Burkina Faso "
    }, {

        name: "BURUNDI",
        code: "BI",
        country_dial_code: "257",
        label: "BURUNDI",
        value: "BURUNDI"
    }, {

        name: "CAMBODIA",
        code: "KH",
        country_dial_code: "855",
        label: "CAMBODIA",
        value: "CAMBODIA"
    }, {

        name: "Cameroon",
        code: "CM",
        country_dial_code: "237",
        label: "Cameroon",
        value: "Cameroon"
    }, {

        name: "CANADA",
        code: "CA",
        country_dial_code: "1",
        label: "CANADA",
        value: "CANADA"
    }, {

        name: "CANARY ISLANDS",
        code: "IC",
        country_dial_code: "34",
        label: "CANARY ISLANDS",
        value: "CANARY ISLANDS"
    }, {

        name: "CAPE VERDE",
        code: "CV",
        country_dial_code: "238",
        label: "CAPE VERDE",
        value: "CAPE VERDE"
    }, {

        name: "CAYMAN ISLAND",
        code: "KY",
        country_dial_code: "1345",
        label: "CAYMAN ISLAND",
        value: "CAYMAN ISLAND"
    }, {

        name: "Central African Republic",
        code: "CF",
        country_dial_code: "236",
        label: "Central African Republic",
        value: "Central African Republic"
    }, {

        name: "Chad",
        code: "TD",
        country_dial_code: "235",
        label: "Chad",
        value: "Chad"
    }, {

        name: "CHILE",
        code: "CL",
        country_dial_code: "56",
        label: "CHILE",
        value: "CHILE"
    }, {

        name: "CHINA",
        code: "CN",
        country_dial_code: "86",
        label: "CHINA",
        value: "CHINA"
    }, {

        name: "Christmas Island",
        code: "CX",
        country_dial_code: "61",
        label: "Christmas Island",
        value: "Christmas Island"
    }, {

        name: "Cocos ",
        code: "CC",
        country_dial_code: "672",
        label: "Cocos ",
        value: "Cocos "
    }, {

        name: "COLOMBIA",
        code: "CO",
        country_dial_code: "57",
        label: "COLOMBIA",
        value: "COLOMBIA"
    }, {

        name: "COMOROS",
        code: "KM",
        country_dial_code: "269",
        label: "COMOROS",
        value: "COMOROS"
    }, {

        name: "CONGO",
        code: "CG",
        country_dial_code: "242",
        label: "CONGO",
        value: "CONGO"
    }, {

        name: "Congo Democratic ",
        code: "CD",
        country_dial_code: "242",
        label: "Congo Democratic ",
        value: "Congo Democratic "
    }, {

        name: "Cook Islands",
        code: "CK",
        country_dial_code: "682",
        label: "Cook Islands",
        value: "Cook Islands"
    }, {

        name: "COSTA RICA",
        code: "CR",
        country_dial_code: "506",
        label: "COSTA RICA",
        value: "COSTA RICA"
    }, {

        name: "Cote d'Ivoire",
        code: "CI",
        country_dial_code: "225",
        label: "Cote d'Ivoire",
        value: "Cote d'Ivoire"
    }, {

        name: "CROATIA",
        code: "HR",
        country_dial_code: "385",
        label: "CROATIA",
        value: "CROATIA"
    }, {

        name: "Cuba",
        code: "CU",
        country_dial_code: "53",
        label: "Cuba",
        value: "Cuba"
    }, {

        name: "CURACAO",
        code: "CW",
        country_dial_code: "599",
        label: "CURACAO",
        value: "CURACAO"
    }, {

        name: "CYPRUS",
        code: "CY",
        country_dial_code: "357",
        label: "CYPRUS",
        value: "CYPRUS"
    }, {

        name: "CZECH REPUBLIC",
        code: "CZ",
        country_dial_code: "420",
        label: "CZECH REPUBLIC",
        value: "CZECH REPUBLIC"
    }, {

        name: "DENMARK",
        code: "DK",
        country_dial_code: "45",
        label: "DENMARK",
        value: "DENMARK"
    }, {

        name: "DJIBOUTI",
        code: "DJ",
        country_dial_code: "253",
        label: "DJIBOUTI",
        value: "DJIBOUTI"
    }, {

        name: "Dominica",
        code: "DM",
        country_dial_code: "1767",
        label: "Dominica",
        value: "Dominica"
    }, {

        name: "DOMINICAN REPUBLIC",
        code: "DO",
        country_dial_code: "1809",
        label: "DOMINICAN REPUBLIC",
        value: "DOMINICAN REPUBLIC"
    }, {

        name: "Ecuador",
        code: "EC",
        country_dial_code: "593",
        label: "Ecuador",
        value: "Ecuador"
    }, {

        name: "EGYPT",
        code: "EG",
        country_dial_code: "20",
        label: "EGYPT",
        value: "EGYPT"
    }, {

        name: "EL SALVADOR",
        code: "SV",
        country_dial_code: "503",
        label: "EL SALVADOR",
        value: "EL SALVADOR"
    }, {

        name: "Equatorial Guinea",
        code: "GQ",
        country_dial_code: "240",
        label: "Equatorial Guinea",
        value: "Equatorial Guinea"
    }, {

        name: "ERITREA",
        code: "ER",
        country_dial_code: "291",
        label: "ERITREA",
        value: "ERITREA"
    }, {

        name: "ESTONIA",
        code: "EE",
        country_dial_code: "372",
        label: "ESTONIA",
        value: "ESTONIA"
    }, {

        name: "ETHIOPIA",
        code: "ET",
        country_dial_code: "251",
        label: "ETHIOPIA",
        value: "ETHIOPIA"
    }, {

        name: "Faeroe Islands",
        code: "FO",
        country_dial_code: "298",
        label: "Faeroe Islands",
        value: "Faeroe Islands"
    }, {

        name: "FALKLAND ISLANDA",
        code: "FK",
        country_dial_code: "500",
        label: "FALKLAND ISLANDA",
        value: "FALKLAND ISLANDA"
    }, {

        name: "FIJI",
        code: "FJ",
        country_dial_code: "679",
        label: "FIJI",
        value: "FIJI"
    }, {

        name: "FINLAND",
        code: "FI",
        country_dial_code: "358",
        label: "FINLAND",
        value: "FINLAND"
    }, {

        name: "FRANCE",
        code: "FR",
        country_dial_code: "33",
        label: "FRANCE",
        value: "FRANCE"
    }, {

        name: "French Guiana",
        code: "GF",
        country_dial_code: "594",
        label: "French Guiana",
        value: "French Guiana"
    }, {

        name: "French Polynesia",
        code: "PF",
        country_dial_code: "689",
        label: "French Polynesia",
        value: "French Polynesia"
    }, {

        name: "French Southern Territories",
        code: "TF",
        country_dial_code: "0",
        label: "French Southern Territories",
        value: "French Southern Territories"
    }, {

        name: "Gabon",
        code: "GA",
        country_dial_code: "241",
        label: "Gabon",
        value: "Gabon"
    }, {

        name: "GAMBIA",
        code: "GM",
        country_dial_code: "220",
        label: "GAMBIA",
        value: "GAMBIA"
    }, {

        name: "Georgia",
        code: "GE",
        country_dial_code: "995",
        label: "Georgia",
        value: "Georgia"
    }, {

        name: "GERMANY",
        code: "DE",
        country_dial_code: "49",
        label: "GERMANY",
        value: "GERMANY"
    }, {

        name: "GHANA",
        code: "GH",
        country_dial_code: "233",
        label: "GHANA",
        value: "GHANA"
    }, {

        name: "GIBRALTAR",
        code: "GI",
        country_dial_code: "350",
        label: "GIBRALTAR",
        value: "GIBRALTAR"
    }, {

        name: "GREECE",
        code: "GR",
        country_dial_code: "30",
        label: "GREECE",
        value: "GREECE"
    }, {

        name: "Greenland",
        code: "GL",
        country_dial_code: "299",
        label: "Greenland",
        value: "Greenland"
    }, {

        name: "Grenada",
        code: "GD",
        country_dial_code: "1473",
        label: "Grenada",
        value: "Grenada"
    }, {

        name: "Guadeloupe",
        code: "GP",
        country_dial_code: "590",
        label: "Guadeloupe",
        value: "Guadeloupe"
    }, {

        name: "Guam",
        code: "GU",
        country_dial_code: "1671",
        label: "Guam",
        value: "Guam"
    }, {

        name: "GUATEMALA",
        code: "GT",
        country_dial_code: "502",
        label: "GUATEMALA",
        value: "GUATEMALA"
    }, {

        name: "Guernsey",
        code: "GG",
        country_dial_code: "44",
        label: "Guernsey",
        value: "Guernsey"
    }, {

        name: "GUINEA",
        code: "GN",
        country_dial_code: "224",
        label: "GUINEA",
        value: "GUINEA"
    }, {

        name: "Guinea - Bissau",
        code: "GW",
        country_dial_code: "245",
        label: "Guinea - Bissau",
        value: "Guinea - Bissau"
    }, {

        name: "GUYANA",
        code: "GY",
        country_dial_code: "592",
        label: "GUYANA",
        value: "GUYANA"
    }, {

        name: "HAITI",
        code: "HT",
        country_dial_code: "509",
        label: "HAITI",
        value: "HAITI"
    }, {

        name: "Heard and McDonald Islands",
        code: "HM",
        country_dial_code: "0",
        label: "Heard and McDonald Islands",
        value: "Heard and McDonald Islands"
    }, {

        name: "HONDURAS",
        code: "HN",
        country_dial_code: "504",
        label: "HONDURAS",
        value: "HONDURAS"
    }, {

        name: "HONG KONG",
        code: "HK",
        country_dial_code: "852",
        label: "HONG KONG",
        value: "HONG KONG"
    }, {

        name: "HUNGARY",
        code: "HU",
        country_dial_code: "36",
        label: "HUNGARY",
        value: "HUNGARY"
    }, {

        name: "ICELAND",
        code: "IS",
        country_dial_code: "354",
        label: "ICELAND",
        value: "ICELAND"
    }, {

        name: "INDIA",
        code: "IN",
        country_dial_code: "91",
        label: "INDIA",
        value: "INDIA"
    }, {

        name: "INDONESIA",
        code: "ID",
        country_dial_code: "62",
        label: "INDONESIA",
        value: "INDONESIA"
    }, {

        name: "International Organization",
        code: "ZZ",
        country_dial_code: "0",
        label: "International Organization",
        value: "International Organization"
    }, {

        name: "IRAQ",
        code: "IQ",
        country_dial_code: "964",
        label: "IRAQ",
        value: "IRAQ"
    }, {

        name: "IRELAND",
        code: "IE",
        country_dial_code: "353",
        label: "IRELAND",
        value: "IRELAND"
    }, {

        name: "Isle of Man",
        code: "IM",
        country_dial_code: "44",
        label: "Isle of Man",
        value: "Isle of Man"
    }, {

        name: "ISRAEL",
        code: "IL",
        country_dial_code: "972",
        label: "ISRAEL",
        value: "ISRAEL"
    }, {

        name: "ITALY",
        code: "IT",
        country_dial_code: "39",
        label: "ITALY",
        value: "ITALY"
    }, {

        name: "JAMAICA",
        code: "JM",
        country_dial_code: "1876",
        label: "JAMAICA",
        value: "JAMAICA"
    }, {

        name: "JAPAN",
        code: "JP",
        country_dial_code: "81",
        label: "JAPAN",
        value: "JAPAN"
    }, {

        name: "Jersey",
        code: "JE",
        country_dial_code: "44",
        label: "Jersey",
        value: "Jersey"
    }, {

        name: "JORDAN",
        code: "JO",
        country_dial_code: "962",
        label: "JORDAN",
        value: "JORDAN"
    }, {

        name: "KAZAKSTAN",
        code: "KZ",
        country_dial_code: "7",
        label: "KAZAKSTAN",
        value: "KAZAKSTAN"
    }, {

        name: "KENYA",
        code: "KE",
        country_dial_code: "254",
        label: "KENYA",
        value: "KENYA"
    }, {

        name: "Kiribati",
        code: "KI",
        country_dial_code: "686",
        label: "Kiribati",
        value: "Kiribati"
    }, {

        name: "KOREA - SOUTH",
        code: "KR",
        country_dial_code: "82",
        label: "KOREA - SOUTH",
        value: "KOREA - SOUTH"
    }, {

        name: "KUWAIT",
        code: "KW",
        country_dial_code: "965",
        label: "KUWAIT",
        value: "KUWAIT"
    }, {

        name: "Kyrgyzstan Republic",
        code: "KG",
        country_dial_code: "996",
        label: "Kyrgyzstan Republic",
        value: "Kyrgyzstan Republic"
    }, {

        name: "LAOS",
        code: "LA",
        country_dial_code: "856",
        label: "LAOS",
        value: "LAOS"
    }, {

        name: "LATVIA",
        code: "LV",
        country_dial_code: "371",
        label: "LATVIA",
        value: "LATVIA"
    }, {

        name: "LEBANON",
        code: "LB",
        country_dial_code: "961",
        label: "LEBANON",
        value: "LEBANON"
    }, {

        name: "LESOTHO",
        code: "LS",
        country_dial_code: "266",
        label: "LESOTHO",
        value: "LESOTHO"
    }, {

        name: "LIBERIA",
        code: "LR",
        country_dial_code: "231",
        label: "LIBERIA",
        value: "LIBERIA"
    }, {

        name: "LIBYA",
        code: "LY",
        country_dial_code: "218",
        label: "LIBYA",
        value: "LIBYA"
    }, {

        name: "Liechtenstein",
        code: "LI",
        country_dial_code: "423",
        label: "Liechtenstein",
        value: "Liechtenstein"
    }, {

        name: "LITHUANIA",
        code: "LT",
        country_dial_code: "370",
        label: "LITHUANIA",
        value: "LITHUANIA"
    }, {

        name: "LUXEMBOURG",
        code: "LU",
        country_dial_code: "352",
        label: "LUXEMBOURG",
        value: "LUXEMBOURG"
    }, {

        name: "MACAU",
        code: "MO",
        country_dial_code: "853",
        label: "MACAU",
        value: "MACAU"
    }, {

        name: "Macedonia ",
        code: "MK",
        country_dial_code: "389",
        label: "Macedonia ",
        value: "Macedonia "
    }, {

        name: "MADAGASCAR",
        code: "MG",
        country_dial_code: "261",
        label: "MADAGASCAR",
        value: "MADAGASCAR"
    }, {

        name: "MALAWI",
        code: "MW",
        country_dial_code: "265",
        label: "MALAWI",
        value: "MALAWI"
    }, {

        name: "MALAYSIA",
        code: "MY",
        country_dial_code: "60",
        label: "MALAYSIA",
        value: "MALAYSIA"
    }, {

        name: "MALDIVES",
        code: "MV",
        country_dial_code: "960",
        label: "MALDIVES",
        value: "MALDIVES"
    }, {

        name: "MALI",
        code: "ML",
        country_dial_code: "223",
        label: "MALI",
        value: "MALI"
    }, {

        name: "MALTA",
        code: "MT",
        country_dial_code: "356",
        label: "MALTA",
        value: "MALTA"
    }, {

        name: "Marshall Islands",
        code: "MH",
        country_dial_code: "692",
        label: "Marshall Islands",
        value: "Marshall Islands"
    }, {

        name: "Martinique",
        code: "MQ",
        country_dial_code: "596",
        label: "Martinique",
        value: "Martinique"
    }, {

        name: "MAURITANIA",
        code: "MR",
        country_dial_code: "222",
        label: "MAURITANIA",
        value: "MAURITANIA"
    }, {

        name: "MAURITIUS",
        code: "MU",
        country_dial_code: "230",
        label: "MAURITIUS",
        value: "MAURITIUS"
    }, {

        name: "Mayotte",
        code: "YT",
        country_dial_code: "269",
        label: "Mayotte",
        value: "Mayotte"
    }, {

        name: "MEXICO",
        code: "MX",
        country_dial_code: "52",
        label: "MEXICO",
        value: "MEXICO"
    }, {

        name: "Micronesia",
        code: "FM",
        country_dial_code: "691",
        label: "Micronesia",
        value: "Micronesia"
    }, {

        name: "MOLDOVA",
        code: "MD",
        country_dial_code: "373",
        label: "MOLDOVA",
        value: "MOLDOVA"
    }, {

        name: "Monaco",
        code: "MC",
        country_dial_code: "377",
        label: "Monaco",
        value: "Monaco"
    }, {

        name: "MONGOLIA",
        code: "MN",
        country_dial_code: "976",
        label: "MONGOLIA",
        value: "MONGOLIA"
    }, {

        name: "MONTENEGRO",
        code: "ME",
        country_dial_code: "382",
        label: "MONTENEGRO",
        value: "MONTENEGRO"
    }, {

        name: "Montserrat",
        code: "MS",
        country_dial_code: "1664",
        label: "Montserrat",
        value: "Montserrat"
    }, {

        name: "MOROCCO",
        code: "MA",
        country_dial_code: "212",
        label: "MOROCCO",
        value: "MOROCCO"
    }, {

        name: "MOZAMBIQUE",
        code: "MZ",
        country_dial_code: "258",
        label: "MOZAMBIQUE",
        value: "MOZAMBIQUE"
    }, {

        name: "MYANMAR",
        code: "MM",
        country_dial_code: "95",
        label: "MYANMAR",
        value: "MYANMAR"
    }, {

        name: "NAMIBIA",
        code: "NA",
        country_dial_code: "264",
        label: "NAMIBIA",
        value: "NAMIBIA"
    }, {

        name: "Nauru",
        code: "NR",
        country_dial_code: "674",
        label: "Nauru",
        value: "Nauru"
    }, {

        name: "NEPAL",
        code: "NP",
        country_dial_code: "977",
        label: "NEPAL",
        value: "NEPAL"
    }, {

        name: "NETHERLAND",
        code: "NL",
        country_dial_code: "31",
        label: "NETHERLAND",
        value: "NETHERLAND"
    }, {

        name: "New Caledonia",
        code: "NC",
        country_dial_code: "687",
        label: "New Caledonia",
        value: "New Caledonia"
    }, {

        name: "NEW ZEALAND",
        code: "NZ",
        country_dial_code: "64",
        label: "NEW ZEALAND",
        value: "NEW ZEALAND"
    }, {

        name: "NICARAGUA",
        code: "NI",
        country_dial_code: "505",
        label: "NICARAGUA",
        value: "NICARAGUA"
    }, {

        name: "Niger",
        code: "NE",
        country_dial_code: "227",
        label: "Niger",
        value: "Niger"
    }, {

        name: "NIGERIA",
        code: "NG",
        country_dial_code: "234",
        label: "NIGERIA",
        value: "NIGERIA"
    }, {

        name: "Niue",
        code: "NU",
        country_dial_code: "683",
        label: "Niue",
        value: "Niue"
    }, {

        name: "Norfolk Island",
        code: "NF",
        country_dial_code: "672",
        label: "Norfolk Island",
        value: "Norfolk Island"
    }, {

        name: "Northern Mariana Islands",
        code: "MP",
        country_dial_code: "1670",
        label: "Northern Mariana Islands",
        value: "Northern Mariana Islands"
    }, {

        name: "NORWAY",
        code: "NO",
        country_dial_code: "47",
        label: "NORWAY",
        value: "NORWAY"
    }, {

        name: "OMAN",
        code: "OM",
        country_dial_code: "968",
        label: "OMAN",
        value: "OMAN"
    }, {

        name: "PAKISTAN",
        code: "PK",
        country_dial_code: "92",
        label: "PAKISTAN",
        value: "PAKISTAN"
    }, {

        name: "Palau",
        code: "PW",
        country_dial_code: "680",
        label: "Palau",
        value: "Palau"
    }, {

        name: "Palestinian Territory",
        code: "PS",
        country_dial_code: "970",
        label: "Palestinian Territory",
        value: "Palestinian Territory"
    }, {

        name: "Panama ",
        code: "PA",
        country_dial_code: "507",
        label: "Panama ",
        value: "Panama "
    }, {

        name: "PANAMA CANAL ZONE",
        code: "PZ",
        country_dial_code: "507",
        label: "PANAMA CANAL ZONE",
        value: "PANAMA CANAL ZONE"
    }, {

        name: "PAPUA NEW GUINEA",
        code: "PG",
        country_dial_code: "675",
        label: "PAPUA NEW GUINEA",
        value: "PAPUA NEW GUINEA"
    }, {

        name: "PARAGUAY",
        code: "PY",
        country_dial_code: "595",
        label: "PARAGUAY",
        value: "PARAGUAY"
    }, {

        name: "PERU",
        code: "PE",
        country_dial_code: "51",
        label: "PERU",
        value: "PERU"
    }, {

        name: "PHILIPPINES",
        code: "PH",
        country_dial_code: "63",
        label: "PHILIPPINES",
        value: "PHILIPPINES"
    }, {

        name: "Pitcairn Islands",
        code: "PN",
        country_dial_code: "0",
        label: "Pitcairn Islands",
        value: "Pitcairn Islands"
    }, {

        name: "POLAND",
        code: "PL",
        country_dial_code: "48",
        label: "POLAND",
        value: "POLAND"
    }, {

        name: "PORTUGAL",
        code: "PT",
        country_dial_code: "351",
        label: "PORTUGAL",
        value: "PORTUGAL"
    }, {

        name: "Puerto Rico",
        code: "PR",
        country_dial_code: "1787",
        label: "Puerto Rico",
        value: "Puerto Rico"
    }, {

        name: "QATAR",
        code: "QA",
        country_dial_code: "974",
        label: "QATAR",
        value: "QATAR"
    }, {

        name: "REPUBLIC OF KOSOVO",
        code: "XK",
        country_dial_code: "383",
        label: "REPUBLIC OF KOSOVO",
        value: "REPUBLIC OF KOSOVO"
    }, {

        name: "REPUBLIC OF SERBIA",
        code: "RS",
        country_dial_code: "381",
        label: "REPUBLIC OF SERBIA",
        value: "REPUBLIC OF SERBIA"
    }, {

        name: "Reunion",
        code: "RE",
        country_dial_code: "262",
        label: "Reunion",
        value: "Reunion"
    }, {

        name: "ROMANIA",
        code: "RO",
        country_dial_code: "40",
        label: "ROMANIA",
        value: "ROMANIA"
    }, {

        name: "RUSSIA",
        code: "RU",
        country_dial_code: "70",
        label: "RUSSIA",
        value: "RUSSIA"
    }, {

        name: "Rwanda",
        code: "RW",
        country_dial_code: "250",
        label: "Rwanda",
        value: "Rwanda"
    }, {

        name: "SAINT HELENA",
        code: "SH",
        country_dial_code: "290",
        label: "SAINT HELENA",
        value: "SAINT HELENA"
    }, {

        name: "Saint Kitts and Nevis",
        code: "KN",
        country_dial_code: "1869",
        label: "Saint Kitts and Nevis",
        value: "Saint Kitts and Nevis"
    }, {

        name: "Saint Lucia",
        code: "LC",
        country_dial_code: "1758",
        label: "Saint Lucia",
        value: "Saint Lucia"
    }, {

        name: "Saint Pierre and Miquelon",
        code: "PM",
        country_dial_code: "508",
        label: "Saint Pierre and Miquelon",
        value: "Saint Pierre and Miquelon"
    }, {

        name: "Saint Vincent ",
        code: "VC",
        country_dial_code: "1784",
        label: "Saint Vincent ",
        value: "Saint Vincent "
    }, {

        name: "SAMOA",
        code: "WS",
        country_dial_code: "684",
        label: "SAMOA",
        value: "SAMOA"
    }, {

        name: "San Marino",
        code: "SM",
        country_dial_code: "378",
        label: "San Marino",
        value: "San Marino"
    }, {

        name: "SAO TOME AND PRINCIPE",
        code: "ST",
        country_dial_code: "239",
        label: "SAO TOME AND PRINCIPE",
        value: "SAO TOME AND PRINCIPE"
    }, {

        name: "SAUDI ARABIA",
        code: "SA",
        country_dial_code: "966",
        label: "SAUDI ARABIA",
        value: "SAUDI ARABIA"
    }, {

        name: "Senegal",
        code: "SN",
        country_dial_code: "221",
        label: "Senegal",
        value: "Senegal"
    }, {

        name: "SEYCHELLES",
        code: "SC",
        country_dial_code: "248",
        label: "SEYCHELLES",
        value: "SEYCHELLES"
    }, {

        name: "SIERRA LEONE",
        code: "SL",
        country_dial_code: "232",
        label: "SIERRA LEONE",
        value: "SIERRA LEONE"
    }, {

        name: "SINGAPORE",
        code: "SG",
        country_dial_code: "65",
        label: "SINGAPORE",
        value: "SINGAPORE"
    }, {

        name: "SLOVEKIA",
        code: "SK",
        country_dial_code: "421",
        label: "SLOVEKIA",
        value: "SLOVEKIA"
    }, {

        name: "SLOVENIA",
        code: "SI",
        country_dial_code: "386",
        label: "SLOVENIA",
        value: "SLOVENIA"
    }, {

        name: "SOLOMON ISLANDS",
        code: "SB",
        country_dial_code: "677",
        label: "SOLOMON ISLANDS",
        value: "SOLOMON ISLANDS"
    }, {

        name: "SOMALIA",
        code: "SO",
        country_dial_code: "252",
        label: "SOMALIA",
        value: "SOMALIA"
    }, {

        name: "SOUTH AFRICA",
        code: "ZA",
        country_dial_code: "27",
        label: "SOUTH AFRICA",
        value: "SOUTH AFRICA"
    }, {

        name: "South Georgia and South Sandwich Is.",
        code: "GS",
        country_dial_code: "0",
        label: "South Georgia and South Sandwich Is.",
        value: "South Georgia and South Sandwich Is."
    }, {

        name: "SPAIN",
        code: "ES",
        country_dial_code: "34",
        label: "SPAIN",
        value: "SPAIN"
    }, {

        name: "SRI LANKA",
        code: "LK",
        country_dial_code: "94",
        label: "SRI LANKA",
        value: "SRI LANKA"
    }, {

        name: "SUDAN",
        code: "SD",
        country_dial_code: "249",
        label: "SUDAN",
        value: "SUDAN"
    }, {

        name: "SURINAME",
        code: "SR",
        country_dial_code: "597",
        label: "SURINAME",
        value: "SURINAME"
    }, {

        name: "Svalbard and Jan Mayen",
        code: "SJ",
        country_dial_code: "47",
        label: "Svalbard and Jan Mayen",
        value: "Svalbard and Jan Mayen"
    },
    {
        name: "SWAZILAND",
        code: "SZ",
        country_dial_code: "268",
        label: "SWAZILAND",
        value: "SWAZILAND"
    }, {

        name: "SWEDEN",
        code: "SE",
        country_dial_code: "46",
        label: "SWEDEN",
        value: "SWEDEN"
    }, {

        name: "SWITZERLAND",
        code: "CH",
        country_dial_code: "41",
        label: "SWITZERLAND",
        value: "SWITZERLAND"
    }, {

        name: "SYRIA",
        code: "SY",
        country_dial_code: "963",
        label: "SYRIA",
        value: "SYRIA"
    }, {

        name: "TAIWAN",
        code: "TW",
        country_dial_code: "886",
        label: "TAIWAN",
        value: "TAIWAN"
    }, {

        name: "Tajikistan",
        code: "TJ",
        country_dial_code: "992",
        label: "Tajikistan",
        value: "Tajikistan"
    }, {

        name: "TANZANIA",
        code: "TZ",
        country_dial_code: "255",
        label: "TANZANIA",
        value: "TANZANIA"
    }, {

        name: "THAILAND",
        code: "TH",
        country_dial_code: "66",
        label: "THAILAND",
        value: "THAILAND"
    }, {

        name: "Timor - Leste",
        code: "TL",
        country_dial_code: "670",
        label: "Timor - Leste",
        value: "Timor - Leste"
    }, {

        name: "Togo",
        code: "TG",
        country_dial_code: "228",
        label: "Togo",
        value: "Togo"
    }, {

        name: "Tokelau",
        code: "TK",
        country_dial_code: "690",
        label: "Tokelau",
        value: "Tokelau"
    }, {

        name: "TONGA",
        code: "TO",
        country_dial_code: "676",
        label: "TONGA",
        value: "TONGA"
    }, {

        name: "TRINIDAD AND TOBAGO",
        code: "TT",
        country_dial_code: "1868",
        label: "TRINIDAD AND TOBAGO",
        value: "TRINIDAD AND TOBAGO"
    }, {

        name: "TUNISIA",
        code: "TN",
        country_dial_code: "216",
        label: "TUNISIA",
        value: "TUNISIA"
    }, {

        name: "TURKEY",
        code: "TR",
        country_dial_code: "90",
        label: "TURKEY",
        value: "TURKEY"
    }, {

        name: "Turkmenistan",
        code: "TM",
        country_dial_code: "7370",
        label: "Turkmenistan",
        value: "Turkmenistan"
    }, {

        name: "Turks and Caicos Islands",
        code: "TC",
        country_dial_code: "1649",
        label: "Turks and Caicos Islands",
        value: "Turks and Caicos Islands"
    }, {

        name: "Tuvalu ",
        code: "TV",
        country_dial_code: "688",
        label: "Tuvalu ",
        value: "Tuvalu "
    }, {

        name: "UGANDA",
        code: "UG",
        country_dial_code: "256",
        label: "UGANDA",
        value: "UGANDA"
    }, {

        name: "UKRAINE",
        code: "UA",
        country_dial_code: "380",
        label: "UKRAINE",
        value: "UKRAINE"
    }, {

        name: "UNITED ARAB EMIRATES",
        code: "AE",
        country_dial_code: "971",
        label: "UNITED ARAB EMIRATES",
        value: "UNITED ARAB EMIRATES"
    }, {

        name: "UNITED KINGDOM",
        code: "GB",
        country_dial_code: "44",
        label: "UNITED KINGDOM",
        value: "UNITED KINGDOM"
    }, {

        name: "UNITED STATES MINOR OUTLAYING ISLANDS",
        code: "UM",
        country_dial_code: "246",
        label: "UNITED STATES MINOR OUTLAYING ISLANDS",
        value: "UNITED STATES MINOR OUTLAYING ISLANDS"
    }, {

        name: "UNITED STATES OF AMERICA",
        code: "US",
        country_dial_code: "1",
        label: "UNITED STATES OF AMERICA",
        value: "UNITED STATES OF AMERICA"
    }, {

        name: "URUGUAI",
        code: "UY",
        country_dial_code: "598",
        label: "URUGUAI",
        value: "URUGUAI"
    }, {

        name: "US Virgin Islands",
        code: "VI",
        country_dial_code: "1340",
        label: "US Virgin Islands",
        value: "US Virgin Islands"
    }, {

        name: "Uzbekistan",
        code: "UZ",
        country_dial_code: "998",
        label: "Uzbekistan",
        value: "Uzbekistan"
    }, {

        name: "VANUATU",
        code: "VU",
        country_dial_code: "678",
        label: "VANUATU",
        value: "VANUATU"
    }, {

        name: "Vatican",
        code: "VA",
        country_dial_code: "39",
        label: "Vatican",
        value: "Vatican"
    }, {

        name: "VENEZUELA",
        code: "VE",
        country_dial_code: "58",
        label: "VENEZUELA",
        value: "VENEZUELA"
    }, {

        name: "VIET NAM",
        code: "VN",
        country_dial_code: "84",
        label: "VIET NAM",
        value: "VIET NAM"
    }, {

        name: "Wallis and Futuna",
        code: "WF",
        country_dial_code: "681",
        label: "Wallis and Futuna",
        value: "Wallis and Futuna"
    }, {

        name: "Western Sahara",
        code: "EH",
        country_dial_code: "212",
        label: "Western Sahara",
        value: "Western Sahara"
    }, {

        name: "Yemen",
        code: "YE",
        country_dial_code: "967",
        label: "Yemen",
        value: "Yemen"
    }, {

        name: "YUGOSLAVIA",
        code: "YU",
        country_dial_code: "38",
        label: "YUGOSLAVIA",
        value: "YUGOSLAVIA"
    }, {

        name: "ZAIRE",
        code: "ZR",
        country_dial_code: "243",
        label: "ZAIRE",
        value: "ZAIRE"
    }, {

        name: "ZAMBIA",
        code: "ZM",
        country_dial_code: "260",
        label: "ZAMBIA",
        value: "ZAMBIA"
    },
    {
        name: "ZIMBABWE",
        code: "ZW",
        country_dial_code: "263",
        label: "ZIMBABWE",
        value: "ZIMBABWE"
    }
]

export default countries;
