import React, { useEffect, useLayoutEffect, useState } from "react";
import GalleryFilters from "./GalleryFilters";
import Style from "./Gallery.module.scss";
// import ImageGallery from "./ImageGallery";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Outlet, useLocation } from "react-router-dom";
import RightFilteredCategoryMobile from "./GalleryFilters/RightFilteredCategoryMobile";
import useGallery from "../../Logic/useGallery";
import {
  changeCurrentTabView,
  currentDashboardPage,
  currentTabSelection,
  currentView,
} from "../../store/slices/gallerySlice";
import RightFilteredCategory from "./GalleryFilters/RightFilteredCategory";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Gallery = ({ }) => {
  const { t } = useTranslation(["dashboard"]);
  const [width, height] = useWindowSize();
  const {
    editGallery,
    setEditGallery,
    preSelect,
    setPreSelect,
    hideSelectHandler,
  } = useGallery();

const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname?.split("/")[2]) {
      dispatch(currentTabSelection(location.pathname?.split("/")[2]));
    } else {
      dispatch(currentTabSelection("all"));
    }
  }, [location]);
  function functionabc(){
    alert()
  }
  let currentPath = location.pathname
  .replace("/dashboard", "")
  .replaceAll("/", "");

  return (
    <div className={Style.my_gallery}>
      {location.pathname == "/dashboard/image-edit" ? (
        ""
      ) : (
        <div className={Style.my_gallery_header}>
          {width < 1200 ? (
            // <RightFilteredCategoryMobile
            //   editGallery={editGallery}
            //   setEditGallery={setEditGallery}
            //   preSelect={preSelect}
            //   setPreSelect={setPreSelect}
            // />
            <RightFilteredCategory currentPath={currentPath} />
          ) : (
            <></>
          )}
          {width >= 1200 || !editGallery ? (
            <>
              <h1 className={Style.my_gallery_title}>{t("gallery")}</h1>
              <GalleryFilters
                editGallery={editGallery}
                setEditGallery={setEditGallery}
                preSelect={preSelect}
                setPreSelect={setPreSelect}
                hideSelectHandler={hideSelectHandler}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      )}

      <Outlet />
    </div>
  );
};

export default Gallery;
