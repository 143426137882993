import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { openSlideShowFullview } from "../../store/slices/gallerySlice";
import { getMusicList, openSlideShow } from "../../store/slices/slideshow/QuickSliseShowSlice";

export const useAlbumQuickViewer = () => {
  let dispatch = useDispatch();

  const [currentSlideData, setCurrentSlideData] = useState([]);
  const quickSlideShow = useSelector((state) => state?.quickSlideShow);

  const [openSlideShow, setOpenSlideShow] = useState({ open: false, type: "all" });


  useEffect(() => {
    if (quickSlideShow?.musicList?.length == 0) {
      dispatch(getMusicList());
    }
  }, [])

  const handleOpenSlideShowModal = (item) => {
    if (!item?.images || item?.images?.length == 0) {
      toast.warn("Please Add Some Images !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setCurrentSlideData(item?.images);
      setOpenSlideShow({ open: true, type: "all" })
      // dispatch(openSlideShow({ open: true, type: "all" }))
    }
  };



  const closeQuickSlideShow = () => {
    setOpenSlideShow({ open: false, type: "all" })
    // dispatch(openSlideShow({ open: false, type: "all" }))
  }




  return {
    quickSlideShow,
    currentSlideData,
    setCurrentSlideData,
    handleOpenSlideShowModal,
    closeQuickSlideShow,
    openSlideShow

  };
};
