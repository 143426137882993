import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SelectedImage } from "../../store/slices/GalleryTabSlice";

export const useCustomClick = () => {
    const dispatch = useDispatch()
    const [points, setPoints] = useState({ x: 0, y: 0 });
    const [show, setShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState("")
    const [addToAlbum, setAddToAlbum] = useState({ popup: false, type: "add" });
    const [DeleteModal, setDeleteModal] = useState({
        open: false,
        imageId: null
    });


    useLayoutEffect(() => {
        const handleClick = () => setShow(false);
        window.addEventListener("click", handleClick);
        return () => window.removeEventListener("click", handleClick);
    }, []);

    useEffect(() => {
        if (show) {
            document.querySelector("html").classList.add("overflow-hidden");
        }
        return () => {
            document.querySelector("html").classList.remove("overflow-hidden");
        };
    }, [show]);

    const handleClickContextMenu = (e, item) => {
        e.preventDefault();
        setShow(true)
        dispatch(SelectedImage({ tab: "", imageId: item?.id }))
         setSelectedItem(item?.id);
        setPoints({ x: e.clientX, y: e.clientY });
    }

    return {
        DeleteModal,
        setDeleteModal,
        addToAlbum,
        setAddToAlbum,
        points,
        show,
        selectedItem,
        setPoints,
        setShow,
        handleClickContextMenu
    }
}