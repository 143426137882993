import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DarkThemeToggler from "../../components/DarkThemeToggler";
import PreLogin from "../../components/Layouts/PreLogin";
import CancellationAndRefundPolicy from "../../components/UtilityPages/CancellationAndRefundPolicy";
import CustomerSupport from "../../components/UtilityPages/CustomerSupport";
import FAQ from "../../components/UtilityPages/FAQ";
import InformationAndGuidelines from "../../components/UtilityPages/InfomationAndGuidelines";
import NotFoundPage from "../../components/UtilityPages/NotFoundPage";
import PrivacyPolicy from "../../components/UtilityPages/PrivacyPolicy";
import useDarkmode from "../../utils/utilityHooks/useDarkmode";

const LegalPages = () => {
    const location = useLocation();
    const { theme, themeToggler } = useDarkmode();

    useEffect(() => {
        (
            location.pathname === "/terms-of-service-and-guidelines" ||
            location.pathname === "/privacy-policy" ||
            location.pathname === "/faq" ||
            location.pathname === "/cancellation-and-refund-policy"
            // || location.pathname === "/customer-support" 
        ) && document.body.classList.add('small-header');
        return () => {
            document.body.classList.remove('small-header');
        }
    }, [location.pathname])

    useEffect(() => {
        const allSections = document.querySelectorAll(".anim");
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const element = entry.target;
                    element.classList.add("visible");
                }
            });
        });
        allSections.forEach((section) => {
            observer.observe(section);
        });
    }, [location.pathname]);

    return (
        <>
            <PreLogin hasFooter={true}>
                {
                    location.pathname == '/terms-of-service-and-guidelines' ? (
                        <InformationAndGuidelines />
                    ) : location.pathname === '/privacy-policy' ? (
                        <PrivacyPolicy />
                    ) : location.pathname === '/faq' ? (
                        <FAQ />
                    ) : location.pathname === '/cancellation-and-refund-policy' ? (
                        <CancellationAndRefundPolicy />
                    ) :
                        // location.pathname === '/customer-support' ? (
                        //     <CustomerSupport />
                        // ) : 
                        (
                            <NotFoundPage />
                        )
                }
            </PreLogin>
            <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
        </>
    );
};

export default LegalPages;
