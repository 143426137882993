import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  isImageEditCompleted: false,
  isLoading: true,
  isMediaSaving: false,
  images: {},
};

export const getMediaURLs = createAsyncThunk(
  "user-image-edit/getMediaURLs",
  async (media_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const params = {
        params: {
          media_id,
        },
      };
      const response = await api.get(`image-edit/${media_id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const config = {
  onUploadProgress: function (progressEvent) {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
  },
};

export const saveMedia = createAsyncThunk("user-image-edit/saveMedia", async (params, { rejectWithValue }) => {
  const api = await getAxiosInstance();
  try {
    let data = new FormData();
    if (params.path == "album") {
      data.append("blob_image", params.blob);
      data.append("album_id", params.albumId);
    } else {
      data.append("blob_image", params.blob);
    }

    const response = await api.post(`image-update`, data, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
);




const userImageEditor = createSlice({
  name: "user-image-edit",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    setImageEditStatus: (state, action) => {
      state.isImageEditCompleted = action.payload;
    },
  },
  extraReducers: {
    [getMediaURLs.pending]: (state) => {
      state.isLoading = true;
    },
    [getMediaURLs.fulfilled]: (state, action) => {
      state.images = action.payload.data.result;
      state.isLoading = false;
    },
    [getMediaURLs.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [saveMedia.pending]: (state) => {
      state.isMediaSaving = true;
    },
    [saveMedia.fulfilled]: (state, action) => {
      state.images = action.payload.data.result;
      state.isMediaSaving = false;
    },
    [saveMedia.rejected]: (state, action) => {
      state.isMediaSaving = false;
    },
  },
});

export const { showLoading, setImageEditStatus } = userImageEditor.actions;

export default userImageEditor.reducer;
