import React, { Suspense, useLayoutEffect } from "react";
import "../styles/common.scss";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import MainRoutes from "../utils/RoutesDetails";
import { useEffect, useState } from "react";

import HomePage from "../pages/HomePage";
import DashboardPage from "../pages/DashboardPage";
import Invitations from "../components/Invitations";
import ImageGallery from "../components/Gallery/ImageGallery";
import Favorites from "../components/Favorites";
import Gallery from "../components/Gallery";
import Album from "../components/Album";
import Slideshow from "../components/Slideshow";
import Archive from "../components/Archive/index";
import { SlideShowEditNew } from "../components/Slideshow/SlideShowEditNew";
import FamilyTreePage from "../components/FamilyTreePage";
import { MyRequests } from "../components/MyRequests";
import Preference from "../components/Preference";
import { TellUsAbout } from "../components/TellUsAboutYourFamily";
import GetStartedPage from "../pages/GetStartedPage";
import FamilyTreeForm from "../components/FamilyTreePage/FamilyTreeForm";
import UserProfile from "../components/UserProfile";
import UserProfileOverview from "../components/UserProfile/UserProfileOverview";
import UserProfileGallery from "../components/UserProfile/UserProfileGallery";
import UserProfileViewOnTree from "../components/UserProfile/UserProfileViewOnTree";
import UserProfileFamilyMembers from "../components/UserProfile/UserProfileFamilyMembers";
import ChoosePlanPage from "../pages/ChoosePlanPage";
import StyleGuidePage from "../pages/StyleGuidePage";
import SearchResults from "../components/SearchResults";
import EditProfilePage from "../components/EditProfilePage";
import PreferenceMenu from "../components/PreferenceMenu";
import KeyboardShortcutsList from "../components/KeyboardShortcutsList";
import ContactPage from "../pages/ContactPage";
import LegalPages from "../pages/LegalPages";
import ErrorPage from "../pages/ErrorPage";
import DemoPage from "../pages/DemoPage";
import ImageViewer from "../components/ImageViewer";
import ImageEditComponent from "../components/ImageEditComponent";
import Trash from "../components/Trash";
import ViewContent from "../pages/ViewContent";
import DemoVideo from "../pages/DemoVideo";
import TestPayment from "../components/TestPayment";
import HdfcSuccess from "../components/HdfcSuccess";
import AlbumDetailView from "../components/NewAlbum/AlbumDetailView";



const ScrollToTop = (props) => {
  const location = useLocation();

  useLayoutEffect(() => {
    if (!location.pathname.startsWith("/dashboard/user-profile")) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return <>{props.children}</>;
};

const App = () => {
  const [userToken, setUserToken] = useState("");

  useEffect(() => {
    setUserToken(window.localStorage.getItem("USER_ACCESS_TOKEN"));
  }, []);

  // console.log(MainRoutes,'route...')

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            {MainRoutes?.map((route, index) => {
              return (
                <Route path={route?.path} key={index} element={route?.element}>
                  {route?.Children?.map((child, index) => {
                    return (
                      <Route
                        path={child?.path}
                        key={index}
                        element={child?.element}
                      >
                        {child.grandChildren?.map((grandChild, index) => {
                          return (
                            <Route
                              path={grandChild?.path}
                              key={index}
                              element={grandChild?.element}
                            />
                          );
                        })}
                      </Route>
                    );
                  })}
                </Route>
              );
            })}
          </Routes>
        </ScrollToTop>
      </BrowserRouter>



      {/* <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="music" element={<AudioPlayer tracks={tracks} />} />
            <Route path="service" element={<Service />}>
              <Route index element={<All />} />
              <Route path="latest" element={<Latest />} />
              <Route path="popular" element={<Popular />} />
              <Route path="collection" element={<Collections />} />
            </Route>
            <Route path="/:photoSlug" element={<PhotoPage/>} />
          </Routes>
        </BrowserRouter> */}

    </Suspense>
  );
};

export default App;
