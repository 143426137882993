import CommonModal from "../../../../Modals/commonModal";
import Style from "./DeleteAlbumImageModal.module.scss";
import { useDeleteAlbumImageModal } from "./useDeleteAlbumImageModal";

const DeleteAlbumImageModal = ({ deleteModal, setDeleteModal, albumId }) => {
  const { selectedAlbumImage, handleDelete, albumDetails, handleClose, isDeletingAlbum,
    isDeletingAll } = useDeleteAlbumImageModal({
      setDeleteModal,
      albumId
    });



  const DeleteModalContent = () => {
    return (
      <>
        <h3 className={Style.delete_title}>{`Delete ${selectedAlbumImage?.selectedImage?.length > 1 ? "Photos" : "Photo"
          }`}</h3>
        <p className={Style.delete_content}>
          {`Are you sure you want to delete ${selectedAlbumImage?.selectedImage?.length
            } 
                ${selectedAlbumImage?.selectedImage?.length > 1 ? "Photos" : "Photo"}
                ?`}
        </p>
        <div className={`${Style.delete_btn_wrap} `}>
          {!albumDetails?.default ? <button className={`${isDeletingAll == "loading" && Style.btn_loader} btn-danger`} onClick={() => handleDelete("permanent")}>
            {isDeletingAll !== "loading" && " Delete from All "}
          </button> :
            <button
              className="btn-outline-gray"
              onClick={() => handleClose()}
            >
              Cancel
            </button>}
          <button
            className={`${albumDetails?.default ? 'btn-danger' : 'btn-outline-gray'} ${isDeletingAlbum == "loading" && Style.btn_loader}`}
            onClick={() => handleDelete("album")}
          >
            {isDeletingAlbum !== "loading" && "Delete from Album"}
          </button>
        </div>
      </>
    );
  };

  return (
    <CommonModal
      showModal={deleteModal.popup}
      hide={() => handleClose()}
      className={Style.delete_this_photo_modal}
      title={""}
      content={<DeleteModalContent />}
    />
  );
};

export default DeleteAlbumImageModal;
