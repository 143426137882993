import Style from "./UserProfileGalleryImages.module.scss";
import {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GalleryImage from "../../Gallery/ImageGallery/ImageThumb";
import Masonry from "react-masonry-css";
import useGallery from "../../../Logic/useGallery";
import { useNavigate } from "react-router-dom";
import FullscreenPreview from "../../Gallery/FullscreenPreview";
import {
  getMusicList,
  openSlideShowFullview,
} from "../../../store/slices/gallerySlice";
import SlideShowFullViewTest from "../../Slideshow/SlideShowFullViewTest";
import { useStaredImagesViewer } from "../../../Logic/FavoritesHooks/useStaredImagesViewer";
import StaredFavImageContainer from "../../Gallery/ImageGallery/StaredFavImageContainer";

const UserProfileGalleryImages = ({ breakpointColumnsObj, data }) => {
  const observer = useRef();
  const imgRootRef = useRef(null);
  const imgItemRef = useRef(null);
  const loaction = useLocation();
  const navigate = useNavigate();
  let dispatch = useDispatch();

  const {
    sections,
    currentTab,
    staredImages,
    quickSlideShowActions,
    musicList,
  } = useSelector((state) => state.gallery);

  const {
    handleOpenImageFullView,
    closeSlideShowViewHandler,
    showFullView,
    setShowFullView,
    handleCloseAfterClickedEscape,
  } = useStaredImagesViewer();

  const {
    images,
    handleReArrange,
    getImagesForSection,
    getStaredImages,
    handleReArrangeStaredImages,
  } = useGallery();

  useLayoutEffect(() => {
    if (musicList?.length == 0) {
      dispatch(getMusicList());
    }
  }, []);

  useEffect(() => {
    getImagesForSection("all");
  }, []);

  const moveListItem = useCallback((dragIndex, dropIndex, imgData) => {
    const dragItem = imgData[dragIndex];
    const hoverItem = imgData[dropIndex];
    let changeArray = () => {
      const updatedImages = [...imgData];
      updatedImages[dragIndex] = hoverItem;
      updatedImages[dropIndex] = dragItem;
      handleReArrangeStaredImages(updatedImages, dragItem, hoverItem);
    };
    changeArray();
  }, []);

  return (
    <>
      <div
        ref={imgRootRef}
        className={Style.ImageGallery}
        data-masonry='{"percentPosition": true }'
      >
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {staredImages?.data?.map((image, i) => {
            const lastIndex = staredImages?.data?.length - 1;
            return (
              <div
                key={i}
                ref={lastIndex == i ? imgItemRef : null}
                className={`${Style.StaredImage} ${data?.isRearrange ? Style.shake_active : ""
                  }`}
              >
                <StaredFavImageContainer
                  handleOpenImageFullView={handleOpenImageFullView}
                  key={image.id}
                  image={image}
                  i={i}
                  moveListItem={moveListItem}
                  isShake={data?.isRearrange}
                />
              </div>
            );
          })}
        </Masonry>
      </div>
      
    </>
  );
};

export default UserProfileGalleryImages;
