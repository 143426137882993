import React from 'react'
import useDarkmode from '../../../utils/utilityHooks/useDarkmode';
import PreLogin from "../../../components/Layouts/PreLogin";
import DarkThemeToggler from '../../DarkThemeToggler';
import { useShareAlbum } from './useShareAlbum';
import AlbumImageContainer from './AlbumImageContainer';


const ShareAlbum = () => {
    const { theme, themeToggler } = useDarkmode();
    const { data } = useShareAlbum()
    console.log(data, "ress alvum,")
    return (
        <div>
            <PreLogin hasFooter={true}>
                <AlbumImageContainer data={data} />
            </PreLogin >
            <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
        </div>
    )
}

export default ShareAlbum