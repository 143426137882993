import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  status: "idle",
  descriptionStatus: "idle"
};

export const addTags = createAsyncThunk(
  "tags/addTags",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`add-tag`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeTags = createAsyncThunk(
  "tags/removeTags",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`remove-tag`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addDescription = createAsyncThunk(
  "tags/addDescription",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`add-description`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeDescription = createAsyncThunk(
  "tags/removeDescription",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`remove-description`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const tagSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},

  extraReducers: {
    [addTags.pending]: (state, action) => {
      state.status = "loading";
    },
    [addTags.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [addTags.rejected]: (state, action) => {
      state.status = "failed";
    },
    [addDescription.pending]: (state, action) => {
      state.descriptionStatus = "loading";
    },
    [addDescription.fulfilled]: (state, action) => {
      state.descriptionStatus = "succeeded";
    },
    [addDescription.rejected]: (state, action) => {
      state.descriptionStatus = "failed";
    },
  },
});

export default tagSlice.reducer;
