import Assets from "../../components/Layouts/Assets";
const FATHER = `father`;
const MOTHER = `mother`;
const YOU = `user`;
const PARTNER = `partner`;
const FEMALE = 2;
const MALE = 1;
const AVATAR = Assets.AVATAR;
const CHILDREN = "children";
const AVATAR_01 = Assets.AVATAR_01;
const AVATAR_02 = Assets.AVATAR_02;
const AVATAR_03 = Assets.AVATAR_03;

const usePopUpData = () => {
  const popupData = [
    {
      id: 1,
      data: [
        {
          id: 1,
          connectionWithNode: FATHER,
          connectionName: "Father",
          connection: FATHER,
          img: AVATAR,
        },
        {
          id: 2,
          connectionWithNode: "brother",
          connectionName: "Brother",
          connection: "sibling",
          img: AVATAR_01,
        },
        {
          id: 3,
          connectionWithNode: "spouse",
          connectionName: "Spouse",
          connection: "partner",
          img: AVATAR_02,
        },
      ],
      user: false,
    },
    {
      id: 2,
      data: [
        {
          id: 4,
          connectionWithNode: YOU,
          connectionName: YOU,
        },
      ],
      user: true,
    },
    {
      id: 3,
      data: [
        {
          id: 5,
          connectionWithNode: "mother",
          connectionName: "Mother",
          connection: "mother",
          img: AVATAR_02,
        },
        {
          id: 6,
          connectionWithNode: "sister",
          connectionName: "Sister",
          connection: "sibling",
          img: AVATAR_02,
        },
        {
          id: 7,
          connectionWithNode: "child",
          connectionName: "Child",
          connection: CHILDREN,
          img: AVATAR_01,
        },
      ],
      user: false,
    },
    {
      id: 4,
      data: [
        {
          id: 1,
          connectionWithNode: "pet",
          connectionName: "Pet",
          connection: "pet",
          img: AVATAR_03,
        },
      ],
      user: false,
    },
  ];
  return {
    popupData,
    FATHER,
    MOTHER,
    YOU,
    PARTNER,
    MALE,
    FEMALE,
    CHILDREN,
  };
};

export default usePopUpData;
