import React from 'react'
import Style from './LanguageMenu.module.scss';
import Languages from '../../../constants/languages';
import { useTranslation } from "react-i18next";


const LanguageMenu = ({ showLang, setShowLang, languageSwitch, currentLanguage }) => {
    const { i18n, t } = useTranslation(["common"]);

    return (
        <>
        <div className={Style.language_menu}>
            <div className='container'>
                <h2 className={Style.language_menu_title}><button onClick={() => { setShowLang(false) }}>{t("language")}</button></h2>
                <ul className={Style.language_menu_list}>
                    {
                        Languages.map((lang, i) => {
                            return (
                                <li key={lang.id} onClick={() => languageSwitch(lang.language, lang.flag, lang.code)}>
                                    <input checked={lang.language == currentLanguage} className={Style.language_radio_input} type="radio" id={lang.language} />
                                    <label className={Style.language} htmlFor={lang.language}>{lang.language}</label>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
        </>
    )
}

export default LanguageMenu;