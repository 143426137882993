import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DarkThemeToggler from "../../components/DarkThemeToggler";
import PreLogin from "../../components/Layouts/PreLogin";
import useDarkmode from "../../utils/utilityHooks/useDarkmode";
import { Routes, Route, useParams } from "react-router-dom";
import { useSocial } from "../../Logic/useSocial";
import Style from "./ViewContent.module.scss";
import { Helmet } from "react-helmet";

const ViewContent = () => {
  const location = useLocation();
  const { theme, themeToggler } = useDarkmode();
  let { secret_token } = useParams();
  const { sharedData, shareData } = useSocial();

  useEffect(() => {
    sharedData(secret_token, "photos");
  }, []);

  useEffect(() => {
    location.pathname === "/view-content" &&
      document.body.classList.add("small-header")
    return () => {
      document.body.classList.remove("small-header");
    };
  }, [location.pathname]);

  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("visible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [location.pathname]);

  return (
    <>
      <PreLogin hasFooter={true}>
        <div className={Style.shareContent}>
          <div className={Style.imageSection}>
            <img src={shareData?.src?.original} />
          </div>
          <p>{shareData?.image}</p>
        </div>
      </PreLogin>
      <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
    </>
  );
};
export default ViewContent;
