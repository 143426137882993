import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import styles from "./CheckoutForm.module.scss";
import { usePlans } from "../../Logic/ChoosePlans/usePlans";
import { useNavigate } from "react-router-dom";
import Gpay from "../Payment/Gpay";
import Assets from "../Layouts/Assets";
import LoaderSuccessFailure from "../LoaderSuccessFailure";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

  let navigate = useNavigate();

  const { confirmPaymentAfterSuccess, closePaymentModal, paymentCompleteStatus, message,
    setMessage } = usePlans();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://familyphotosalbum.com/get-started",
      },
      redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      confirmPaymentAfterSuccess(error.payment_intent, "error");
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent?.status == "succeeded") {
      setMessage("Payment Status: " + paymentIntent?.status + "🎉");
      setIsPaymentSuccess(true);
      confirmPaymentAfterSuccess(paymentIntent, "success");
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <>
      {/* <Gpay /> */}
      <form
        id="payment-form"
        className={styles.form_item}
        onSubmit={handleSubmit}
      >
        {isPaymentSuccess ? (
          <div className={styles.PaymentSuccess}>
            <figure className={styles.PaymentSuccess__image}>
              {/* <img src={Assets.payment_success} alt="" /> */}
              <LoaderSuccessFailure status={paymentCompleteStatus} />
            </figure>
            {paymentCompleteStatus == "loading" ?
              <>
                <h4 className={styles.PaymentSuccess__title}>Please Wait....</h4>
                {/* <p className={styles.PaymentSuccess__description}>
              You have successfully purchased plans. Continue to explore FPA
            </p> */}
              </>
              : paymentCompleteStatus == "success" ?
                <>
                  <h4 className={styles.PaymentSuccess__title}>Payment Success</h4>
                  <p className={styles.PaymentSuccess__description}>
                    You have successfully purchased plans. Continue to explore FPA
                  </p>
                </>
                :
                ""
            }

          </div>
        ) : (
          <>
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
            <button
              className={styles.paymentButton}
              disabled={isLoading || !stripe || !elements}
              id="submit"
            >
              <span id="button-text">
                {isLoading ? (
                  <div className={styles.spinner} id="spinner"></div>
                ) : (
                  "Pay now"
                )}
              </span>
            </button>
            {message && (
              <div className={styles.payment_message} id="payment-message">
                {message}
              </div>
            )}
          </>
        )}
      </form>
    </>
  );
}
