import {
  useUploady,
  useBatchAddListener,
  useItemStartListener,
  useItemFinishListener,
  useItemFinalizeListener,
  useAbortAll,
} from "@rpldy/uploady";

import loadImage from "blueimp-load-image";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllImages,
  openMiniUploadBox,
  openUploadModalAction,
  updateAllUploadedImages,
} from "../../../../../store/slices/Gallery/fileUploadSlce";
import imageCompression from "browser-image-compression";
import { useParams } from "react-router-dom";
import { updateAlbumUploadedImages } from "../../../../../store/slices/Album/AlbumDetails";
import {
  getProfileDetails,
  updatePlanPercentage,
} from "../../../../../store/slices/userprofileSlice";

var allowedExtensions = new RegExp(
  /[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|HEIC|heic)$/
);

const useImageUpload = ({ mode }) => {
  let { albumId } = useParams();

  const uploady = useUploady();
  const abortAll = useAbortAll();
  const dispatch = useDispatch();
  const fileUploadState = useSelector((state) => state.fileUpload);

  const fileAlbumState = useSelector((state) => state.albumDetails);

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadingProgress, setIsUploadingprogress] = useState(false);

  const [allFiles, setAllFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);

  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [duplicateImages, setDuplicateImages] = useState([]);
  const [invalidImages, setInvalidImages] = useState([]);

  const [uploadIndex, setUploadIndex] = useState(0);
  const [fileIndex, setFileIndex] = useState(0);

  const [isLimit, setIsLimit] = useState(false);

  const imagesLength =
    images.length + duplicateImages.length + invalidImages.length;


    
  const QA_URL = process.env.REACT_APP_APIURL_QA
  const TEST_URL = process.env.REACT_APP_APIURL;
  const BASE_URL = process.env.REACT_APP_APIURL_LIVE;

  let api = process.env.REACT_APP_DEV_ENV == "PRODUTION" ? BASE_URL : process.env.REACT_APP_DEV_ENV == "DEMO" ? TEST_URL : QA_URL


  //FIXME handle disabled state for the modal
  useEffect(() => {
    dispatch(getAllImages());
  }, []);

  useEffect(() => {
    if (!isUploading) {
      compressedUpload(0);
    }
  }, [files]);

  let albumIds = albumId
    ? fileAlbumState?.resultData?.data?.[albumId]?.images?.map(
      (item) => item?.id
    )
    : [];

  let getGetAlbumImages = fileUploadState.allUploadedImages?.filter((item) =>
    albumIds?.includes(item?.id)
  );

  let currentAlbum = fileAlbumState?.resultData?.data?.[albumId];

  useEffect(() => {
    if (!fileUploadState.openUploadModal?.isOpen && isUploadingProgress) {
      dispatch(openMiniUploadBox(true));
    }
  }, [fileUploadState.openUploadModal?.isOpen]);

  useItemStartListener((item) => {

    setIsUploadingprogress(true);
  });

  useItemFinalizeListener((item) => {

    setIsUploadingprogress(false);
  });

  const addCompletedImages = (item) => {
    images.forEach((img, i) => {
      if (img?.file?.name === item?.file?.name) {
        images[i] = {
          ...images[i],
          thumbnail: item?.uploadResponse?.data?.result?.src?.thumbnail2,
        };
      }
    });
    setUploadIndex((prevState) => prevState + 1);
    setImages([...images]);
    if (mode == "album") {
      if (currentAlbum?.album?.description !== "Faces/Portraits") {
        dispatch(updateAllUploadedImages(item));
      }
      dispatch(updateAlbumUploadedImages(item?.uploadResponse?.data?.result));
    } else {
      if (allFiles?.length > 10) {
        if (newFiles?.length < 10) {
          setNewFiles([...newFiles, item]);
        } else {
          let newFilesTmp = [...newFiles, item];
          newFilesTmp.forEach((item) => {
            dispatch(updateAllUploadedImages(item));
          });
          setNewFiles([]);
        }
      } else {
        dispatch(updateAllUploadedImages(item));
      }
    }
    // dispatch(updatePlanPercentage(fileUploadState?.allUploadedImages?.length));

  };

  const addDuplicate = (item) => {
    //item is file's name
    let tempArr = JSON.parse(JSON.stringify(images));
    let index = tempArr.findIndex((image) => image.file.name === item);
    tempArr.splice(index, 1);
    setImages([...tempArr]);
    setDuplicateImages((state) => [...state, { name: item, duplicate: true }]);
  };

  useBatchAddListener((batch) => {
    if (!fileUploadState?.openUploadDocumentModal?.isOpen) {
      console.log("image added. working 1 at normal upload")
      setImages((state) => [...state, ...batch?.items]);
    }

  });

  const handleFiles = (upldFiles) => {
    let tempArr = [...upldFiles];
    tempArr.forEach((item) => {
      if (
        fileUploadState.allUploadedImages.some(
          (image) => image.name === item.name
        )
      ) {
        setDuplicateImages((state) => [
          ...state,
          { name: item?.name, duplicate: true },
        ]);
      } else if (!allowedExtensions.test(item?.name)) {
        setInvalidImages((state) => [
          ...state,
          { name: item?.name, invalid: true },
        ]);
      } else {
        setFiles((state) => [...state, item]);
        setAllFiles((state) => [...state, item]);
      }
    });
  };

  useItemFinishListener((item) => {
    if (!fileUploadState?.openUploadDocumentModal?.isOpen) {
      console.log("upload finished working 1 at normal upload")
      if (item.uploadResponse?.data?.code === 200) {
        addCompletedImages(item);
      } else if (item.uploadResponse?.data?.code === 403) {
        addDuplicate(item?.file?.name);
      } else if (item.uploadResponse?.data?.code === 402) {
        setIsLimit(true);
        abortAll();
      }
    }
  });


  const handleImageChange = (e) => {
    // uploady.upload(e.target.files);
    handleFiles(e.target.files);
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    handleFiles(e.dataTransfer.files);
  };

  const openFiles = () => {
    document.getElementById("file_upload_input").click();
  };

  const closeModal = () => {
    if (uploadIndex == images?.length) {
      closeUploadModalWhenDone();
    } else {
      dispatch(openUploadModalAction({}));
      setIsLimit(false);
    }
  };

  const closeUploadModalWhenDone = () => {
    setUploadIndex(0);
    setFileIndex(0);
    setImages([]);
    setFiles([]);
    setAllFiles([]);
    setDuplicateImages([]);
    setInvalidImages([]);
    setIsUploading(false);
    dispatch(openUploadModalAction({}));
    dispatch(getProfileDetails());
    setIsLimit(false);
  };

  /********* Compressed Upload ***********/

  const compressedUpload = async (index) => {
    if (!fileUploadState?.openUploadDocumentModal?.isOpen) {
      console.log("compress working 1 at normal upload")
      if (files.length > 0) {
        if (isUploading === false) {
          setIsUploading(true);
        }
        if (index < files.length) {
          handleImageUpload(files[index]).then((r) => {
            compressedUpload(index + 1);
            setFileIndex((prevState) => prevState + 1);
          });
        } else {
          setFiles([]);
          setIsUploading(false);
        }
      }
    }
  };

  const handleImageUpload = async (imageFile) => {
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      let compressedFile = await imageCompression(imageFile, {
        ...options,
        alwaysKeepResolution: true,
      });
      let cmpFile =
        imageFile.size > 4199999
          ? compressedFile.size < 1100000
            ? imageFile
            : compressedFile
          : imageFile;

      let isCompressed =
        imageFile.size > 4199999
          ? compressedFile.size < 1100000
            ? false
            : true
          : false;


      loadImage.parseMetaData(imageFile, (data) => {

        if (data?.exif) {
          uploady.upload(cmpFile, {
            destination: {
              params: {
                exif: JSON.stringify(data?.exif?.getAll()),
                album_id: albumId,
                isCompressed,
              },
              filesParamName: "image",
            },
          });
        } else {
          uploady.upload(cmpFile, {
            destination: {
              params: { album_id: albumId, isCompressed },
              filesParamName: "image",
            },
          });
        }
      });


      return true;
    } catch (error) {

      uploady.upload(imageFile, {
        destination: {
          params: { album_id: albumId },
          filesParamName: "image",
          isCompressed: false,
        },
      });

      console.log(error, "image file not compressed");
      return true;
    }
  };

  const handleClickCloseMiniModalModal = () => {
    abortAll();
    closeUploadModalWhenDone();
    dispatch(openMiniUploadBox(false));
    dispatch(getProfileDetails());
    setIsLimit(false);
  };

  return {
    handleClickCloseMiniModalModal,
    closeUploadModalWhenDone,
    handleImageChange,
    handleDragOver,
    handleDragOut,
    handleDrop,
    openFiles,
    closeModal,
    isDraggingOver,
    fileUploadState,
    imagesLength,
    images,
    duplicateImages,
    invalidImages,
    isUploading,
    uploadIndex,
    allFiles,
    newFiles,
    fileIndex,
    isLimit
  };
};

export default useImageUpload;
