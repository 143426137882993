import React from "react";
import Style from "./HomeBrowsePhotos.module.scss";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Assets from "../Layouts/Assets";
import { useDimensions } from "../../Logic/Dimensions";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import useAuthChecker from "../../Logic/useAuthChecker";
import { authModalHandler, changeScreen } from "../../store/slices/authSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAuthHandler } from "../../Logic/Auth/useAuthHandler";

const HomeBrowsePhotos = () => {
  const windowDimensions = useDimensions();
  const { t } = useTranslation();
  const { isAuthCompleted } = useAuthChecker();
  const dispatch = new useDispatch();
  const {
    handleClickLogin,
  } = useAuthHandler();

  return (
    <div className={`${Style.home_browse_photos} section_browse_photos`}>
      <div className={Style.anim_elements}>
        <figure
          className={`${Style.anim_elem} ${Style.circle_yellow} anim_element anim`}
        >
          <LazyLoadImage src={Assets.browse_circle_yellow} alt="circle_yellow" />
        </figure>
        <figure
          className={`${Style.anim_elem} ${Style.circle_green} anim_element anim`}
        >
          <LazyLoadImage src={Assets.browse_circle_green} alt="circle_yellow" />
        </figure>
        <figure
          className={`${Style.anim_elem} ${Style.circle_grey} anim_element anim`}
        >
          <LazyLoadImage src={Assets.browse_circle_grey} alt="circle_yellow" />
        </figure>
        <figure
          className={`${Style.anim_elem} ${Style.donut_yellow} anim_element anim`}
        >
          <LazyLoadImage src={Assets.browse_donut_yellow} alt="circle_yellow" />
        </figure>
        <figure
          className={`${Style.anim_elem} ${Style.donut_grey} anim_element anim`}
        >
          <LazyLoadImage src={Assets.browse_donut_grey} alt="circle_yellow" />
        </figure>
      </div>
      <Container>
        <div
          className={`${Style.home_browse_photos_title
            } ${"section_title_block center"}`}
        >
          <h2 className="anim load-anim">{t("browse&upload")}</h2>
          <p className="anim load-anim">{t("browse&upload_desc")}</p>
        </div>
        <div className={`${Style.home_browse_photos_button} anim load-anim`}>
          <button
            onClick={() => {
              if (isAuthCompleted) {
                window.location = "/dashboard";
              } else {
                handleClickLogin()
              }
            }}
            className="btn btn-primary"
          >
            {t("browse_btn")}
          </button>
        </div>
        <Row className={`${Style.home_browse_photos_gallery} anim load-anim`}>
          {windowDimensions.width >= 992 ? (
            <>
              <Col>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_1} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_4} alt="browse-sample" />
                </figure>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_1} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_11} alt="browse-sample" />
                </figure>
              </Col>
              <Col>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_1} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_12} alt="browse-sample" />
                </figure>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_2} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_2} alt="browse-sample" />
                </figure>
              </Col>
              <Col>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_1} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_3} alt="browse-sample" />
                </figure>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_3} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_7} alt="browse-sample" />
                </figure>
              </Col>
              <Col>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_1} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_1} alt="browse-sample" />
                </figure>
              </Col>
              <Col>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_1} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_8} alt="browse-sample" />
                </figure>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_3} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_6} alt="browse-sample" />
                </figure>
              </Col>
              <Col>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_1} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_5} alt="browse-sample" />
                </figure>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_2} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_10} alt="browse-sample" />
                </figure>
              </Col>
              <Col>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_1} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_9} alt="browse-sample" />
                </figure>
                <figure
                  className={`${Style.home_browse_photos_image} ${Style.aspect_1} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_13} alt="browse-sample" />
                </figure>
              </Col>
            </>
          ) : (
            <>
              <Col className={Style.column}>
                <Row>
                  <Col>
                    <figure
                      className={`${Style.home_browse_photos_image_mob} ${Style.aspect_mob_1} anim load-anim`}
                    >
                      <LazyLoadImage src={Assets.browse_mob_1} alt="browse-sample" />
                    </figure>
                  </Col>
                  <Col>
                    <figure
                      className={`${Style.home_browse_photos_image_mob} ${Style.aspect_mob_2} anim load-anim`}
                    >
                      <LazyLoadImage src={Assets.browse_mob_2} alt="browse-sample" />
                    </figure>
                    <figure
                      className={`${Style.home_browse_photos_image_mob} ${Style.aspect_mob_3} anim load-anim`}
                    >
                      <LazyLoadImage src={Assets.browse_mob_3} alt="browse-sample" />
                    </figure>
                  </Col>
                </Row>
              </Col>
              <Col className={Style.column}>
                <figure
                  className={`${Style.home_browse_photos_image_mob} ${Style.aspect_mob_4} anim load-anim`}
                >
                  <LazyLoadImage src={Assets.browse_mob_4} alt="browse-sample" />
                </figure>
              </Col>
              <Col className={Style.column}>
                <Row>
                  <Col>
                    <figure
                      className={`${Style.home_browse_photos_image_mob} ${Style.aspect_mob_2} anim load-anim`}
                    >
                      <LazyLoadImage src={Assets.browse_mob_5} alt="browse-sample" />
                    </figure>
                    <figure
                      className={`${Style.home_browse_photos_image_mob} ${Style.aspect_mob_3} anim load-anim`}
                    >
                      <LazyLoadImage src={Assets.browse_mob_6} alt="browse-sample" />
                    </figure>
                  </Col>
                  <Col>
                    <figure
                      className={`${Style.home_browse_photos_image_mob} ${Style.aspect_mob_1} anim load-anim`}
                    >
                      <LazyLoadImage src={Assets.browse_mob_7} alt="browse-sample" />
                    </figure>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default HomeBrowsePhotos;
