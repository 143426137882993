import React from "react";
import Assets from "../Layouts/Assets";
import Icons from "../Layouts/Icons";
import Style from "./SlideshowList.module.scss";
import { useSlideShowThumb } from "./useSlideShowThumb";

const SlideshowList = React.forwardRef(({ item, lastIndex, index }, ref) => {
  const { selections, loadSlideShow, setLoadSlideShow, handleSelectSlideShow } =
    useSlideShowThumb();

  return (
    <div
      ref={lastIndex == index ? ref : null}
      className={`${Style.slideshow_list_item}  ${
        loadSlideShow ? "slideshow-loaded" : "slideshow-loading"
      }
        ${
          selections?.ImageSlideshowTab?.selction &&
          !selections?.ImageSlideshowTab.selectedImage?.includes(item?.id) &&
          Style.preselection
        }
        `}
      onClick={() => handleSelectSlideShow(item?.id)}
    >
      <div className={Style.slideshow_list_item_card}>
        {selections?.ImageSlideshowTab.selectedImage?.includes(item?.id) &&
        selections?.ImageSlideshowTab?.selction ? (
          <figure className={Style.gallery_select_tick}>
            <Icons icon={"icon-tick"} size={15} />
          </figure>
        ) : (
          <div
            className={`${
              selections?.ImageSlideshowTab?.selction && Style.selectionItem
            }`}
          />
        )}
        <figure
          className={`${Style.slideshow_list_item_image} ${
            !item?.src?.thumbnail && Style.no_data
          }`}
        >
          <img
            src={
              !(item?.src?.medium_thumbnail === undefined)
                ? item?.src?.medium_thumbnail
                : Assets.blank_folder
            }
            alt=""
            onLoad={() => setLoadSlideShow(true)}
          />
          <div className={Style.slideshow_play_btn}>
            <Icons icon={"slideshow-play-btn"} size={46} />
          </div>
        </figure>
        {loadSlideShow ? (
          <>
            <div className={Style.slideshow_list_item_data}>
              <h4 className={Style.slideshow_name}>
                {item?.title?.substring(0, 24)}
                {item?.title?.length >= 24 ? "..." : ""}
              </h4>
            </div>
          </>
        ) : (
          <>
            <div className={Style.slideshow_list_item_data}>
              <h4 className={Style.slideshow_name}></h4>
              <span className={Style.slideshow_duration}></span>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default SlideshowList;
