import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDimensions } from "../Logic/Dimensions";
import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
  logOut,
} from "../store/slices/authSlice";

import { DeleteCoverPhoto } from "../store/slices/userprofileSlice";
import imageCompression from "browser-image-compression";

import {
  coverPhotoUpload,
  RemoveCoverPhoto,
} from "../store/slices/coverPhotoUploadSlice";
import { useProfile } from "../Logic/useProfile";

export const useCoverPhoto = () => {
  const dispatch = useDispatch();
  const [IsCoverPhotoDeleting, setIsCoverPhotoDeleting] = useState(false);
  const [IsCoverPhotoUploading, setIsCoverPhotoUploading] = useState(false);
  const [tempCoverPhotoObj, setTempCoverPhotoObj] = useState({});
  const inputFile = useRef(null);
  const inputFileForm = useRef(null);
  const { replaceCoverPhotoObj } = useProfile();

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const CoverButtonLabel = (data) => {
    switch (data) {
      case 1:
        return "Upload cover photo";
        break;
      case 2:
        return "Edit cover photo";
        break;
      case 3:
        return "Uploading...";
        break;
      case 4:
        return "Upload cover";
        break;
      case 5:
        return "Removing...";
        break;
      case 6:
        return "Remove photo";
        break;
      default:
        return "";
    }
  };

  const options = {
    maxSizeMB: 3,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };



  const handleDragOverCover = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragOutCover = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDropCover = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const compressedFile = await imageCompression(e?.dataTransfer?.files[0], {
      ...options,
      alwaysKeepResolution: true,
    });
    setIsCoverPhotoUploading(true);
    dispatch(
      coverPhotoUpload({
        photo: compressedFile,
      })
    )
      .unwrap()
      .then((res) => {
        const { success, result } = res?.data;
        if (success && success !== undefined) {
          inputFileForm?.current?.reset?.();
          replaceCoverPhotoObj(result?.src);
          setIsCoverPhotoUploading(false);
        }
      });
  }


  const UploadCover = async () => {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(inputFile.current.files[0].name)) {
      // alert('Please upload file having extensions .jpeg/.jpg/.png/.gif only.');
      inputFileForm.current.reset();
      return false;
    } else {
      const compressedFile = await imageCompression(inputFile?.current?.files[0], {
        ...options,
        alwaysKeepResolution: true,
      });
      setIsCoverPhotoUploading(true);
      dispatch(
        coverPhotoUpload({
          photo: compressedFile,
        })
      )
        .unwrap()
        .then((res) => {
          const { success, result } = res?.data;
          if (success && success !== undefined) {
            inputFileForm?.current?.reset?.();
            replaceCoverPhotoObj(result?.src);
            setIsCoverPhotoUploading(false);
          }
        });
    }
  };

  const TriggerRemoveCoverPhoto = () => {
    setIsCoverPhotoDeleting(true);
    dispatch(RemoveCoverPhoto())
      .unwrap()
      .then((res) => {
        const { success, result } = res?.data;
        if (success && success !== undefined) {
          dispatch(DeleteCoverPhoto());
          setIsCoverPhotoDeleting(false);
        }
      });
  };

  return {
    inputFile,
    inputFileForm,
    onButtonClick,
    UploadCover,
    TriggerRemoveCoverPhoto,
    IsCoverPhotoUploading,
    setIsCoverPhotoDeleting,
    IsCoverPhotoDeleting,
    setIsCoverPhotoUploading,
    CoverButtonLabel,
    handleDragOverCover,
    handleDragOutCover,
    handleDropCover
  };
};
