import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSlideShowFullview } from "../../store/slices/gallerySlice";
export const useStaredImagesViewer = () => {
  const [showFullView, setShowFullView] = useState({ index: 0, isOpen: false });
  const { quickSlideShowActions } = useSelector((state) => state.gallery);
  let dispatch = useDispatch();

  const handleOpenImageFullView = (i) => {
    setShowFullView({
      index: i,
      isOpen: true,
      type: "staredImages",
    });
  };

  useEffect(() => {
    if (quickSlideShowActions?.staredImages?.showSlideShowContainer) {
      setShowFullView({
        ...showFullView,
        index: "",
        isOpen: false,
      });
    }
  }, [quickSlideShowActions?.staredImages?.showSlideShowContainer]);

  const closeSlideShowViewHandler = (i) => {
    let data = {
      isOpen: false,
      type: "all",
    };
    dispatch(openSlideShowFullview(data));
    setShowFullView({
        ...showFullView,
      index: i,
      isOpen: true,
    });
  };

  const handleCloseAfterClickedEscape = (i) => {
    if (quickSlideShowActions?.staredImages?.showSlideShowContainer) {
      setShowFullView({
        ...showFullView,
        index: i,
        isOpen: true,
      });
    }
    let data = {
      isOpen: false,
      type: "staredImages",
    };
    dispatch(openSlideShowFullview(data));
  };

  return {
    handleOpenImageFullView,
    closeSlideShowViewHandler,
    showFullView,
    setShowFullView,
    handleCloseAfterClickedEscape
  };
};
