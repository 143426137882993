import React, { useEffect, useLayoutEffect, useRef } from "react";
import useGallery from "../../Logic/useGallery";
import GalleryImage from "../Gallery/ImageGallery/ImageThumb";
import Masonry from "react-masonry-css";
import { useSelector } from "react-redux";
import Style from "../Gallery/ImageGallery/ImageGallery.module.scss";
import { DashboardLoader } from "../DashboardLoader";
import GalleryBlankPage from "../BlankGallery";
import { currentDashboardPage } from "../../store/slices/gallerySlice";
import StaredFavImageContainer from "../Gallery/ImageGallery/StaredFavImageContainer";
import { useState } from "react";
import FullscreenPreview from "../Gallery/FullscreenPreview";
import { useStaredFavorites } from "./useStaredFavorite";

const breakpointColumnsObj = {
  default: 5,
  1599: 4,
  991: 3,
  575: 2,
  374: 1,
};

const StaredFavorite = () => {
  const observer = useRef();
  const imgRootRef = useRef(null);
  const imgItemRef = useRef(null);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const { favorites, loadMore } = useStaredFavorites();

  useEffect(() => {//FIXME loadmore not working 
    if (imgItemRef.current) {
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries.every((entry) => {
            return entry.isIntersecting;
          })
        ) {
          loadMore()
        }
      });
      observer.current.observe(imgItemRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [favorites?.resultData?.data]);

  return (
    <div
      ref={imgRootRef}
      className={`${Style.image_gallery} masonry-wrap`}
      data-masonry='{"percentPosition": true }'
    >
      {favorites?.status == "succeeded" &&
      favorites?.resultData?.data?.length > 0 ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {favorites?.resultData?.data?.map((image, i) => {
            const lastIndex = favorites?.resultData?.data?.length - 1;
            return (
              <div key={i} ref={lastIndex == i ? imgItemRef : null}>
                <StaredFavImageContainer
                  handleOpenImageFullView={{}}
                  key={image.id}
                  image={image}
                  i={i}
                  loadMore={loadMore}
                />
              </div>
            );
          })}
        </Masonry>
      ) : favorites?.status == "loading" ? (
        <DashboardLoader data={favorites?.resultData?.data} />
      ) : (
        <GalleryBlankPage
          title={`Your favorites are empty`}
          description={`The favorites collections you create are shown here`}
          buttonText={``}
          handleClick={{}}
        />
      )}
    </div>
  );
};

export default StaredFavorite;
