import React, { useEffect, useRef, useState } from "react";
import ImageGallery from "../../Gallery/ImageGallery";
import ImageListMasonry from "../../ImageListMasonry";
import Style from "./AlbumDetailView.module.scss";
import { useAlbumView } from "./useAlbumView";
import sample1 from "../../../assets/sample1.pdf"
import * as pdfjsLib from 'pdfjs-dist'
import 'pdfjs-dist/build/pdf.worker.entry';
import Icons from "../../Layouts/Icons";
import ShareModal from "../../ShareModal";
import { DashboardLoader } from "../../DashboardLoader";
import GalleryBlankPage from "../../BlankGallery";
import QuickSlideShow1 from "../../Slideshow/QuickSlideShow1";



const AlbumDetailView = () => {
  const { data, status, selections, token, albumAddToSelection, frames, albumId, openShare, NodataContent, rearrangeItems, loadMore, handleShareAlbum, handleDuration, setOpenShare } = useAlbumView();

  return (
    <div>
      {albumAddToSelection?.selction ? (
        <ImageGallery />
      ) : (
        <>
          <div className={Style.albumDetails}>
            <div className={Style.info}>
              <h2>{data?.album?.title}
                <span >
                  {`${data?.images?.length ? data?.images?.length : 0}`}
                </span>
              </h2>
              <p>{data?.album?.description} </p>
            </div>
            {data?.images?.length > 0 ?
              <button
                className={`${Style.albumShare}`}
                onClick={() => handleShareAlbum()}
              >
                <Icons icon={"share2"} size={14} />
                {" "}
                <span>Share</span>
              </button>
              :
              ""

            }

          </div>
          <div style={{ padding: "10px" }}>
            <ImageListMasonry
              total={data?.images?.length}
              Images={data?.images}
              loadMore={loadMore}
              NodataContent={{}}
              options={selections}
              moveListItem={rearrangeItems}
              albumData={data?.album}
              frames={frames}
              mode="album"
              isShare={data?.album?.photofind_lock}
            />
          </div>

        </>
      )}
      {status == "loading" ? <DashboardLoader data={data?.images?.length} /> : ""}
      {data?.images?.length == 0 &&
        status == "succeeded" &&
        NodataContent?.title != undefined && (
          <GalleryBlankPage
            title={NodataContent?.title}
            description={NodataContent?.description}
         
          />
        )}

      <ShareModal
        openShare={openShare}
        setOpenShare={setOpenShare}
        from="profile"
        secretToken={token}
        path={"albumshare"}
        handleDuration={handleDuration}
        albumId={albumId}
      />

     
    </div>
  );
};

export default AlbumDetailView;
