import React from 'react'
import Style from './nodata.module.scss'
import { useNavigate } from 'react-router-dom';
const NoDataContainer = () => {
    let navigate = useNavigate();

    const handleGoHome = () => {
        navigate("/")
    }

    return (
        <div className={Style.err_container}>
            <div className={Style.message}>
                <h6 className={Style.error_code}>419</h6>
                <div className={Style.vertical_line}></div>
                <h6>Sorry,this url has expired</h6>
            </div>
            <div className={Style.err_btn} onClick={() => handleGoHome()}>
                Go Home
            </div>
        </div>
    )
}

export default NoDataContainer