import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFavoriteImages,
  LoadmoreImages,
} from "../../store/slices/favoriteSlice";

export const useStaredFavorites = () => {
  const dispatch = useDispatch();

  const favorites = useSelector((state) => state?.favorite);
  const selections = useSelector((state) => state.galleryTab);

  useEffect(() => {
    if (favorites?.status == "idle") {
      let obj = {
        page: "1",
        slug: "",
      };
      dispatch(getFavoriteImages(obj));
    }
  }, []);

  const loadMore = (slug, type) => {
    if (
      favorites?.resultData?.current_page < favorites?.resultData?.last_page
    ) {
      let obj = {
        page: favorites?.resultData?.current_page + 1,
        slug: selections?.ImageFavoriteTab?.sort,
      };
      dispatch(LoadmoreImages(obj));
    }
  };

  return {
    favorites,
    loadMore,
  };
};
