export const data = [
    {
        versions: [
            {
                id: 1,
                name: "File 1",
                time: "10:00 AM",
                status: "Current Version",
                date: "June 1, 2023 10:00 AM",
                isLatest: true
            },
        ]
    },
    {

        type: "PREVIOUS 7 DAYS",
        versions: [
            {
                id: 2,
                name: "File 1",
                time: "10:00 AM",
                status: "Current Version",
                date: "June 2, 2023 10:00 AM",
            },
            {
                id: 3,
                name: "File 4",
                time: "02:30 PM",
                status: "Previous Version",
                date: "June 4, 2023 9:00 AM",
            },
            {
                id: 4,
                name: "File 4",
                time: "02:30 PM",
                status: "Previous Version",
                date: "June 4, 2023 7:00 AM",
            }
        ]
    },

    {
        versions: [
            {
                id: 5,
                name: "File 5",
                time: "08:45 AM",
                status: "Current Version",
                date: "June 6, 2023 10:00 AM",
            }
        ]
    },
    {

        type: "OTHER DAYS",
        versions: [
            {
                id: 6,
                name: "File 2",
                time: "11:30 AM",
                status: "Previous Version",
                date: "June 7, 2023 10:00 AM",
            },
            {
                id: 7,
                name: "File 3",
                time: "03:45 PM",
                status: "Previous Version",
                date: "June 9, 2023 10:00 AM",
            }
        ]
    },
];



export const newData = {
    "type1": "today",
    "version1": [
        {
            "date": "22 Jun 2023 02:12 PM",
            "title": "test",
            "id": 1
        },
        {
            "date": "22 Jun 2023 02:12 PM",
            "title": "test3",
            "id": 4
        }
    ],
    "type3": "previous7",
    "version3": [
        {
            "date": "15 Jun 2023 02:12 PM",
            "title": "test2",
            "id": 3
        }
    ],
    "type4": "older",
    "version4": [
        {
            "date": "09 May 2023 02:12 PM",
            "title": "test1",
            "id": 2
        },
        {
            "date": "12 Jun 2023 02:12 PM",
            "title": null,
            "id": 5
        },
        {
            "date": "14 Jun 2023 02:12 PM",
            "title": null,
            "id": 6
        }
    ]
}