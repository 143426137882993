import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const HdfcRedirect = () => {
  const { hdfc_payment } = useSelector((state) => state.payment);

  useEffect(() => {
    let form = document.getElementById("redirect_form");
    if (hdfc_payment && form) {
      form.submit();
    }
  }, [hdfc_payment]);


  return (
    <div>
      {hdfc_payment && (
        <form
          method='post'
          name='redirect'
          id='redirect_form'
          action={hdfc_payment?.base_url}
        >
          <input
            type='hidden'
            name='encRequest'
            value={hdfc_payment?.encrypted_data}
          />
          <input
            type='hidden'
            name='access_code'
            value={hdfc_payment?.access_code}
          />
            <input
            type='hidden'
            name='payment_option'
            value="OPTMOBP"
          />
        </form>
      )}
    </div>
  );
};
export default HdfcRedirect;
