import { useEffect, useState } from "react";
import useAddRelations from "./useAddRelations";
import usePopUpData from "./usePopUpData";

//dummy data for update nods

const UseFamilyPopup = (props) => {
  const {
    checkActive,
    data,
    active,
    checkDrawerOpen,
    setDrawerData,
    mainData,
    dataDrawer,
    setMainData,
    setPopUpData,
  } = props;
  const { popupData } = usePopUpData();
  const [open, setOpen] = useState(false);
  const currentUserData = data[0]?.userData[0];
  const [selectParent, setSelectParent] = useState(false);
  const [selectChild, setSelectChild] = useState(false);
  const { relationBuilding } = useAddRelations(currentUserData, mainData, data);

  useEffect(() => {
    active &&
      !open &&
      setTimeout(() => {
        setOpen(true);
      }, 100);
  }, []);

  const checkMotherAndFatherExist = mainData.filter(
    (d) => d.id == currentUserData.mid || d.id == currentUserData.fid
  );

  //close popup
  const handleClose = () => {
    active &&
      setTimeout(() => {
        checkActive(false);
      }, 300);
    active && setOpen(false);
  };

  //updating nwe member
  const handleNewNode = ({ connection, spouse, connectionWithNode }) => {
    let [tempdata, newData] = relationBuilding(
      connection,
      spouse,
      connectionWithNode
    );
    setDrawerData([newData]);
    setPopUpData(tempdata);
    handleClose();
    checkDrawerOpen(true);
  };

  return {
    handleClose,
    popupData,
    data,
    currentUserData,
    handleNewNode,
    setSelectParent,
    selectParent,
    setSelectChild,
    selectChild,
    open,
    checkMotherAndFatherExist,
  };
};

export default UseFamilyPopup;
