import { useRef } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadmoreAlbums } from "../../../store/slices/albumSlice";
import { SelectedImage } from "../../../store/slices/GalleryTabSlice";


export const useAlbumListView = (data) => {
  const observer = useRef();
  const imgRootRef = useRef(null);
  const albumItemRef = useRef(null);

  const albums = useSelector((state) => state?.album);

  const [collapseList, setCollapseList] = useState([]);
  const selections = useSelector((state) => state.galleryTab);
  const { default_album_id } = useSelector((state) => state.userprofile.userdata);
  const dispatch = useDispatch();


  useEffect(() => {
    if (data) {
      let list = () => data.reduce((acc, el, i) => (el.locked === false ? [...acc, `${i}`] : acc), []);
      setCollapseList(list())
    }
  }, [data])

  const handleCollapse = (eventKey) => {
    let index = collapseList.indexOf(`${eventKey}`)
    let temp = [...collapseList];
    if (index == -1) {
      temp.push(`${eventKey}`)
      setCollapseList(temp)
    } else {
      temp.splice(index, 1)
      setCollapseList(temp)
    }
  }

  const handleClickSelection = (item) => {
    if (selections?.ImageAlbumTab?.selction && item.id != default_album_id && !item.locked && !item?.photofind_album) {
      dispatch(SelectedImage({ tab: 'album', imageId: item.id }))
    }
  };


  useEffect(() => {//FIXME loadmore not working 
    if (albumItemRef.current) {
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries.every((entry) => {
            return entry.isIntersecting;
          })
        ) {

          //  loadMore()
        }
      });
      observer.current.observe(albumItemRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [albums, albumItemRef?.current]);


  const loadMore = () => {
    if (albums?.resultData?.current_page < albums?.resultData?.last_page) {
      let obj = {
        page: albums?.resultData?.current_page + 1,
        slug: selections?.ImageAlbumTab?.sort

      };
      dispatch(LoadmoreAlbums(obj));
    }
  }

  return {
    collapseList,
    handleCollapse,
    selections,
    handleClickSelection,
    default_album_id,
    albumItemRef,
  };
};
