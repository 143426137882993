import React from "react";
import CommonModal from "../../Modals/commonModal";
import Style from "./DeleteModal.module.scss";

const DeleteModal = ({ open, close, status, handledeleteItem }) => {

  return (
    <CommonModal
      showModal={open}
      hide={close}
      className={Style.delete_this_photo_modal}
      title={""}
      content={
        <>
          <h3 className={Style.delete_title}>Delete this video</h3>
          <p className={Style.delete_content}>
            {`Are you sure you want to delete this video ?`}
          </p>
          <div className={Style.delete_btn_wrap}>
            <button
              className="btn-outline-gray"
              onClick={close}
            >
              Cancel
            </button>
            <button
              className="btn-danger"
              onClick={handledeleteItem}
            >
              {status == "loading" ? "..." : "Continue"}
            </button>
          </div>
        </>
      }
    />
  );
};

export default DeleteModal;
