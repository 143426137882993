export const URLtoFile =  (dataURL, type) => {
    const arr = dataURL.split(",")
    let mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    mime = type
    const file = new Blob([u8arr], { type: mime });

    return file
}
