import React from "react";
import { useSelector } from "react-redux";
import Style from "./FamilyUsersPrice.module.scss";
import moment from "moment";
import Assets from "../Layouts/Assets";

const FamilyUsersPrice = () => {
  const { userdata } = useSelector((state) => state.userprofile);
  console.log(userdata, "data");
  return (
    <div
      className={`${Style.family_users} amily-users-ad`}
      style={{
        "--bgimg": "url(" + Assets.dashboardLeftBarUsageBg + ")",
      }}
    >
      <div className={`${Style.family_users_TopContainer}`}>
        <p className={Style.family_users_usage_title}>Usage</p>
        {userdata?.trail_account_status === 1 ? <span>Trail</span> : ""}
      </div>
      <span className={Style.family_users_usage}>
        {userdata?.percentage ? userdata?.percentage : 0}%
      </span>
      {/* <p className={Style.family_users_expiry}>
        Purchased on{" "}
        {moment(userdata?.activated_plan?.[0]?.payment_details?.purchased_date).format("DD MMM YYYY")}
      </p> */}
      <hr />
      <p className={Style.family_users_expiry}>
        Expires on{" "}
        {moment(userdata?.activated_plan?.[0]?.expires_at).format(
          "DD MMM YYYY"
        )}
      </p>
      <div className={Style.family_users_title}>
        <span>{userdata?.mail}</span>
      </div>
    </div>
  );
};

export default FamilyUsersPrice;
