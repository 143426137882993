import { useDispatch, useSelector } from "react-redux"
import { getFlagsList } from "../../../../store/slices/HomeSlice"
import { useEffect } from "react";

export const useFooter = () => {
    const dispatch = useDispatch()
    const { registeredCountries, registeredCountriesStatus } = useSelector((state) => state.home);


    useEffect(() => {
        if (registeredCountriesStatus == "idle") {
            dispatch(getFlagsList())
        }

    }, [])




    return {
        registeredCountries
    }
}