
import Style from "../../VideoGallery.module.scss";
import Icons from "../../../Layouts/Icons";
import { useDimensions } from "../../../../Logic/Dimensions";
import { useSelector } from "react-redux";

export const SelectAllBtn = ({ handleSelectAll }) => {
    const windowDimensions = useDimensions();
    let selectAllSelect = false

    const { resultData, videoActions } = useSelector((state) => state?.videos);


    return (
        <button
            className={`a-05 ${Style.dashboard_btn} ${"btn btn-border-grey me-2"}`}
            type="button"
            onClick={() => handleSelectAll()}
        >
            {resultData?.data?.length === videoActions?.selected_Ids?.length ? (
                <>
                    <Icons color={'currentColor'} icon={"action-unselect"} size={17} />
                    {windowDimensions.width >= 1200 && ('Deselect All')}
                </>
            ) : (
                <>
                    <Icons color={'currentColor'} icon={"icon-square"} size={17} />
                    {windowDimensions.width >= 1200 && ('Select All')}

                </>
            )}
        </button>
    );
};