import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  isLoadingProfile: "idle",
  isLoading: true,
  userdata: {},
  sections: {
    galleryItems: {},
  },
  familyMembers: {
    data: [],
  },
  profileEdit: {
    isLoading: false,
  },
  currentTab: "overview",
};

export const getFamilyMembersData = createAsyncThunk(
  "userprofile/getFamilyMembersData",
  async (user_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`show-family`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProfileDetails = createAsyncThunk(
  "userprofile/getProfileDetails",
  async (user_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`show-user-profile`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGalleryitems = createAsyncThunk(
  "userprofile/getGalleryitems",
  async (user_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`list-user-images`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "userprofile/updateProfile",
  async (userdata, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const formdata = {
        full_name: userdata.firstName,
        family_name: userdata.familyName,
        gender_id: userdata.gender,
        bio: userdata.bio,
        dob: userdata.dob,
        pids: [],
      };
      const response = await api.post("update-user-profile", formdata);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userProfileSlice = createSlice({
  name: "userprofile",
  initialState,
  reducers: {
    clearProfileState: (state, action) => {
      state.userdata = {};
    },
    updateCoverPhotoObj: (state, action) => {
      state.userdata.src = action.payload.obj;
    },
    DeleteCoverPhoto: (state, action) => {
      state.userdata.src = [];
    },
    showLoading: (state) => {
      state.isLoading = true;
    },
    updateUserProfile: (state, action) => {
      state.userdata = {
        ...state.userdata,
        image: action.payload.img,
        name: action.payload.full_name,
      };
    },
    updateActivePlanAfterPusrchase: (state, action) => {
      state.userdata = {
        ...state.userdata,
        activated_plan: action.payload
      };
    },
    callFunction: (state, action) => {
      action.payload(state, action);
    },

    updatePlanPercentage: (state, action) => {
      console.log(action.payload,"check percentage...")
      let newValue = (action.payload * 100) / 1000

      if (newValue) {
        state.userdata = {
          ...state.userdata,
          percentage: Math.round(newValue)
        }
      }
    }
  },
  extraReducers: {
    [getProfileDetails.pending]: (state) => {
      state.isLoadingProfile = "loading";
    },
    [getProfileDetails.fulfilled]: (state, action) => {
      state.userdata = action.payload.data.result;
      state.isLoadingProfile = "succeed";
    },
    [getProfileDetails.rejected]: (state, action) => {
      state.isLoadingProfile = "error";
    },
    [getFamilyMembersData.pending]: (state) => {
      state.familyMembers.isLoading = true;
    },
    [getFamilyMembersData.fulfilled]: (state, action) => {
      state.familyMembers.isLoading = false;
      state.familyMembers.data = action.payload?.data.result;
    },
    [getFamilyMembersData.rejected]: (state, action) => {
      state.familyMembers.isLoading = false;
      state.familyMembers.status = "error";
    },
    [updateProfile.pending]: (state) => {
      state.profileEdit.isLoading = true;
    },
    [updateProfile.fulfilled]: (state, action) => {
      const { full_name, family_name, bio, dob, gender_id, dob_in_format } =
        action.payload.data.result;
      state.userdata.full_name = full_name;
      state.userdata.family_name = family_name;
      state.userdata.gender_id = gender_id;
      state.userdata.dob = dob;
      state.userdata.bio = bio;
      state.userdata.dob_in_format = dob_in_format;
      state.profileEdit.isLoading = false;
    },
    [updateProfile.rejected]: (state, action) => {
      state.profileEdit.isLoading = false;
    },
  },
});

export const {
  showLoading,
  updateUserProfile,
  updateCoverPhotoObj,
  clearProfileState,
  DeleteCoverPhoto,
  callFunction,
  updateActivePlanAfterPusrchase,
  updatePlanPercentage
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
