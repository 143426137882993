import { useEffect, useState } from "react"
import { openDocMiniUploadBox, openUploadDocumentModalAction, updateAllDocFiles } from "../../../../../store/slices/Gallery/fileUploadSlce"
import { useDispatch, useSelector } from "react-redux"
import { getPdfUrl } from "../../../../../utils/GenerateImageFromPdf";
import {
  useUploady,
  useBatchAddListener,
  useItemStartListener,
  useItemFinishListener,
  useItemFinalizeListener,
  useAbortAll,
} from "@rpldy/uploady";
import loadImage from "blueimp-load-image";

import { useParams } from "react-router-dom";
import { updateAlbumUploadedImages } from "../../../../../store/slices/Album/AlbumDetails";
import { getProfileDetails } from "../../../../../store/slices/userprofileSlice";

export const useDocumentUpload = () => {
  const uploady = useUploady();
  const abortAll = useAbortAll();
  const dispatch = useDispatch()
  let { albumId } = useParams();
  const fileUploadState = useSelector((state) => state.fileUpload);

  const [isDraggingOverDoc, setIsDraggingOverDoc] = useState(false)
  const [docUploadState, setIsDocUploadState] = useState(false)
  const [isUploadingProgress, setIsUploadingprogress] = useState(false);


  const [allDocuments, setAllDocuments] = useState([])
  const [newDocuments, setNewDocuments] = useState([])


  const [docFiles, setDocFiles] = useState([])
  const [documents, setdocuments] = useState([])

  const [duplicateDocuments, setDuplicateDocuments] = useState([])
  const [invalidDocuments, setInvalidDocuments] = useState([])


  const [docUploadIndex, setDocUploadIndex] = useState(0)
  const [docFileIndex, setDocFileIndex] = useState(0)
  const [docLength, setDocLength] = useState(0)
  const [isUploadingDoc, setIsUploadingDoc] = useState(false)


  var allowedExtensions = new RegExp(
    /[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|HEIC|heic|pdf)$/
  );


  const QA_URL = process.env.REACT_APP_APIURL_QA
  const TEST_URL = process.env.REACT_APP_APIURL;
  const BASE_URL = process.env.REACT_APP_APIURL_LIVE;

  let api = process.env.REACT_APP_DEV_ENV == "PRODUTION" ? BASE_URL : process.env.REACT_APP_DEV_ENV == "DEMO" ? TEST_URL : QA_URL

  useEffect(() => {
    if (!isUploadingDoc) {
      compressedUpload(0);
    }
  }, [docFiles]);


  useEffect(() => {
    if (!fileUploadState.openUploadDocumentModal?.isOpen && isUploadingProgress) {
      dispatch(openDocMiniUploadBox(true));
    }
  }, [fileUploadState.openUploadDocumentModal?.isOpen]);

  useItemStartListener((item) => {
    setIsUploadingprogress(true);
  });

  useItemFinalizeListener((item) => {
    setIsUploadingprogress(false);
  });

  const closeWhenDoneDoc = () => {

  }
  const handleImageChangeDoc = (e) => {
    handleFiles(e.target.files);
  }
  const handleDragOverDoc = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDraggingOverDoc(false);
  }
  const handleDragOutDoc = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDraggingOverDoc(true);
  }


  const handleDropDoc = (e) => {
    e.stopPropagation();
    e.preventDefault();

    handleFiles(e.dataTransfer.files);


  }


  const openDocFiles = () => {

  }

  const closeDocModal = () => {
    if (docUploadIndex == documents?.length) {
      closeDocUploadModalWhenDone();
    } else {
      dispatch(openUploadDocumentModalAction({ data: false, path: "document" }));
    }
  };

  // console.log(fileUploadState?.allUploadedDocFiles, "file state...")

  const handleFiles = (upldFiles) => {
    let tempArr = [...upldFiles];

    tempArr.forEach((item) => {
      if (fileUploadState.allUploadedDocFiles.some((image) => image.name === item.name)) {
        setDuplicateDocuments((state) => [...state, { name: item?.name, duplicate: true }]);
      } else if (!allowedExtensions.test(item?.name)) {
        setInvalidDocuments((state) => [
          ...state,
          { name: item?.name, invalid: true },
        ]);
      } else {
        setDocFiles((state) => [...state, item]);
        setAllDocuments((state) => [...state, item]);
      }
    });
  };


  const addCompletedImages = (item) => {
    documents.forEach((img, i) => {
      if (img?.file?.name === item?.file?.name) {
        documents[i] = {
          ...documents[i],
          thumbnail: item?.uploadResponse?.data?.result?.file_type == 2 ? item?.uploadResponse?.data?.result?.src?.preview_url : item?.uploadResponse?.data?.result?.src?.thumbnail2
        };
      }
    });
    setDocUploadIndex((prevState) => prevState + 1);
    setdocuments([...documents]);
    dispatch(updateAlbumUploadedImages(item?.uploadResponse?.data?.result));

    console.log(item?.uploadResponse?.data?.result, item, "ress...")
    dispatch(updateAllDocFiles(item));

    // dispatch(updatePlanPercentage(fileUploadState?.allUploadedImages?.length));

  };


  const compressedUpload = async (index) => {
    if (!fileUploadState.openUploadModal.isOpen) {
      if (docFiles.length > 0) {
        if (isUploadingDoc === false) {
          setIsUploadingDoc(true);
        }
        if (index < docFiles.length) {
          handleImageUpload(docFiles[index]).then((r) => {
            compressedUpload(index + 1);
            setDocFileIndex((prevState) => prevState + 1);
          });
        } else {
          setDocFiles([]);
          setIsUploadingDoc(false);
        }
      }
    }
  };

  useBatchAddListener((batch) => {
    if (!fileUploadState.openUploadModal.isOpen) {
      setdocuments((state) => [...state, ...batch?.items]);
    }
  });

  useItemFinishListener((item) => {
    if (!fileUploadState.openUploadModal.isOpen) {
      if (item.uploadResponse?.data?.code === 200) {
        addCompletedImages(item);
      }
    }
  });


  const handleImageUpload = async (file) => {
    if (file?.type == "application/pdf") {
      let url = await getPdfUrl(file)
      uploady.upload(file, {
        destination: {
          url: `${api}upload/document`,
          params: {
            album_id: albumId,
            preview: url,
            isCompressed: false
          },
          filesParamName: "file",
        },
      });
    } else {
      loadImage.parseMetaData(file, (data) => {
        if (data?.exif) {
          uploady.upload(file, {
            destination: {
              url: `${api}upload/document`,
              params: {
                exif: JSON.stringify(data?.exif?.getAll()),
                album_id: albumId,
                preview: null,
                isCompressed: false,
              },
              filesParamName: "file",
            },
          });
        } else {
          uploady.upload(file, {
            destination: {
              url: `${api}upload/document`,
              params: {
                album_id: albumId,
                isCompressed: false,
                preview: null,
                file_name: file.name,
              },
              filesParamName: "file",
            },
          });
        }
      });
    }
  };

  const handleCloseDocMiniModal = () => {
    abortAll();
    closeDocUploadModalWhenDone();
    dispatch(openDocMiniUploadBox(false));
    // dispatch(getProfileDetails());
    // setIsLimit(false);
  };

  const closeDocUploadModalWhenDone = () => {
    setAllDocuments([])
    setDocFiles([])
    setdocuments([])
    setInvalidDocuments([])
    dispatch(openUploadDocumentModalAction({ data: false, path: "document" }))
    setIsUploadingDoc(false)
    setDocUploadIndex(0)
    setDocFileIndex(0)
    dispatch(getProfileDetails());
  };



  return {
    isDraggingOverDoc,
    docUploadState,
    documents,
    duplicateDocuments,
    invalidDocuments,
    allDocuments,
    newDocuments,
    docUploadIndex,
    isUploadingDoc,
    docFileIndex,
    docLength,
    closeWhenDoneDoc,
    handleImageChangeDoc,
    handleDragOverDoc,
    handleDragOutDoc,
    handleDropDoc,
    closeDocModal,
    openDocFiles,
    handleCloseDocMiniModal,
    closeDocUploadModalWhenDone
  }
}