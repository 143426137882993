import Style from "./OTPVerificationModal.module.scss";
import OtpInput from "react-otp-input";
import { useEffect, useRef, useState } from "react";
import { useRegister } from "../../../Logic/useRegister";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  loginCreateOtp,
  showSignInState,
  editMode,
  changeScreen,
} from "../../../store/slices/authSlice";
import { useLogin } from "../../../Logic/useLogin";
import { useTranslation } from "react-i18next";
import { isEditable } from "@testing-library/user-event/dist/utils";
import { useResendOtp } from "../../../Logic/Auth/useResendOtp";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";

const OTPVerificationModal = ({
  setShowRegister,
  setShowOtp,
  setShowSignIn,
}) => {
  const dispatch = useDispatch();
  const { loginCreateOtpFunction } = useLogin;
  const { onVerifyOtp, code, showOtpErrors, setshowOtpErrors } = useRegister();
  const otpInputRef = useRef();

  const { i18n, t } = useTranslation(["authentication"]);

  const { currentAuthData, mobileVerifyData, otpStatus } = useSelector(
    (state) => state.auth
  );

  const {
    resendOtp,
    otpCounter,
    setOtpCounter,
    otpResponse,
    setOtpResponse,
    isResend,
  } = useResendOtp();

  useEffect(() => {
    const timer =
      otpCounter > 0 && setInterval(() => setOtpCounter(otpCounter - 1), 1000);
    return () => clearInterval(timer);
  }, [otpCounter]);
  const handleEditAuth = () => {
    if (currentAuthData?.pageFrom == "login_phone") {
      dispatch(changeScreen("login"));
    } else {
      dispatch(changeScreen("register"));
      dispatch(editMode(true));
    }
  };
  useEffect(() => {
    if (showOtpErrors || isResend) {
      otpInputRef.current.focusInput(0);
    }
  }, [showOtpErrors || isResend]);

  return (
    <Formik
      initialValues={{ otp: "" }}
      onSubmit={(values) => {
        if (!values.otp || values?.otp?.length < 4) {
          setshowOtpErrors("Otp can't be empty");
        }
      }}
    >
      {({
        handleChange,
        values,
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <div className={Style.otp_verification}>
            <div className={Style.otp_verification_header}>
              <h3 className={Style.otp_verification_title}>
                {t("otp_heading")}
              </h3>
              <p className={Style.otp_verification_info}>
                {currentAuthData?.multiple_account === true
                  ? t("email_otp_desc")
                  : t("otp_desc")}
                {/* {mobileVerifyData?.existing_user === true
                  ? "You already have an account with us. To continue, please enter the OTP "
                  : t("otp_desc")} */}
                <span>
                  <br />
                  {currentAuthData?.multiple_account === true
                    ? currentAuthData?.mail
                    : currentAuthData?.phone_number}
                  {/* {mobileVerifyData?.phone_number
                    ? " " + mobileVerifyData?.phone_number
                    : currentAuthData?.phone_number} */}
                </span>
                <button onClick={() => handleEditAuth()}>
                  {t("otp_edit_btn")}
                </button>
              </p>
            </div>
            <form className={Style.otp_inputs} onSubmit={handleSubmit}>
              <div className="form-group">
                <OtpInput
                  ref={otpInputRef}
                  value={isResend || showOtpErrors ? "" : code}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  name="otp"
                  onChange={(e) => {
                    onVerifyOtp(e, "login_otp");
                    setFieldValue("otp", e);
                  }}
                  numInputs={4}
                  onBlur={handleBlur}
                  inputStyle={`${showOtpErrors ? Style.input_Style_error : ""}`}
                  className="otp-field"
                />
              </div>
              <button
                type="submit"
                className={`${
                  Style.signin_button
                } ${"btn btn-secondary w-100"}`}
                // onClick={() => onVerifyOtp()}
              >
                {otpStatus == "loading" ? <InfiniteDotLoader /> : t("otp_btn")}
              </button>
              <span className={Style.otp_error_message}>{showOtpErrors}</span>
            </form>
            <div className={Style.otp_verification_resend_otp}>
              <span>{t("otp_fetch")}</span>
              {otpCounter > 0 ? <span> 00:{otpCounter}s </span> : ""}
              {otpCounter == 0 ? (
                <button
                  onClick={() =>
                    // resendOtp(
                    //   mobileVerifyData?.phone_number
                    //     ? mobileVerifyData?.phone_number
                    //     : currentAuthData?.phone_number
                    // )
                    resendOtp(
                      currentAuthData?.multiple_account === true
                        ? currentAuthData?.mail
                        : currentAuthData?.phone_number
                    )
                  }
                >
                  {t("otp_resend_btn")}
                </button>
              ) : (
                ""
              )}
            </div>
            {
              <span
                style={{
                  color: `${otpResponse?.success ? "green" : "red"}`,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {otpResponse?.message ? "Otp send successfully" : ""}
              </span>
            }
          </div>
        );
      }}
    </Formik>
  );
};

export default OTPVerificationModal;
