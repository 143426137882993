import React from 'react'
import Icons from "../../../Layouts/Icons";
import { Link } from "react-router-dom";

const CustomButton = ({ baseClass, onMouseEnter, onMouseLeave, onClick, iconName, label, iconSize, LinkUrl }) => {
    return (
        <button
            className={`btn btn-link ${baseClass}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
        >
            {LinkUrl ?
                <>
                    <Link
                        to={LinkUrl}
                    >
                        <Icons icon={iconName} size={iconSize} ></Icons> {label}
                    </Link>
                </>
                :
                <>
                    <Icons icon={iconName} size={iconSize} >  </Icons>{label}

                </>

            }


        </button>
    )
}

export default CustomButton