import { useDispatch } from "react-redux";
import {
  authModalHandler,
  changeScreen,
  logout,
} from "../../store/slices/authSlice";
import { clearGalleryState } from "../../store/slices/gallerySlice";
import { clearProfileState } from "../../store/slices/userprofileSlice";

export const useAuthHandler = () => {
  const dispatch = useDispatch();

  const handleClickRegister = () => {
    dispatch(authModalHandler(true));
    dispatch(changeScreen("register"));
  };
  const handleClickLogin = () => {
    dispatch(authModalHandler(true));
    dispatch(changeScreen("login"));
  };
  const handleClickFP = () => {
    dispatch(changeScreen("forgotPassword"));
  };

  const handleClose = () => {
    dispatch(changeScreen(""));
  };

  const handleLogout = () => {
    dispatch(clearProfileState());
    dispatch(logout());
    dispatch(clearGalleryState());
    loggingOut();
  };

  const loggingOut = () => {
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  return {
    handleClickRegister,
    handleClickLogin,
    handleClickFP,
    handleClose,
    handleLogout,
  };
};
