import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Style from "./Preference.module.scss";
import LanguageMenu from "../Layouts/LanguageMenuMobile/LanguageMenu";
import { usePreference } from "../../Logic/usePreference";
import DefaultMenu from "../Layouts/DefaultMenuMobile/DefaultMenu";
import { Outlet } from "react-router-dom";

const Preference = () => {

  const {
    view,
    availableLanguage,
    languageCategory,
    setShowLang,
    setView,
    setDefaultViewActive,
    languageCategorySwitch,
    capitalizeFirstLetter,
    defaultViewChange,
  } = usePreference();

  
  const {isDefaultViewActive,showLang} = useSelector((state) => state.userpreference);

  return (
    <div className={Style.preference}>
      {showLang ? (
        <div className={Style.language_menu_mob}>
          <LanguageMenu
            availableLanguage={availableLanguage}
            languageCategorySwitch={languageCategorySwitch}
            languageCategory={languageCategory}
            capitalizeFirstLetter={capitalizeFirstLetter}
            setShowLang={setShowLang}
          />
        </div>
      ) : isDefaultViewActive === true ? (
        <div className={Style.language_menu_mob}>
          <DefaultMenu
            view={view}
            setView={setView}
            capitalizeFirstLetter={capitalizeFirstLetter}
            defaultViewChange={defaultViewChange}
            setDefaultViewActive={setDefaultViewActive}
          />
        </div>
      ) : (
        <></>
      )}
      <div className={Style.preference_wrap}>
        <Outlet />
      </div>
    </div>
  );
};

export default Preference;
