
import { useDispatch, useSelector, } from "react-redux";
import { toast } from "react-toastify";
import { removeAlbum, updateAlbumList } from "../../../store/slices/albumSlice";



export const useAlbumDeleteModal = ({ albumDeleteModal ,setAlbumDeleteModal }) => {
    const dispatch = useDispatch();

    const handleDeleteAlbum = async () => {
        let album_id = [albumDeleteModal.album];
        const resultAction = await dispatch(removeAlbum({ album_id }));
        if (removeAlbum.fulfilled.match(resultAction)) {
            toast.success("Album deleted !", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            dispatch(updateAlbumList(albumDeleteModal.album))
            setAlbumDeleteModal({})
        }
    };

    return {
        handleDeleteAlbum
    }
}