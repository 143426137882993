import React from "react";
import { useRef } from "react";
import Assets from "../Layouts/Assets";
import Icons from "../Layouts/Icons";
import Style from "./StarredGalleryGrid.module.scss";
import { useDrag, useDrop, DragPreviewImage } from "react-dnd";
import { useSelector } from "react-redux";
import useGallery from "../../Logic/useGallery";
import { useCallback } from "react";
import StarredImageItem from "./StarredImageItem";

const StarredGalleryGrid = ({ data, openFavSection }) => {
  const { staredImages } = useSelector((state) => state.gallery);

  // const { handleFavItemsForStared } = useGallery();

  return (
    <div className={Style.starred_gallery_grid}>
      {data?.map((image, i) => {
        return <StarredImageItem image={image} i={i} />;
      })}
      <div
        className={Style.starred_gallery_grid_wrap}
        onClick={() => openFavSection()}
      >
        <div className={Style.add_btn_wrap}>
          <button className={Style.add_btn}>
            <figure className={Style.starred_gallery_grid_add_btn_image}>
              <img src={Assets.addToStar} alt="" />
            </figure>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StarredGalleryGrid;
