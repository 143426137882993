
import Style from "../../VideoGallery.module.scss";
import Icons from "../../../Layouts/Icons";
import { useDimensions } from "../../../../Logic/Dimensions";

export const SelectBtn = ({ handlePreSelect }) => {
    const windowDimensions = useDimensions();

    return (
        <button
            className={`a-05 ${Style.dashboard_btn} ${"btn btn-border-grey"}`}
            type="button"
            onClick={() => handlePreSelect()}
        >
            <Icons color={'currentColor'} icon={"icon-square"} size={17} />
            {windowDimensions.width >= 1200 && ('Select')}
        </button>
    );
};