import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";


export const verifyOtpForEmailUpdate = createAsyncThunk(
  "user-email-update/verifyOtpForEmailUpdate",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("change-email", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


