import React from 'react';
import Style from "./NotFoundPage.module.scss";

const NotFoundPage = () => {

  return (
    <>
      <div className={`${Style.not_found_page} inner-page`}>
        <div className="inner-page-wrap">
          <h1 className={Style.error_code}>404</h1>
          <h2 className={Style.error_name}>Sorry! Page Not Found</h2>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage;