import React, { useEffect, useState } from "react";
import Style from "./ChoosePlan.module.scss";
import Assets from "../Layouts/Assets";
import { Link, useLocation } from "react-router-dom";
import HomeChooseAPlan from "../HomeChooseAPlan";
import { useTranslation } from "react-i18next";

const ChoosePlan = () => {
  const { t } = useTranslation(["intro"]);

  const location = useLocation();

  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("visible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [location.pathname]);

  return (
    <>
      <section className={Style.get_started_choose_plan}>
        <div className={Style.anim_elements}>
          <figure
            className={`${Style.anim_elem} ${Style.circle_green} anim_element anim`}
          >
            <img
              src={Assets.get_started_plan_circle_green}
              alt="circle_green"
            />
          </figure>
          <figure
            className={`${Style.anim_elem} ${Style.donut_grey} anim_element anim`}
          >
            <img src={Assets.get_started_plan_donut_grey} alt="donut_grey" />
          </figure>
          <figure
            className={`${Style.anim_elem} ${Style.donut_yellow} anim_element anim`}
          >
            <img
              src={Assets.get_started_plan_donut_yellow}
              alt="donut_yellow"
            />
          </figure>
        </div>
        <HomeChooseAPlan />
      </section>
    </>
  );
};

export default ChoosePlan;
