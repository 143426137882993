import CommonModal from "../../../../Modals/commonModal";
import Style from "./DeleteTrashImageModal.module.scss";
import { useDeleteTrashImageModal } from "./useDeleteTrashImageModal";

const DeleteTrashImageModal = ({ trashDeleteModal, setTrashDeleteModal }) => {
  const { selectedImage, handleDelete, status } = useDeleteTrashImageModal({
    setTrashDeleteModal,
  });

  const DeleteModalContent = () => {
    return (
      <>
        <h3 className={Style.delete_title}>{`Delete ${selectedImage?.selectedImage?.length > 1 ? "Photos" : "Photo"
          }`}</h3>
        <p className={Style.delete_content}>
          {`Are you sure you want to delete ${selectedImage?.selectedImage?.length
            } 
                ${selectedImage?.selectedImage?.length > 1 ? "Photos" : "Photo"
            }
                ?`}
        </p>
        <div className={Style.delete_btn_wrap}>
          <button className={`${status == "loading" && Style.btn_loader} btn-danger`} onClick={() => handleDelete()}>
            {status !== "loading" && "Continue"}
          </button>
          <button
            className="btn-outline-gray"
            onClick={() => setTrashDeleteModal({ popup: false })}
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  return (
    <CommonModal
      showModal={trashDeleteModal.popup}
      hide={() => setTrashDeleteModal({ popup: false })}
      className={Style.delete_this_photo_modal}
      title={""}
      content={<DeleteModalContent />}
    />
  );
};

export default DeleteTrashImageModal;
