import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Style from "./GetStarted.module.scss";
import Assets from "../Layouts/Assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HomeChooseAPlan from "../HomeChooseAPlan";
import VideoPlayer from "../../utils/VideoPlayer";
import Icons from "../../components/Layouts/Icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import demoData from "../../utils/DemoVideosLinks/index";
import { usePurchasedChecker } from "../../Logic/AuthChecker/usePurchaseChecker";

const GetStarted = () => {
  const [play, setPlay] = useState(false);
  let navigate = useNavigate();

  const { registerData } = useSelector((state) => state.auth);

  const { t } = useTranslation(["intro"]);

  const location = useLocation();
  const { planData, isPlanActive } = usePurchasedChecker();



  const getData = (active) => {
    let data = {}
    if (active) {
      data = {
        path: "/dashboard",
        url: demoData[1]?.url
      }
    } else {
      data = {
        path: "/choose-plan",
        url: demoData[0]?.url
      }
    }
    return data
  }




  const handleClickIntro = () => {
    let path = getData(isPlanActive).path
    navigate(path)
  }


  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("visible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [location.pathname]);

  return (
    <>
      <div className={Style.get_started}>
        <div className={Style.anim_elements}>
          <figure
            className={`${Style.anim_elem} ${Style.circle_yellow} anim_element anim`}
          >
            <img
              src={Assets.get_started_intro_circle_yellow}
              alt="circle_yellow"
            />
          </figure>
          <figure
            className={`${Style.anim_elem} ${Style.donut_grey} anim_element anim`}
          >
            <img src={Assets.get_started_intro_donut_grey} alt="donut_grey" />
          </figure>
          <figure
            className={`${Style.anim_elem} ${Style.donut_yellow} anim_element anim`}
          >
            <img
              src={Assets.get_started_intro_donut_yellow}
              alt="donut_yellow"
            />
          </figure>
          <figure
            className={`${Style.anim_elem} ${Style.donut_green} anim_element anim`}
          >
            <img src={Assets.get_started_intro_donut_green} alt="donut_green" />
          </figure>
        </div>
        <div className="container">
          <div className={Style.get_started_wrapper}>
            <div className="anim load-anim">
              <VideoPlayer
                play={play}
                setPlay={setPlay}
                vidUrl={getData(isPlanActive)?.url}
                autoplay={true}
                loop={true}
              />
            </div>
            <h1 className={`${Style.get_started_title} anim load-anim`}>
              Welcome, {registerData?.name}
            </h1>
            <p className={`${Style.get_started_description} anim load-anim`}>
              {t("intro_desc")}
            </p>
            <div className={Style.get_started_btn_wrap}>
              <button className="btn btn-primary anim load-anim" onClick={() => handleClickIntro()}>
                {t("intro_btn")}

              </button >
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetStarted;
