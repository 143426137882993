import { useDispatch, useSelector } from "react-redux"
import { clearSelectionState, deSelectAllVideos, getVideoLinks, openPreSelect, openVideoCreationModal, openVideoDeleteModal, selectAllVideos, openVideoDeleteItemsModal } from "../../../store/slices/Videos/VideoLinkSlice"
import { useState } from "react"


export const useHeaderFilter = () => {
    const dispatch = useDispatch()
    let selectActive = false
    const [dropDownSelect, setDropDownSelect] = useState("Latest");
    const { resultData, videoActions } = useSelector((state) => state?.videos);

    const handleOpenVideoModal = () => {
        dispatch(openVideoCreationModal({
            isOpen: true,
            mode: "all",
            currentData: {}
        }))
    }

    const handleSort = async (item) => {
        setDropDownSelect(item.label)
        let obj = {
            page: "1",
            slug: item.slug,
        }

        const resultAction = await dispatch(getVideoLinks(obj));
        if (getVideoLinks.fulfilled.match(resultAction)) {

        }

    }


    const handlePreSelect = () => {
        dispatch(openPreSelect(true))
    }

    const handleClearState = () => {
        dispatch(clearSelectionState(false))
    }


    const handleSelectAll = () => {
        if (resultData?.data?.length == videoActions?.selected_Ids?.length) {
            dispatch(deSelectAllVideos())
        } else {
            let videoIds = resultData?.data?.map((obj) => obj.id);
            dispatch(selectAllVideos(videoIds))
        }

    }

    const handleDeleteItems = () => {
        dispatch(openVideoDeleteItemsModal({
            isOpen: true,
        }))
    }



    return {
        selectActive,
        dropDownSelect,
        videoActions,
        handleSort,
        handleClearState,
        handlePreSelect,
        handleSelectAll,
        handleDeleteItems,
        handleOpenVideoModal,

    }
}