import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react"
import { addDescription } from "../../../../../store/slices/tagSlice";
import { useDispatch, useSelector } from "react-redux";



export const useDescription = ({ data, albumIndex }) => {
    const ref = useRef(null);
    const dispatch = useDispatch()
    const [isEdit, setIsEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const [text, setText] = useState(data?.description ? data?.description : "")
    const { descriptionStatus } = useSelector((state) => state.tags)

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target) && isEdit && text?.length > 0) {
            handleSaveDescription(text)
        } else if (isEdit && !isEditing) {
            setIsEdit(false);
        }
    }


    const handleSaveDescription = async (text) => {
        const resultAction = await dispatch(
            addDescription({ image_id: data?.id, description: text, album_id: albumIndex })
        );
        if (addDescription.fulfilled.match(resultAction)) {
            setIsEdit(false);
            setIsEditing(false)
        }

    }


    const handleEdit = () => {
        setIsEdit(true)
        setIsEditing(true)
    }


    const handleBlur = (e) => {
        setIsEdit(false)
        setIsEditing(false)
    }

    const handleChangeText = (e) => {
        setText(e.target.value)
        setIsEditing(true)
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSaveDescription(text)
        }
    };

    return {
        ref,
        isEdit,
        text,
        descriptionStatus,
        setIsEdit,
        handleEdit,
        handleBlur,
        handleChangeText,
        handleKeyDown
    }
}