import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  currentPage: "gallery",
  archive: {},
  trash: {},
  isFetchingTrash: "idle",
  gridView: false,
  currentView: "all",
  frameListStatus: "idle",
  albumListStatus: "idle",
  isAlbumCreating: "idle",
  isAddtoToAlbum: "idle",
  allImageStatus: "idle",
  isAlbumEditing: "idle",
  isSlideShowSaving: "idle",
  isAlbumImageDeleting: "idle",
  isFetchingTrash: "idle",
  isSlideShowEditing: false,
  isOpenSlideShowModal: false,
  currentAlbumToImageUploaded: "",
  selectedAlbum: "",
  currentTab: "All",
  sections: {
    slideshow: {
      selectedImages: [],
    },
  },
  activeUploadIndex: 0,
  activeUploadPercent: 0,
  faileditems: [],
  slideShows: [],
  isSingleImageUpload: false,
  thumbs: [],
  musicList: [],
  staredImages: {},
  frames: [],
  isSlideshowFetching: "idle",
  quickSlideShowActions: {
    isOpen: false,
    mode: "all",
  },
  duplicatedImageIndexs: [],
  openAlbumCreateModal: false,
  isCreateAlbumModal: false,
};

export const getGalleryImages = createAsyncThunk(
  "gallery/getGalleryImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `${params.url}?page=${params.page}&&sort=${params.slug}&per_page=40`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateImageFunctions = createAsyncThunk(
  "gallery/updateImageFunctions",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `${params.url}?page=${params.page}&&sort=${params.slug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const customFetchImages = createAsyncThunk(
  "gallery/customFetchImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `${params.url}?page=${params.page}&&sort=${params.slug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchArchivedImages = createAsyncThunk(
  "gallery/fetchArchivedImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `${params.url}?page=${params.page}&&sort=desc`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const filterImages = createAsyncThunk(
  "gallery/filterImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `${params.url}?page=${params.page}&&sort=${params.slug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFavorites = createAsyncThunk(
  "gallery/getFavorites",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`list-favourite`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addToFavorites = createAsyncThunk(
  "gallery/addToFavorites",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`add-favourite`, { image_id: params.id });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeFromFavorites = createAsyncThunk(
  "gallery/removeFromFavorites",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`remove-favourite`, {
        image_id: params.id,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reArrange = createAsyncThunk(
  "gallery/reArrange",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`image-rearrange`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteImage = createAsyncThunk(
  "gallery/deleteImage",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`delete-images`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addtoArchive = createAsyncThunk(
  "gallery/addtoArchive",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`add-archive`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeArchive = createAsyncThunk(
  "gallery/removeArchive",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`remove-archive`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const frameList = createAsyncThunk(
  "gallery/frameList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`frames-list`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const albumList = createAsyncThunk(
  "gallery/albumList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`album-list?page=${params.page}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createAlbum = createAsyncThunk(
  "gallery/createAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`create-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeAlbum = createAsyncThunk(
  "gallery/removeAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`delete-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editAlbum = createAsyncThunk(
  "gallery/editAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`update-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAlbumItem = createAsyncThunk(
  "gallery/getAlbumItem",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `album-view/${params.id}?page=${params.page}&&sort=${params.slug}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const customFetchAlbumImages = createAsyncThunk(
  "gallery/customFetchAlbumImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `album-view/${params.id}?page=${params.page}&&sort=${params.slug}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAlbumFacesItem = createAsyncThunk(
  "gallery/getAlbumFacesItem",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`album-view/${params}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAlbumImages = createAsyncThunk(
  "gallery/deleteAlbumImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`remove-images`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//re arrange album...
//re arrange album image
export const reArrangeAlbum = createAsyncThunk(
  "gallery/reArrangeAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`album-image-rearrange`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//re-arrange album items
export const reArrangeAlbumApi = createAsyncThunk(
  "gallery/reArrangeAlbumApi",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`album-rearrange`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const albumFilterItem = createAsyncThunk(
  "gallery/albumFilterItem",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `${params.url}/${params?.id}?page=${params.page}&&sort=${params.slug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMusicList = createAsyncThunk(
  "gallery/getMusicList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`music-list`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************************** SLIDESHOW  **********************************************/

export const getSlideShowList = createAsyncThunk(
  "gallery/getSlideShowList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/list-slideshow?page=${params.page}&&sort=${params.slug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSlideShow = createAsyncThunk(
  "gallery/getSlideShow",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/view-slideshow/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveSlideShow = createAsyncThunk(
  "gallery/saveSlideShow",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`create-slideshow`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSlideShowItems = createAsyncThunk(
  "gallery/deleteSlideShowItems",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`delete-slideshow`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// tags

export const addImageTags = createAsyncThunk(
  "gallery/addImageTags",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`add-tag`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeImageTags = createAsyncThunk(
  "gallery/removeImageTags",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`remove-tag`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addToStared = createAsyncThunk(
  "gallery/addToStared",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`add-to-stared`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStared = createAsyncThunk(
  "gallery/getStared",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`stared`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reArrangeStared = createAsyncThunk(
  "gallery/reArrangeStared",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`rearrange-stared`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTrash = createAsyncThunk(
  "gallery/getTrash",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`trashed-images`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const restoreTrashedImages = createAsyncThunk(
  "gallery/restoreTrashedImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`restore-image`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//album lock

export const handleLockAlbumApi = createAsyncThunk(
  "gallery/handleLockAlbumApi",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`lock-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    clearGalleryState: (state, action) => {
      state.sections = {};
      state.staredImages = [];
    },

    currentDashboardPage: (state, action) => {
      state.currentPage = action?.payload;
    },
    changeCurrentTabView: (state, action) => {
      state.currentView = action?.payload;
    },
    currentTabSelection: (state, action) => {
      state.currentTab = action?.payload;
    },
    reArrangeImage: (state, action) => {
      let draggedIndex = state.sections[state.currentTab].data.findIndex(
        (item) => item.id == action.payload.draggedItem.id
      );
      let changedIndex = state.sections[state.currentTab].data.findIndex(
        (item) => item.id == action.payload.changedItem.id
      );

      state.sections[state.currentTab].data[draggedIndex].position =
        action.payload.changedItem.position;
      state.sections[state.currentTab].data[changedIndex].position =
        action.payload.draggedItem?.position;

      let data1 = state.sections[state.currentTab].data[changedIndex];
      let data2 = state.sections[state.currentTab].data[draggedIndex];

      state.sections[state.currentTab].data[draggedIndex] = data1;
      state.sections[state.currentTab].data[changedIndex] = data2;
    },

    addImages: (state, action) => {
      let newData = current(state.sections[state.currentTab]);
      if (newData?.selectedImages?.includes(action.payload)) {
        //unselect the images
        let filteredIndexs = newData?.selectedImages?.filter((item, index) => {
          return item !== action.payload;
        });
        state.sections[state.currentTab]["selectedImages"] = filteredIndexs;
      } else {
        //select the images
        typeof state.sections[state.currentTab].selectedImages !== "object"
          ? (state.sections[state.currentTab]["selectedImages"] = [
              ...(state.sections[state.currentTab].selectedImages = []),
              action.payload,
            ])
          : (state.sections[state.currentTab]["selectedImages"] = [
              ...(state.sections[state.currentTab].selectedImages = [
                ...state.sections[state.currentTab].selectedImages,
              ]),
              action.payload,
            ]);
      }
    },

    addFavImages: (state, action) => {
      let newData = current(state?.sections?.favorites);
      if (newData?.selectedImages?.includes(action.payload)) {
        //unselect the images
        let filteredIndexs = newData?.selectedImages?.filter((item, index) => {
          return item !== action.payload;
        });
        state.sections.favorites["selectedImages"] = filteredIndexs;
      } else {
        //select the images
        typeof state.sections.favorites.selectedImages !== "object"
          ? (state.sections.favorites["selectedImages"] = [
              ...(state.sections.favorites.selectedImages = []),
              action.payload,
            ])
          : (state.sections.favorites["selectedImages"] = [
              ...(state.sections.favorites.selectedImages = [
                ...state.sections.favorites.selectedImages,
              ]),
              action.payload,
            ]);
      }
    },

    selectAllImages: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = action.payload;
    },

    unSelectAllImages: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [
        ...new Array(0).keys(),
      ];
    },

    removeAllActions: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [];
      state.showSelect = false;
    },

    currentFilterItem: (state, action) => {
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        filterOption: action.payload,
      };
    },

    openPreSelect: (state, action) => {
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        preselect: true,
      };
    },

    openRearrange: (state, action) => {
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        rearrange: true,
      };
    },
    reArrangedItems: (state, action) => {},

    closePreSelect: (state, action) => {
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        preselect: false,
      };
    },

    closeFilterActions: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [];
      state.sections[state.currentTab]["selectedAlbumListItems"] = [];
      state.sections[state.currentTab]["currentActions"] = {};
    },

    favoriteActions: (state, action) => {
      const favIndex = current(state.sections[state.currentTab].data).findIndex(
        (item) => item.id === action.payload
      );
      let allIndex = "";
      if (state?.sections["all"]?.data !== undefined) {
        allIndex = current(state.sections["all"].data).findIndex(
          (item) => item.id === action.payload
        );
      }

      state.sections[state.currentTab].data[favIndex] = {
        ...state.sections[state.currentTab].data[favIndex],
        favourite:
          state.sections[state.currentTab].data[favIndex].favourite == 1
            ? 0
            : 1,
      };

      //add and remove favorites state from all tab
      let keyArr = Object.keys(state.sections);
      if (state.sections[state.currentTab].data[favIndex].favourite == 1) {
        if (keyArr.includes("favorites")) {
          state.sections["favorites"]["data"] = [
            ...state.sections.favorites.data,
            state.sections[state.currentTab].data[favIndex],
          ];
        } else {
          state.sections["favorites"] = {
            data: [state.sections[state.currentTab].data[favIndex]],
          };
        }
      } else {
        let alreadyFavoritesIndex = current(
          state.sections["favorites"]["data"]
        ).findIndex((item) => item.id === action.payload);
        let favArry = Object.keys(state.sections["favorites"]["data"]);
        if (favArry?.includes(alreadyFavoritesIndex.toString())) {
          state.sections["favorites"]["data"] = state.sections["favorites"][
            "data"
          ].filter((item) => item.id !== action.payload);
        }
      }

      //remove favorite item from favorite tab
      if (state.currentTab == "favorites") {
        state.sections[state.currentTab].data = state.sections[
          state.currentTab
        ].data.filter((item) => item.id !== action.payload);

        if (state?.sections["all"]?.data !== undefined) {
          state.sections["all"].data[allIndex] = {
            ...state.sections["all"].data[allIndex],
            favourite: 0,
          };
        }
      }
    },

    //image uploader section
    addToThumbs: (state, action) => {
      state.thumbs = [...state.thumbs, action.payload];
    },

    clearThumbs: (state, action) => {
      state.thumbs = [];
      // state.activeUploadIndex = 0;
      state.activeUploadPercent = 0;
      state.duplicatedImageIndexs = [];
    },

    updateActiveUploadPercentage: (state, action) => {
      state.activeUploadPercent = action.payload;
    },

    updateUploadedImage: (state, action) => {
      state.sections["all"].data = [
        action.payload.data,
        ...state.sections["all"].data,
      ];
    },

    updateActiveIndex: (state, action) => {
      state.activeUploadIndex = action.payload;
    },

    getFailedItems: (state, action) => {
      state.faileditems = [...state.faileditems, action.payload];
    },

    singleImageUpload: (state, action) => {
      state.isSingleImageUpload = action.payload;
    },

    // archive

    //instant delete from state
    deleteImageAction: (state, action) => {
      state.sections[state.currentTab].data = state.sections[
        state.currentTab
      ].data.filter((item) => item.id !== action.payload);
    },

    deleteMultipleImages: (state, action) => {
      let selectedItem = current(
        state.sections[state.currentTab].selectedImages
      );

      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        data: state.sections[state.currentTab].data.filter(
          (item, index) => !selectedItem.includes(item.id)
        ),
        selectedImages: [],
        currentActions: {},
      };
    },

    //remove single image from archive list
    removeArchiveFunction: (state, action) => {
      state.archive.data = state.archive.data.filter(
        (item) => item.id !== action.payload
      );
    },

    removeTrashFunction: (state, action) => {
      state.trash.data = state.trash.data.filter(
        (item) => item.id !== action.payload
      );
    },

    archiveActions: (state, action) => {
      let keyArr = Object.keys(state);
      if (state.currentTab == "all" && state.currentPage == "all") {
        let selectedItem = current(
          state.sections[state.currentTab].selectedImages
        );
        let archivedArry = current(
          state.sections[state.currentTab].data
        ).filter((item, index) => selectedItem.includes(item.id));
        //add to archive...
        if (keyArr.includes("archive")) {
          state.archive["data"] = [...state.archive.data, ...archivedArry];
        } else {
          state.archive = { data: [...archivedArry] };
        }
      }
      //remove from archive list
      else {
        let selectedArchiveItem = current(state.archive.selectedImages);

        state.archive.data = state.archive.data.filter(
          (item, index) => !selectedArchiveItem.includes(item.id)
        );
      }
    },

    handleremoveFailedItem: (state, action) => {
      state.faileditems = state.faileditems.filter(
        (item) => item !== action.payload
      );
      state.sections[state.currentTab].data = state.sections[
        state.currentTab
      ].data.filter((item, index) => index !== action.payload);
    },

    //album actions

    addAlbumImageHandler: (state, action) => {
      let newData = current(state.sections[state.currentTab]);
      if (newData?.selectedAlbumImages?.includes(action.payload)) {
        //unselect the images
        let filteredIndexs = newData?.selectedAlbumImages?.filter(
          (item, index) => {
            return item !== action.payload;
          }
        );
        state.sections[state.currentTab]["selectedAlbumImages"] =
          filteredIndexs;
      } else {
        //select the images
        typeof state.sections[state.currentTab].selectedAlbumImages !== "object"
          ? (state.sections[state.currentTab]["selectedAlbumImages"] = [
              ...(state.sections[state.currentTab].selectedAlbumImages = []),
              action.payload,
            ])
          : (state.sections[state.currentTab]["selectedAlbumImages"] = [
              ...(state.sections[state.currentTab].selectedAlbumImages = [
                ...state.sections[state.currentTab].selectedAlbumImages,
              ]),
              action.payload,
            ]);
      }
    },

    selectAllAlbumImages: (state, action) => {
      state.sections[state.currentTab]["selectedAlbumImages"] = action.payload;
    },

    unSelectAllAlbumImages: (state, action) => {
      state.sections[state.currentTab]["selectedAlbumImages"] = [
        ...new Array(0).keys(),
      ];
    },

    addAlbumListitemsHandler: (state, action) => {
      let newData = current(state.sections[state.currentTab]);
      if (newData?.selectedAlbumListItems?.includes(action.payload)) {
        //unselect the images
        let filteredIndexs = newData?.selectedAlbumListItems?.filter(
          (item, index) => {
            return item !== action.payload;
          }
        );
        state.sections[state.currentTab]["selectedAlbumListItems"] =
          filteredIndexs;
      } else {
        //select the images
        typeof state.sections[state.currentTab].selectedAlbumListItems !==
        "object"
          ? (state.sections[state.currentTab]["selectedAlbumListItems"] = [
              ...(state.sections[state.currentTab].selectedAlbumListItems = []),
              action.payload,
            ])
          : (state.sections[state.currentTab]["selectedAlbumListItems"] = [
              ...(state.sections[state.currentTab].selectedAlbumListItems = [
                ...state.sections[state.currentTab].selectedAlbumListItems,
              ]),
              action.payload,
            ]);
      }
    },

    deleteMultipleAlbumsListItemsfromState: (state, action) => {
      let selectedItem = current(
        state.sections[state.currentTab].selectedAlbumListItems
      );

      state.sections.album = {
        ...state.sections[state.currentTab],
        data: state.sections[state.currentTab].data.filter(
          (item, index) => !selectedItem.includes(item.id)
        ),
        selectedAlbumListItems: [],
        currentActions: {
          ...state.sections.album.currentActions,
          openListDeleteModal: false,
        },
      };
    },

    currentSelectedAlbum: (state, action) => {
      state.selectedAlbum = action.payload;
    },

    removeAlbumFromState: (state, action) => {
      state.sections[state.currentTab].data = state.sections[
        state.currentTab
      ].data.filter((item, index) => item.id !== action.payload);
    },

    removeAlbumImagesFromState: (state, action) => {
      let selectedItem = current(
        state.sections[state.currentTab].selectedAlbumImages
      );
      let AlbumImages = current(
        state.sections.album.list[state.selectedAlbum].images
      );

      let filterAlbumImages = AlbumImages?.filter(
        (item, index) => !selectedItem.includes(item.id)
      );

      state.sections.album.list[state.selectedAlbum] = {
        ...state.sections.album.list[state.selectedAlbum],
        images: state.sections.album.list[state.selectedAlbum].images.filter(
          (item, index) => !selectedItem.includes(item.id)
        ),
        selectedImages: [],
        total: filterAlbumImages?.length,
      };
    },

    clearAlbumState: (state, action) => {
      state.albumListStatus = "idle";
      state.isAlbumCreating = "idle";
      state.isAddtoToAlbum = "idle";
      state.isAlbumEditing = "idle";
    },

    clearAlbumEditState: (state, action) => {
      state.isAlbumEditing = "idle";
    },

    reArrangeAlbumImage: (state, action) => {
      let draggedIndex = state.sections[state.currentTab].list[
        state.selectedAlbum
      ].images.findIndex((item) => item.id == action.payload.draggedItem.id);

      let changedIndex = state.sections[state.currentTab].list[
        state.selectedAlbum
      ].images.findIndex((item) => item.id == action.payload.changedItem.id);

      state.sections[state.currentTab].list[state.selectedAlbum].images[
        draggedIndex
      ].position = action.payload.changedItem.position;
      state.sections[state.currentTab].list[state.selectedAlbum].images[
        changedIndex
      ].position = action.payload.draggedItem?.position;

      let data1 =
        state.sections[state.currentTab].list[state.selectedAlbum].images[
          changedIndex
        ];
      let data2 =
        state.sections[state.currentTab].list[state.selectedAlbum].images[
          draggedIndex
        ];

      state.sections[state.currentTab].list[state.selectedAlbum].images[
        draggedIndex
      ] = data1;
      state.sections[state.currentTab].list[state.selectedAlbum].images[
        changedIndex
      ] = data2;
    },

    reArrangeAlbumItemsState: (state, action) => {
      let draggedIndex = state.sections[state.currentTab].data.findIndex(
        (item) => item.id == action.payload.draggedItem.id
      );

      let changedIndex = state.sections[state.currentTab].data.findIndex(
        (item) => item.id == action.payload.changedItem.id
      );

      state.sections[state.currentTab].data[draggedIndex].position =
        action.payload.changedItem.position;

      state.sections[state.currentTab].data[changedIndex].position =
        action.payload.draggedItem?.position;

      let data1 = state.sections[state.currentTab].data[changedIndex];
      let data2 = state.sections[state.currentTab].data[draggedIndex];

      state.sections[state.currentTab].data[draggedIndex] = data1;
      state.sections[state.currentTab].data[changedIndex] = data2;
    },

    reArrangeStaredImage: (state, action) => {
      let draggedIndex = state.staredImages.data.findIndex(
        (item) => item.id == action.payload.draggedItem.id
      );
      let changedIndex = state.staredImages.data.findIndex(
        (item) => item.id == action.payload.changedItem.id
      );

      state.staredImages.data[draggedIndex].position =
        action.payload.changedItem.position;
      state.staredImages.data[changedIndex].position =
        action.payload.draggedItem?.position;

      let data1 = state.staredImages.data[changedIndex];
      let data2 = state.staredImages.data[draggedIndex];

      state.staredImages.data[draggedIndex] = data1;
      state.staredImages.data[changedIndex] = data2;
    },

    /*********************************** SLIDE SHOW *******************************/

    editCurrentSlideShow: (state, action) => {
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        name: action.payload,
      };
    },

    deleteImageFromSlideshow: (state, action) => {
      state.sections.slideshow.currentSlideShow[
        state.sections.slideshow.currentSlideShowID
      ].images = state.sections.slideshow.currentSlideShow[
        state.sections.slideshow.currentSlideShowID
      ].images.filter((item, index) => index != action.payload.slideIndex);
      // state.sections.slideshow = {
      // };
    },

    openSlideShowFullview: (state, action) => {
      state.quickSlideShowActions = {
        [action.payload.type]: {
          showSlideShowContainer: action.payload.isOpen,
          mode: action.payload.mode,
        },
      };
    },

    handleSlideShowModal: (state, action) => {
      state.isOpenSlideShowModal = action.payload;
    },

    /*********************************** Archive *******************************/

    addArchiveToList: (state, action) => {
      let newData = current(state[state.currentPage]);
      if (newData?.selectedImages?.includes(action.payload)) {
        //unselect the images
        let filteredIndexs = newData?.selectedImages?.filter((item, index) => {
          return item !== action.payload;
        });
        state[state.currentPage]["selectedImages"] = filteredIndexs;
      } else {
        //select the images
        typeof state[state.currentPage].selectedImages !== "object"
          ? (state[state.currentPage]["selectedImages"] = [
              ...(state[state.currentPage].selectedImages = []),
              action.payload,
            ])
          : (state[state.currentPage]["selectedImages"] = [
              ...(state[state.currentPage].selectedImages = [
                ...state[state.currentPage].selectedImages,
              ]),
              action.payload,
            ]);
      }
    },

    selectAllArchive: (state, action) => {
      state.archive["selectedImages"] = action.payload;
    },

    unSelectAllArchives: (state, action) => {
      state.archive["selectedImages"] = [...new Array(0).keys()];
    },

    selectAllTrash: (state, action) => {
      state.trash["selectedImages"] = action.payload;
    },

    unSelectAllTrash: (state, action) => {
      state.trash["selectedImages"] = [...new Array(0).keys()];
    },

    removeAllActions: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [];
      state.showSelect = false;
    },

    changeSlideShowEditState: (state, action) => {
      state.isSlideShowEditing = action.payload;
    },

    addEditedImageToState: (state, action) => {
      state.sections["all"].data = [
        ...state.sections["all"].data,
        action?.payload,
      ];
    },

    duplicatedImages: (state, action) => {
      state.duplicatedImageIndexs = [
        ...state.duplicatedImageIndexs,
        action.payload,
      ];
    },

    openAlbumCreateModalAction: (state, action) => {
      state.openAlbumCreateModal = action.payload;
    },
    isAlbumCreateAction: (state, action) => {
      state.isCreateAlbumModal = action.payload;
    },

    currentAlbumToImageUploadedAction: (state, action) => {
      state.currentAlbumToImageUploaded = action.payload;
    },

    updateAlbumLockStatus: (state, action) => {
      const lockIndex = current(state.sections["album"].data).findIndex(
        (item) => item.id === action.payload?.id
      );
      state.sections["album"].data[lockIndex].locked = state.sections["album"]
        .data[lockIndex].locked
        ? false
        : true;
    },

    currentAlbumImageFilterItem: (state, action) => {

      state.sections[state.currentTab].list[state.selectedAlbum][
        "currentActions"
      ] = {
        ...state.sections[state.currentTab].list[state.selectedAlbum][
          "currentActions"
        ],
        filterOption: action.payload,
      };
    },

    callFunction: (state, action) => {
      action.payload(state, action);
    },
  },
  extraReducers: {
    [frameList.pending]: (state, action) => {
      state.frameListStatus = "loading";
      state.message = null;
    },
    [frameList.fulfilled]: (state, action) => {
      state.frameListStatus = "succeeded";
      state.frames = action.payload.data.result;
    },
    [frameList.rejected]: (state, action) => {
      state.frameListStatus = "failed";
    },

    [getGalleryImages.pending]: (state, action) => {
      state.allImageStatus = "loading";
      state.message = null;
    },
    [getGalleryImages.fulfilled]: (state, action) => {
      state.allImageStatus = "succeeded";
      if (state.currentTab == "archive") {
        state.sections["all"] = {
          ...state.sections["all"],
          ...action?.payload?.data?.result,
        };
      } else if (state.currentTab == "slideshow") {
        state.sections["all"] = {
          ...state.sections["all"],
          ...action?.payload?.data?.result,
        };
      } else if (state.staredImages?.currentActions?.openFavoriteSection) {
        state.sections["favorites"] = {
          ...state.sections["favorites"],
          ...action?.payload?.data?.result,
        };
      } else if (state.currentTab == "album") {
        state.sections["all"] = {
          ...state.sections["all"],
          ...action?.payload?.data?.result,
        };
      } else {
        state.sections[state.currentTab] = {
          ...state.sections[state.currentTab],
          ...action?.payload?.data?.result,
        };
      }
    },
    [getGalleryImages.rejected]: (state, action) => {
      state.allImageStatus = "failed";
    },

    [updateImageFunctions.pending]: (state, action) => {
      state.allImageStatus = "loading";
      state.message = null;
    },
    [updateImageFunctions.fulfilled]: (state, action) => {
      state.allImageStatus = "succeeded";
      state.sections["favorites"] = action?.payload?.data?.result;
    },
    [updateImageFunctions.rejected]: (state, action) => {
      state.allImageStatus = "failed";
    },

    [customFetchImages.pending]: (state, action) => {
      state.allImageStatus = "loading";
      state.message = null;
    },
    [customFetchImages.fulfilled]: (state, action) => {
      let imgIds = action?.payload?.data?.result.data.map(
        (item, index) => item.id
      );
      state.allImageStatus = "succeeded";

      if (
        state.sections[state.currentTab]?.currentActions?.preselect &&
        state.sections[state.currentTab]?.selectedImages?.length > 0 &&
        !state.sections[state.currentTab]?.currentActions?.imageGalleryOpen
      ) {
        state.sections[state.currentTab].data = [
          ...state.sections[state.currentTab].data,
          ...action?.payload?.data?.result.data,
        ];
        state.sections[state.currentTab] = {
          ...state.sections[state.currentTab],
          current_page: action?.payload?.data?.result?.current_page,
          selectedImages: [
            ...state.sections[state.currentTab].selectedImages,
            // ...imgIds,
          ],
        };
      } else if (
        state.sections[state.currentTab]?.currentActions
          ?.openAlbumItemPreSelection &&
        state.sections[state.currentTab]?.selectedAlbumListItems?.length > 0
      ) {
        state.sections[state.currentTab].data = [
          ...state.sections[state.currentTab].data,
          ...action?.payload?.data?.result.data,
        ];
        state.sections[state.currentTab] = {
          ...state.sections[state.currentTab],
          current_page: action?.payload?.data?.result?.current_page,
          selectedAlbumListItems: [
            ...state.sections[state.currentTab].selectedAlbumListItems,
            ...imgIds,
          ],
        };
      } else if (
        state.sections[state.currentTab]?.currentActions?.preselect &&
        state.sections[state.currentTab]?.currentActions?.imageGalleryOpen
      ) {
        state.sections["all"].data = [
          ...state.sections["all"].data,
          ...action?.payload?.data?.result.data,
        ];
        state.sections["all"] = {
          ...state.sections["all"],
          current_page: action?.payload?.data?.result?.current_page,
          selectedImages: [...state.sections["all"].selectedImages, ...imgIds],
        };
      } else {
        state.sections[state.currentTab].data = [
          ...state.sections[state.currentTab].data,
          ...action?.payload?.data?.result.data,
        ];
        state.sections[state.currentTab] = {
          ...state.sections[state.currentTab],
          current_page: action?.payload?.data?.result?.current_page,
        };
      }
    },
    [customFetchImages.rejected]: (state, action) => {
      state.allImageStatus = "failed";
    },

    [fetchArchivedImages.pending]: (state, action) => {
      state.archiveListStatus = "loading";
      state.message = null;
    },
    [fetchArchivedImages.fulfilled]: (state, action) => {
      state.archiveListStatus = "succeeded";
      state.archive = {
        ...state.archive,
        ...action?.payload?.data?.result,
      };
      state.archive = {
        ...state.archive,
        current_page: action?.payload?.data?.result?.current_page,
      };
    },
    [fetchArchivedImages.rejected]: (state, action) => {
      state.archiveListStatus = "failed";
    },

    [filterImages.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [filterImages.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.sections[state.currentTab].data = [];
      state.sections[state.currentTab].data = [
        ...action?.payload?.data?.result.data,
      ];

      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        current_page: action?.payload?.data?.result?.current_page,
      };
    },
    [filterImages.rejected]: (state, action) => {
      state.status = "failed";
    },

    [addtoArchive.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [addtoArchive.fulfilled]: (state, action) => {
      state.status = "succeeded";
      let imgItems = current(state.sections[state.currentTab].selectedImages);
      state.sections[state.currentTab].data = state.sections[
        state.currentTab
      ].data.filter((item, index) => !imgItems.includes(item.id));
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        preselect: false,
      };
      state.sections[state.currentTab]["selectedImages"] = [];
    },
    [addtoArchive.rejected]: (state, action) => {
      state.status = "failed";
    },

    [removeArchive.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [removeArchive.fulfilled]: (state, action) => {
      state.status = "succeeded";
      if (state.archive.selectedImages) {
        let selectedItem = current(state.archive.selectedImages);
        state.archive = {
          ...state.archive,
          data: state.archive.data.filter(
            (item, index) => !selectedItem.includes(item.id)
          ),
          selectedImages: [],
          currentActions: {},
        };
      }
    },
    [removeArchive.rejected]: (state, action) => {
      state.status = "failed";
    },

    [reArrange.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [reArrange.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // state.sections[state.currentTab]["currentActions"] = {
      //   ...state.sections[state.currentTab]["currentActions"],
      //   rearrange: false,
      // }
    },
    [reArrange.rejected]: (state, action) => {
      state.status = "failed";
    },

    //album images

    [reArrangeAlbum.pending]: (state, action) => {
      state.albumRearrangeStatus = "loading";
      state.message = null;
    },
    [reArrangeAlbum.fulfilled]: (state, action) => {
      state.albumRearrangeStatus = "succeeded";
    },
    [reArrangeAlbum.rejected]: (state, action) => {
      state.albumRearrangeStatus = "failed";
    },

    //album items
    [reArrangeAlbumApi.pending]: (state, action) => {
      state.albumItemRearrangeStatus = "loading";
      state.message = null;
    },
    [reArrangeAlbumApi.fulfilled]: (state, action) => {
      state.albumItemRearrangeStatus = "succeeded";
    },
    [reArrangeAlbumApi.rejected]: (state, action) => {
      state.albumItemRearrangeStatus = "failed";
    },

    [albumList.pending]: (state, action) => {
      state.albumListStatus = "loading";
      state.message = null;
    },
    [albumList.fulfilled]: (state, action) => {
      state.albumListStatus = "succeeded";
      state.sections["album"] = action.payload.data.result;
    },
    [albumList.rejected]: (state, action) => {
      state.albumListStatus = "failed";
    },

    [createAlbum.pending]: (state, action) => {
      state.isAlbumCreating = "loading";
      state.message = null;
    },
    [createAlbum.fulfilled]: (state, action) => {
      state.isAlbumCreating = "succeeded";
      let obj = {
        ...action.payload.data.result,
        frameName: "None",
      };

      state.sections["album"].data = [obj, ...state.sections["album"].data];
      state.sections["album"].total = state.sections["album"].total + 1;
      state.sections[state.currentTab]["currentActions"] = {
        ...state.sections[state.currentTab]["currentActions"],
        preselect: false,
      };
      state.sections[state.currentTab]["selectedImages"] = [];
    },
    [createAlbum.rejected]: (state, action) => {
      state.isAlbumCreating = "failed";
    },

    [removeAlbum.pending]: (state, action) => {
      state.isAlbumRemoved = "loading";
      state.message = null;
    },
    [removeAlbum.fulfilled]: (state, action) => {
      state.isAlbumRemoved = "succeeded";
    },
    [removeAlbum.rejected]: (state, action) => {
      state.isAlbumRemoved = "failed";
    },

    [editAlbum.pending]: (state, action) => {
      state.isAlbumEditing = "loading";
      state.message = null;
    },
    [editAlbum.fulfilled]: (state, action) => {
      state.isAlbumEditing = "succeeded";

      if (state.sections["album"].data?.length > 0) {
        let albumIndex = state.sections["album"].data.findIndex(
          (item) => item.id == action.payload.data.result.id
        );
        state.sections["album"].data[albumIndex] = action.payload.data.result;
        state.sections[state.currentTab]["currentActions"] = {
          ...state.sections[state.currentTab]["currentActions"],
          preselect: false,
        };
        state.sections[state.currentTab]["selectedImages"] = [];
      }
    },
    [editAlbum.rejected]: (state, action) => {
      state.isAlbumEditing = "failed";
    },

    [getAlbumItem.pending]: (state, action) => {
      state.isAlbumList = "loading";
      state.message = null;
    },

    [getAlbumItem.fulfilled]: (state, action) => {
      state.isAlbumList = "succeeded";
      let keyArr = Object.keys(state.sections);
      if (keyArr.includes("album")) {
        if (typeof state.sections[state.currentTab]["list"] !== "object") {
          state.sections.album = {
            ...state.sections?.album,
            list: {
              [state.selectedAlbum]: { ...action.payload.data.result },
            },
          };
        } else {
          state.sections.album = {
            ...state.sections?.album,
            list: {
              ...state.sections.album.list,
              [state.selectedAlbum]: { ...action.payload.data.result },
            },
          };
        }
      } else {
        state.sections["album"] = {
          list: {
            [state.selectedAlbum]: { ...action.payload.data.result },
          },
        };
      }
    },

    [getAlbumItem.rejected]: (state, action) => {
      state.isAlbumList = "failed";
    },

    [customFetchAlbumImages.pending]: (state, action) => {
      state.isAlbumList = "loading";
      state.message = null;
    },

    [customFetchAlbumImages.fulfilled]: (state, action) => {
      state.isAlbumList = "succeeded";
      state.sections[state.currentTab].list[state.selectedAlbum].images = [
        ...state.sections[state.currentTab].list[state.selectedAlbum].images,
        ...action.payload.data.result.images,
      ];

      state.sections[state.currentTab].list[state.selectedAlbum] = {
        ...state.sections[state.currentTab].list[state.selectedAlbum],
        current_page: action?.payload?.data?.result?.current_page,
      };
    },

    [customFetchAlbumImages.rejected]: (state, action) => {
      state.isAlbumList = "failed";
    },

    [albumFilterItem.pending]: (state, action) => {
      state.isAlbumItemList = "loading";
      state.message = null;
    },

    [albumFilterItem.fulfilled]: (state, action) => {
      state.isAlbumItemList = "succeeded";
      state.sections[state.currentTab].list[state.selectedAlbum].images =
        action.payload.data.result.images;

      state.sections[state.currentTab].list[state.selectedAlbum] = {
        ...state.sections[state.currentTab].list[state.selectedAlbum],
        current_page: action?.payload?.data?.result?.current_page,
      };
    },
    [albumFilterItem.rejected]: (state, action) => {
      state.isAlbumItemList = "failed";
    },

    [deleteAlbumImages.pending]: (state, action) => {
      state.isAlbumImageDeleting = "loading";
      state.message = null;
    },

    [deleteAlbumImages.fulfilled]: (state, action) => {
      state.isAlbumImageDeleting = "succeeded";
    },
    [deleteAlbumImages.rejected]: (state, action) => {
      state.isAlbumImageDeleting = "failed";
    },

    /*********************************** SLIDE SHOW *******************************/

    [getSlideShowList.pending]: (state) => {
      state.isSlideshowFetching = "loading";
    },
    [getSlideShowList.fulfilled]: (state, action) => {
      state.isSlideshowFetching = "succeeded";
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        ...action?.payload?.data?.result,
      };
    },

    [getSlideShowList.rejected]: (state, action) => {
      state.isSlideshowFetching = "failed";
    },

    [getSlideShow.pending]: (state) => {
      state.status = "loading";
    },
    [getSlideShow.fulfilled]: (state, action) => {
      state.status = "success";

      state.sections.slideshow = {
        ...state.sections.slideshow,
        currentSlideShow: {
          ...state.sections.slideshow.currentSlideShow,
          [state.sections.slideshow.currentSlideShowID]:
            action.payload?.data?.result,
        },
      };
    },
    [getSlideShow.rejected]: (state, action) => {
      state.status = "failed";
    },

    [saveSlideShow.pending]: (state) => {
      state.isSlideShowSaving = "loading";
    },
    [saveSlideShow.fulfilled]: (state, action) => {
      state.isSlideShowSaving = "success";
      let slideIndex = state.sections["slideshow"]?.data?.findIndex(
        (item) => item.id == action.payload.data.result.id
      );
      if (slideIndex == -1) {
        state.sections["slideshow"].data = [
          ...state.sections["slideshow"].data,
          action.payload.data.result,
        ];
        state.sections["slideshow"]["currentActions"] = {};
        state.sections["slideshow"].selectedImages = [];
        state.sections["slideshow"].currentSlideShowID = "";
      } else {
        state.sections["slideshow"].data[slideIndex] =
          action.payload.data.result;

        state.sections["slideshow"].currentSlideShow = {
          ...(state.sections.slideshow.currentSlideShow = {}),
        };
      }
    },
    [saveSlideShow.rejected]: (state, action) => {
      state.isSlideShowSaving = "failed";
    },
    /*********************************** SLIDE SHOW *******************************/

    [getMusicList.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getMusicList.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.musicList = action.payload.data.result;
    },
    [getMusicList.rejected]: (state, action) => {
      state.status = "failed";
    },

    //tags

    [addImageTags.pending]: (state, action) => {
      state.tagStatus = "loading";
      state.message = null;
    },
    [addImageTags.fulfilled]: (state, action) => {
      state.tagStatus = "succeeded";
      state.musicList = action.payload.data.result;
    },
    [addImageTags.rejected]: (state, action) => {
      state.tagStatus = "failed";
    },

    [removeImageTags.pending]: (state, action) => {
      state.tagStatus = "loading";
      state.message = null;
    },
    [removeImageTags.fulfilled]: (state, action) => {
      state.tagStatus = "succeeded";
      // state.musicList = action.payload.data.result;
    },
    [removeImageTags.rejected]: (state, action) => {
      state.tagStatus = "failed";
    },

    [getStared.pending]: (state, action) => {
      state.staredStatus = "loading";
      state.message = null;
    },
    [getStared.fulfilled]: (state, action) => {
      state.staredStatus = "succeeded";
      state.staredImages.data = action.payload.data.result.data;
    },
    [getStared.rejected]: (state, action) => {
      state.staredStatus = "failed";
    },

    [addToStared.pending]: (state, action) => {
      state.addstaredStatus = "loading";
      state.message = null;
    },
    [addToStared.fulfilled]: (state, action) => {
      state.addstaredStatus = "succeeded";
      state.staredImages.data = action.payload.data.result;
    },
    [addToStared.rejected]: (state, action) => {
      state.addstaredStatus = "failed";
    },

    [reArrangeStared.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [reArrangeStared.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // state.sections[state.currentTab]["currentActions"] = {
      //   ...state.sections[state.currentTab]["currentActions"],
      //   rearrange: false,
      // }
    },
    [reArrangeStared.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getTrash.pending]: (state, action) => {
      state.isFetchingTrash = "loading";
      state.message = null;
    },
    [getTrash.fulfilled]: (state, action) => {
      state.isFetchingTrash = "succeeded";
      state.trash = {
        ...state.trash,
        ...action?.payload?.data?.result,
      };

      state.trash = {
        ...state.trash,
        current_page: action?.payload?.data?.result?.current_page,
      };
    },
    [getTrash.rejected]: (state, action) => {
      state.isFetchingTrash = "failed";
    },

    [restoreTrashedImages.pending]: (state, action) => {
      state.isFetchingTrash = "loading";
      state.message = null;
    },
    [restoreTrashedImages.fulfilled]: (state, action) => {
      state.isFetchingTrash = "succeeded";
      if (state?.trash?.selectedImages) {
        let selectedItem = current(state?.trash?.selectedImages);

        state.trash = {
          ...state.trash,
          data: state.trash.data.filter(
            (item, index) => !selectedItem.includes(item.id)
          ),
          selectedImages: [],
          currentActions: {},
        };
      }
    },
    [restoreTrashedImages.rejected]: (state, action) => {
      state.isFetchingTrash = "failed";
    },
    [handleLockAlbumApi.pending]: (state, action) => {
      state.isLocking = "loading";
    },
    [handleLockAlbumApi.fulfilled]: (state, action) => {
      state.isLocking = "succeeded";
    },
    [handleLockAlbumApi.rejected]: (state, action) => {
      state.isLocking = "failed";
    },
  },
});

export const {
  clearGalleryState,
  galleryActions,
  addImages,
  reArrangeImage,
  selectAllImages,
  unSelectAllImages,
  removeAllActions,
  currentFilterItem,
  openPreSelect,
  openRearrange,
  closePreSelect,
  closeFilterActions,
  favoriteActions,
  currentTabSelection,
  reArrangedItems,
  preUploadImage,
  updateActiveUploadPercentage,
  updateActiveIndex,
  updateUploadedImage,
  addtoArchiveFunction,
  deleteImageAction,
  deleteMultipleImages,
  removeArchiveFunction,
  archiveActions,
  getFailedItems,
  handleremoveFailedItem,
  singleImageUpload,
  addToThumbs,
  clearThumbs,
  removeAlbumFromState,
  clearAlbumState,
  clearAlbumEditState,
  currentSelectedAlbum,
  editCurrentSlideShow,
  callFunction,
  deleteImageFromSlideshow,
  addAlbumImageHandler,
  removeAlbumImagesFromState,
  addAlbumListitemsHandler,
  deleteMultipleAlbumsListItemsfromState,
  reArrangeAlbumImage,
  reArrangedAlbumItems,
  openSlideShowFullview,
  handleSlideShowModal,
  changeCurrentTabView,
  currentDashboardPage,
  addArchiveToList,
  selectAllArchive,
  unSelectAllArchives,
  addFavImages,
  reArrangeStaredImage,
  selectAllAlbumImages,
  unSelectAllAlbumImages,
  changeSlideShowEditState,
  addEditedImageToState,
  removeTrashFunction,
  selectAllTrash,
  unSelectAllTrash,
  openAlbumCreateModalAction,
  isAlbumCreateAction,
  currentAlbumToImageUploadedAction,
  reArrangeAlbumItemImages,
  reArrangeAlbumItemsState,
  updateAlbumLockStatus,
  currentAlbumImageFilterItem,
  duplicatedImages,
} = gallerySlice.actions;

export default gallerySlice.reducer;
