import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SelectedImage } from "../../store/slices/GalleryTabSlice";

export const useSlideShowThumb = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [loadSlideShow, setLoadSlideShow] = useState(false);
    const [DeleteModal, setDeleteModal] = useState({ open: false, imageId: null });
    const selections = useSelector((state) => state.galleryTab)



    const handleSelectSlideShow = (imageId, index) => {
        if (selections?.ImageSlideshowTab?.selction == true)
            dispatch(SelectedImage({ tab: 'slideshow', imageId }))
        else {
            navigate(`/dashboard/slideshow/edit/${imageId}`);
        }
    }

    return {
        selections,
        loadSlideShow,
        setLoadSlideShow,
        handleSelectSlideShow
    }
}