import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailUpdateForm } from "../../../store/slices/emailUpdateSlice";


export const useUpdateEmail = () => {
    const dispatch = useDispatch();
    const [otpCounter, setOtpCounter] = useState(59);
    const [otpResponse, setOtpResponse] = useState({
        message: "",
        success: null,
    })

    const { emailUpdateData } = useSelector(
        (state) => state.userEmailUpdate
    );


    const resentEmailOtp = async () => {
        const resultAction = await dispatch(emailUpdateForm(emailUpdateData));
        if (emailUpdateForm.fulfilled.match(resultAction)) {
            setOtpCounter(59);
            setOtpResponse({
                message: "OTP Resend Success",
                success: true
            });
            setTimeout(() => {
                setOtpResponse({});
            }, 1500);
        } else {
            if (resultAction.payload) {
                setOtpCounter(59);
                setOtpResponse({
                    message: "OTP Resend Failed",
                    success: false
                });

                setTimeout(() => {
                    setOtpResponse({});
                }, 1500);
            }
        }
    }


    return {
        otpCounter,
        otpResponse,
        resentEmailOtp,
        setOtpCounter
    }

}