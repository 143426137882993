import { useEffect, useState } from "react";
import { useDispatch, useSelector, } from "react-redux";
import { editAlbum } from "../../../store/slices/albumSlice";
import { frameList } from "../../../store/slices/frameSlice";

export const useAlbumEditModal = ({ albumEditModal, setAlbumEditModal }) => { //FIXME album close after success
  const dispatch = useDispatch();
  const frames = useSelector((state) => state?.frames);
  const album = useSelector((state) => state.album)
  const [selectedFrame, setSelectedFrame] = useState("");
  const [frameData, setFrameData] = useState({ title: "", description: '' });


  useEffect(() => {
    if (frames?.status == 'idle') {
      dispatch(frameList());
    }
  }, [])


  useEffect(() => {
    if (albumEditModal.popup) {
      let selected = frames.resultData.find((obj) => obj.id == albumEditModal.album.frame_id)
      setSelectedFrame(selected)
      setFrameData({
        album_id: albumEditModal.album?.id,
        cover_photo: albumEditModal?.album?.src?.large,
        cropValue: albumEditModal?.album.crop_value,
        description: albumEditModal?.album.description,
        frameClass: albumEditModal?.album.frame_class,
        frame_id: albumEditModal?.album.frame_id,
        title: albumEditModal?.album.title
      });
    }
  }, [albumEditModal])

  const handleAlbumEdit = async (e) => {
    e.preventDefault();
    setFrameData({ ...frameData, frame_id: selectedFrame.id })
    let data = {
      ...frameData, frame_id: selectedFrame.id
    }
    const resultAction = await dispatch(editAlbum({ data: data }))
    if (editAlbum.fulfilled.match(resultAction)) {
      setAlbumEditModal({})
    }
  }


  return {
    frames: frames?.resultData,
    selectedFrame,
    setSelectedFrame,
    frameData,
    setFrameData,
    handleAlbumEdit,
    album
  };
};
