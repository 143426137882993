import React from "react";
import LoaderSuccessFailure from "../LoaderSuccessFailure";
import styles from "./paymentSuccess.module.scss";
import Icons from "../Layouts/Icons";

const HdfcSuccess = ({ type }) => {
    const handleClick = () => {

        if (type == "success") {
            window.location = "/get-started";
        } else {
            window.location = "/"
        }

    }

    let orderData = JSON.parse(localStorage.getItem("orderDetails"))

    return (
        <>
            {/* <h1>{props}</h1> */}
            <div className={styles.PaymentSuccess}>
                <figure className={styles.PaymentSuccess__image}>
                    <LoaderSuccessFailure status={type} />
                </figure>
                {type == "error" ?
                    <>
                        <h4 className={styles.PaymentSuccess__title}>Payment Failed</h4>

                    </>
                    : type == "success" ?
                        <>
                            <h4 className={styles.PaymentSuccess__title}>Payment Success</h4>
                            <h4 className={styles.PaymentSuccess__title}>We have received your ₹ {orderData?.amount} Payment for order {orderData?.order_id}
                            </h4>
                            <p className={styles.PaymentSuccess__description}>
                                You have successfully purchased plans. Continue to explore FPA
                            </p>
                        </>
                        :
                        ""
                }
                <button
                    className={styles.btn_container}
                    onClick={() => handleClick()}
                >
                    <span>Continue</span>
                    <Icons icon={"back-arrow"} size={20} />

                </button>
            </div>
            {/* <button onClick={() => handleClick()}>Back to home</button> */}
        </>

    )
}
export default HdfcSuccess;
