import Assets from "../components/Layouts/Assets";

const Languages = [
  {
    language: 'English',
    flag: Assets.english_flag,
    id: "lang1",
    code: "en",
  },
  {
    language: 'Español',
    flag: Assets.spanish_flag,
    id: "lang2",
    code: "esp",
  },
  {
    language: 'Deutsch',
    flag: Assets.german_flag,
    id: "lang3",
    code: "nl",
  },
  {
    language: 'Français',
    flag: Assets.french_flag,
    id: "lang4",
    code: "fr",
  },
  {
    language: '日本語',
    flag: Assets.japanese_flag,
    id: "lang5",
    code: "chi",
  },
  {
    language: 'Pусский',
    flag: Assets.russian_flag,
    id: "lang6",
    code: "pol",
  },
  {
    language: 'Italiano',
    flag: Assets.italian_flag,
    id: "lang7",
    code: "ity",
  },
];

export default Languages;
