import { React, useEffect, useState } from "react";
import Style from "./VideoPlayer.module.scss";
import { Slider } from "antd";
import "antd/dist/antd.min.css";
import Icons from "../../components/Layouts/Icons";
import Assets from "../../components/Layouts/Assets";

const Controls = ({
  play,
  setPlay,
  currentDuration,
  secondsToTime,
  totalDuration,
  seekHandler,
  fullScreen,
  fullScreenHandler,
  volume,
  setVolume,
  playBackSpeedHandler,
  scroll,
  inView
}) => {
  function volumeFormat(value) {
    return `${Math.round(value * 100)}%`;
  }
  function durationFormat(currentDuration) {
    return `${secondsToTime(currentDuration)}`;
  }

  // console.log(inView, scroll, "check")

  // useEffect(() => {
  //   if (inView && scroll) {
  //     setEnableAnim(true);
  //     setPlay(true);
  //     setIsPlayed(true);
  //   } else if (!inView && scroll) {
  //     setEnableAnim(false);
  //     setPlay(false);
  //     setIsPlayed(false);
  //   }
  // }, [inView])


  const handlePausePlay = () => {
    setEnableAnim(true);
    setPlay(!play);
    setIsPlayed(true);
  };


  useEffect(() => {
    play ? setIsPlayed(true) : setIsPlayed(false);
    //eslint-disable-next-line
  }, []);

  const [hasVolume, setHasVolume] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [enableAnim, setEnableAnim] = useState(false);

  return (
    <>
      <button
        className={`${Style.icon_video_play_toggle} 
        ${play ? Style.btn_played : Style.btn_paused}
        ${!isPlayed ? Style.video_unplayed : ""}
        ${enableAnim ? Style.anim_enabled : ""}
        `}
        onClick={() => {
          handlePausePlay();
          // !play ? setPlay(true) : setPlay(false);
        }}
      >
        <img
          src={play ? Assets.pause_lg : Assets.play_lg}
          size={17}
          alt="video_toggle_play"
        />
      </button>
      <div className={`${Style.controls} player-controls`}>
        <div className={Style.controls_seek}>
          <Slider
            min={0}
            max={totalDuration}
            value={currentDuration}
            tipFormatter={durationFormat}
            onChange={seekHandler}
            step={0.01}
          />
        </div>
        <div className={Style.controls_other_controls}>
          <div className={Style.controls_left}>
            <div className={`${Style.controls_play} ${Style.controls_item}`}>
              <button
                id="playbutton"
                className={Style.btn_control}
                onClick={() => {
                  handlePausePlay();
                }}
              >
                <Icons
                  icon={play ? "icon-pause-video" : "icon-play-video"}
                  size={17}
                />
              </button>
            </div>
            {/* <div className={`${Style.controls_skip} ${Style.controls_item}`}>
              <button
                className={Style.btn_control}
                onClick={() => {
                  handleSkipVideo();
                }}
              >
                <Icons icon={"icon-skip"} size={17} />
              </button>
            </div> */}
            <div
              className={`${Style.controls_volume} ${Style.controls_item}`}
              onMouseEnter={() => {
                setHasVolume(true);
              }}
              onMouseLeave={() => {
                setHasVolume(false);
              }}
            >
              <button
                className={`${Style.btn_control}`}
                onClick={() => (volume ? setVolume(0) : setVolume(1))}
              >
                <Icons icon={volume ? "icon-volume" : "icon_mute"} size={17} />
              </button>
              {hasVolume && (
                <div className={Style.vol_slider}>
                  <Slider
                    min={0}
                    max={1}
                    step={0.01}
                    tipFormatter={volumeFormat}
                    value={volume}
                    onChange={(value) => {
                      setVolume(value);
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className={`${Style.controls_duration} ${Style.controls_item}`}
            >
              {`${secondsToTime(currentDuration)}/${secondsToTime(
                totalDuration
              )}`}
            </div>
          </div>

          <div className={Style.controls_right}>
            {/* <div
              className={`${Style.controls_settings} ${Style.controls_item}`}
              onClick={playBackSpeedHandler}
            >
              <button className={Style.btn_control}>
                <Icons icon={"icon-settings-video"} size={17} />
              </button>
            </div> */}
            {/* <div
              className={`${Style.controls_theatre_mode} ${Style.controls_item}`}
            >
              <button className={Style.btn_control}>
                <Icons icon={"icon-theatre-mode"} size={17} />
              </button>
            </div> */}
            <div
              className={`${Style.controls_fullscreen} ${Style.controls_item}`}
            >
              <button onClick={fullScreenHandler} className={Style.btn_control}>
                <Icons
                  icon={fullScreen ? "icon-miniscreen" : "icon-fullscreen"}
                  size={17}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Controls;
