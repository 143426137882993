import Style from "../ImageThumb/ImageThumb.module.scss";
import { useEffect, useRef, useState } from "react";
import { useDrag, useDrop, DragPreviewImage } from "react-dnd";
import useGalleryImage from "../../../../Logic/useGalleryImage";
import useGallery from "../../../../Logic/useGallery";
import Icons from "../../../../components/Layouts/Icons";
import { ProgressBar } from "react-bootstrap";
import placeholder1 from "../../../../assets/placeholder-image.svg";
import { handleremoveFailedItem } from "../../../../store/slices/gallerySlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CommonModal from "../../../Modals/commonModal";
import useAlbum from "../../../../Logic/useAlbum";
import { InView, useInView } from "react-intersection-observer";
import { useStaredThumb } from "./useStaredThumb";

const StaredFavImageContainer = ({ image, i, frame, albumData }) => {
  const someRef = useRef(null);
  const dispatch = useDispatch();

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const { toBeDragged, setToBeDragged, handleMouseDown, handleMouseUp } =
    useGalleryImage();

  const { sections, currentTab, allImageStatus, staredImages } = useSelector(
    (state) => state.gallery
  );

  const {
    favorites,
    onLoadState,
    onMouseHover,
    selections,
    handleRemove,
    setOnMouseHover,
    setOnLoadState,
    handleMouseEnter,
    handleMouseLeave,
    handleImageSelect,
  } = useStaredThumb();

  let newImgRatio = (image?.height / image?.width) * 100;

  return (
    <div
      className={Style.drop_container}
      ref={someRef}
      style={{
        opacity: 1,
        // transform: !toBeDragged ? "scale(1)" : "scale(0.95)",
        position: "relative",
      }}
    >
      <div
        className={`${Style.gallery_image_cont}  ${
          image.favourite == 1 ? `${Style.favorite_active} favorite-active` : ""
        } ${favorites?.status === "loading" ? "data-loading" : ""}`}
        style={{ paddingBottom: `${newImgRatio}%` }}
      >
        <figure
          className={`${Style.gallery_image_wrap} ${frame && Style.frameFit} 
                        } ${onMouseHover ? Style.onHover_figure : ""} ${
            !onLoadState ? "img-loading" : "img-loaded"
          }   ${onLoadState && newImgRatio ? Style.imageRatio : ""}`}
          ref={ref}
        >
          {selections?.ImageProfileTab?.selction &&
          selections?.ImageProfileTab?.selectedImage?.includes(image?.id) &&
          selections?.ImageProfileTab?.selction ? (
            <figure className={Style.gallery_select_tick}>
              <Icons icon={"icon-tick"} size={15} />
            </figure>
          ) : selections?.ImageProfileTab?.selction ? (
            <div className="selection" />
          ) : (
            ""
          )}
          <img
            style={{
              transform:
                albumData?.album?.crop_value > 0 &&
                ` scale(${albumData?.album?.crop_value})`,
            }}
            ref={someRef}
            className={` ${
              image?.src?.thumbnail1 ? Style.placeholder_image_active : ""
            } ${currentTab == "all" && Style.galleryMode}`}
            onClick={() => handleImageSelect(image?.id, i)}
            src={
              inView
                ? image?.src?.encoded
                : "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3e%3crect id='Rectangle_22157' data-name='Rectangle 22157' width='12' height='12' fill='%23e8e8e8'/%3e%3c/svg%3e"
            }
            alt="Loading"
            onMouseDown={() => handleMouseDown()}
            onMouseUp={handleMouseUp}
            onLoad={(e) => handleRemove(e)}
          />

          {image?.src?.thumbnail1 ? (
            <div
              className={`${Style.gallery_image_tray} image_tray`}
              style={{ display: toBeDragged ? "none" : "flex" }}
            >
              <div className={Style.tray_details}>
                <div className={Style.gallery_image_tray_author}>
                  {image?.user?.username}{" "}
                </div>
                <div
                  className={`${Style.gallery_image_tray_post_date} post-date-wrap`}
                >
                  {image?.metadata?.file_date_time ? (
                    <>
                      <span className="post-date-label">Captured on:</span>{" "}
                      <span className="post-date">
                        {moment(image?.metadata?.file_date_time).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </figure>
      </div>
      <div></div>
    </div>
  );
};

export default StaredFavImageContainer;
