import { Accordion, Dropdown } from "react-bootstrap";
import { useDimensions } from "../../../Logic/Dimensions";
import BackHeader from "../../BackHeader";
import Style from "./HistoryDrawer.module.scss";
import ReactDOM from "react-dom";
import { useState } from "react";
import HistoryItem from "../HistoryItem";
import CommonModal from "../../Modals/commonModal";
import Header from "./Header";
import VersionSelection from "./VersionSelection";
import { data as dummyData, newData } from "./data";
import { useVersionHistory } from "./useVersionHistory";
import { DashboardLoader } from "../../DashboardLoader";

const HistoryDrawer = ({ handleClose, handleUpdate }) => {
  const {
    versionHistory,
    historyActions,
    openRestoreModal,
    openDeleteModal,
    isDeletingHistory,
    isRestoringVersion,
    isFetchingHistory,
    handleChangeTitle,
    showToast,
    setOpenRestoreModal,
    setOpenDeleteModal,
    handleSelectItem,
    handleRestore,
    handleDeleteVersion,
    handleOpenDeleteModal,
  } = useVersionHistory({ handleUpdate, handleClose });


  let hasNoItems = versionHistory.every(obj => obj?.versions?.length === 0);


  return ReactDOM.createPortal(
    <div className={Style.root}>
      <div className={Style.HistoryDrawerWrapper}>
        <Header handleClose={handleClose} />

        {hasNoItems ?
          <div className={Style.no_data}>No histories yet</div> :
          <div className={Style.HistoryDrawerWrapper_content}>
            <div className={Style.HistoryDrawerWrapper_versions}>
              {versionHistory?.map((item, i) => {
                return (
                  <>
                    {item.type && item.versions?.length > 0 ? (
                      <h4 className={Style.HistoryDrawerWrapper_versions_title}>
                        {item.type}
                      </h4>
                    ) : (
                      ""
                    )}
                    {item.versions?.length == 1 && (
                      <HistoryItem
                        data={item.versions[0]}
                        handleChange={handleChangeTitle}
                        setActionDelete={(e) =>
                          handleOpenDeleteModal(e, item.versions[0])
                        }
                        setActionRestore={setOpenRestoreModal}
                        onSelectItem={(e) => handleSelectItem(e)}
                        className={
                          historyActions?.selectedId == item?.versions[0]?.id &&
                          "selected"
                        }
                        isActive={historyActions?.itemId == item?.versions[0]?.id}
                      />
                    )}

                    {item.versions?.length > 1 ? (
                      <Accordion>
                        {[1]?.map((AccItem, k) => (
                          <>
                            {item.versions?.length > 1 && (
                              <>
                                <Accordion.Item key={k} eventKey={k?.toString()}>
                                  <Accordion.Header>
                                    {item?.versions[0].date}
                                  </Accordion.Header>
                                  {item?.versions?.map((item, j) => (
                                    <Accordion.Body>
                                      <HistoryItem
                                        data={item}
                                        date={item.date} // Replace with the desired date property from AccItem
                                        setActionDelete={(e) =>
                                          handleOpenDeleteModal(e, item)
                                        }
                                        setActionRestore={setOpenRestoreModal}
                                        onSelectItem={(e) => handleSelectItem(e)}
                                        className={
                                          historyActions?.selectedId === item.id &&
                                          "selected"
                                        }
                                        isActive={historyActions?.itemId == item.id}
                                      />
                                    </Accordion.Body>
                                  ))}
                                </Accordion.Item>
                              </>
                            )}
                          </>
                        ))}
                      </Accordion>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </div>
          </div>
        }


        {historyActions?.isShowRestoreBtn && (
          <div
            className={`${Style.HistoryDrawerWrapper_footer} ${Style.ShowRestoreBtn}`}
          >
            <button
              className={`btn btn-secondary w-100 ${Style.restore_btn}`}
              onClick={() => setOpenRestoreModal(true)}
            >
              Restore this version
            </button>
          </div>
        )}

        {isFetchingHistory == "loading" ?
          <DashboardLoader data={[]} /> : ""
        }

      </div>
      <CommonModal
        showModal={openDeleteModal}
        hide={() => setOpenDeleteModal(false)}
        className={Style.DeleteModal}
        title={""}
        content={
          <div className={Style.DeleteModal_body}>
            <div className={Style.DeleteModal_header}>
              <h4>Delete this version</h4>
              <p>Are you sure you want to delete this version</p>
            </div>
            <div className={Style.DeleteModal_button_wrapper}>
              <button
                className={`btn btn-sm btn-border btn-border-grey ${Style.DeleteModal_button_cancel}`}
                onClick={() => setOpenDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className={`btn btn-sm btn-red ${Style.DeleteModal_button_Delete}`}
                onClick={() => {
                  handleDeleteVersion(historyActions?.itemId);
                  showToast("2023 April 25,06:22 PM Version has been deleted");
                }}
              >
                {isDeletingHistory == "loading" ? "..." : "Continue"}
              </button>
            </div>
          </div>
        }
      />
      <CommonModal
        showModal={openRestoreModal}
        hide={() => setOpenRestoreModal(false)}
        className={Style.RestoreModal}
        title={""}
        content={
          <div className={Style.RestoreModal_body}>
            <div className={Style.RestoreModal_header}>
              <h4>Restore this version</h4>
              <p>
                Your current version will be revert from  {historyActions?.info}{" "}
              </p>
            </div>
            <div className={Style.RestoreModal_button_wrapper}>
              <button
                className={`btn btn-sm btn-border btn-border-grey ${Style.RestoreModal_button_cancel}`}
                onClick={() => setOpenRestoreModal(false)}
              >
                Cancel
              </button>
              <button
                className={`btn btn-sm btn-secondary ${Style.RestoreModal_button_restore}`}
                onClick={() => {
                  handleRestore(historyActions?.itemId);
                  // showToast("Restored version: 2023 April 25,06:22 PM");
                }}
              >
                {isRestoringVersion == "loading" ? "..." : "Restore"}
              </button>
            </div>
          </div>
        }
      />
    </div>,
    document.getElementById("slideshow-root")
  );
};

export default HistoryDrawer;
