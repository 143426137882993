import { useState } from "react";

export const useOverView = ({
  openStarredImageModal,
  setOpenStarredImageModal,
}) => {
  const [options, setOptions] = useState({
    isRearrange: false,
  });

  const closeReArrange = () => {
    setOptions({
      isRearrange: false,
    });
  };

  const opemReArrange = () => {
    setOptions({
      isRearrange: true,
    });
  };

  const openStaredImages = () => {
    setOpenStarredImageModal(!openStarredImageModal);
  };

  return {
    options,
    openStaredImages,
    closeReArrange,
    opemReArrange,
  };
};
