import { useFormik } from "formik"
import * as Yup from "yup";
import { addVideoLink, updateVideoLink } from "../../../store/slices/Videos/VideoLinkSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

export const useVideoModal = (close) => {
    const dispatch = useDispatch()

    const { isCreatingLink, videoActions } = useSelector((state) => state?.videos);
    const [thumbUrl, setThumbUrl] = useState("")

    const videoSchema = Yup.object({
        title: Yup.string().required("Title is required"),
        video_url: Yup.string().url('Invalid URL').required('Video link is required'),
        description: Yup.string().required("Description is required"),
        thumbnail: Yup.string().notRequired(),
    });


    let initialData = {
        title: "",
        video_url: "",
        description: "",
        thumbnail: ""
    }

    useEffect(() => {
        setThumbUrl(videoActions.currentData.thumbnail)
    }, [videoActions.currentData.thumbnail])



    const formik = useFormik({
        validationSchema: videoSchema,
        enableReinitialize: true,
        initialValues: videoActions?.mode == "edit" ? videoActions?.currentData : initialData,
        onSubmit: async (values) => {
            if (videoActions?.mode == "all") {
                const formData = new FormData();
                formData.append('title', values.title);
                formData.append('video_url', values.video_url);
                formData.append('description', values.description);
                if (values.thumbnail) {
                    formData.append('thumbnail', values.thumbnail);
                }

                const resultAction = await dispatch(addVideoLink(formData));
                if (addVideoLink.fulfilled.match(resultAction)) {
                    handleCloseModal()
                }
            } else {
                const formData = new FormData();
                formData.append('video_id', values.id);
                formData.append('title', values.title);
                formData.append('video_url', values.video_url);
                formData.append('description', values.description);
                if (values.thumbnail) {
                    formData.append('thumbnail', values.thumbnail);
                }
                // formData.append('thumbnail', values.thumbnail && values.thumbnail);

                const resultAction = await dispatch(updateVideoLink(formData));
                if (updateVideoLink.fulfilled.match(resultAction)) {
                    handleCloseModal()
                }
            }

        },

    })



    const handleFileChange = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue("thumbnail", file)
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setThumbUrl(reader.result)

            };
            reader.readAsDataURL(file);
        }
    }

    const handleRemove = () => {
        formik.setFieldValue("thumbnail", "")
    }

    const handleCloseModal = () => {
        close(false)
        formik.resetForm()
    }



    return { formik, isCreatingLink, thumbUrl, videoActions, handleFileChange, handleRemove, handleCloseModal }
}