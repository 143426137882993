import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  status: "idle",
  error: null,
  resultData: {}
};


export const getSlideDetails = createAsyncThunk(
  "slideShowDetail/getSlideDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/view-slideshow/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const slideShowDetailSlice = createSlice({
  name: "slideShowDetail",
  initialState,
  reducers: {
    deleteImage: (state, action) => {
      let images = [...current(state?.resultData?.images)];
      let objWithIdIndex = images.findIndex((obj) => obj.id == action.payload.id);
      if (objWithIdIndex > -1) {
        images.splice(objWithIdIndex, 1);
        state.resultData.images = images;
      }
    },
    updateSlideDetails: (state, action) => {
      state.resultData.status = "temp"
      state.resultData.slideshow = action?.payload?.details
    },
    addSlideShow: (state, action) => {
      state.status = 'succeeded'
      state.resultData.images = action.payload.images;

    },
    clearSlideShow: (state, action) => {
      state.status = 'idle'
      state.resultData.images = {};
    }
    // updateFavorite: (state, action) => {
    //   if (state?.resultData?.data) {
    //     let index = state?.resultData?.data.findIndex((obj) => obj.id === action?.payload?.id)
    //     state.resultData.data[index].favourite = action?.payload?.favorite;
    //   }
    // },

  },
  extraReducers: {
    [getSlideDetails.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSlideDetails.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData = { ...action?.payload?.data?.result }
    },
    [getSlideDetails.rejected]: (state, action) => {
      state.status = "failed";
    },
    ["slideshow/saveSlideShow/fulfilled"]: (state, action) => {
      state.resultData.slideshow = {
        ...state.resultData.slideshow,
        id: action.payload.data.result.id

      }
    }

  },
});

export const {
  deleteImage,
  addSlideShow,
  updateSlideDetails,
  clearSlideShow
} = slideShowDetailSlice.actions;

export default slideShowDetailSlice.reducer;
