import { useState, useRef, useEffect } from "react";
import ImageListMasonry from "../../../ImageListMasonry";
import Style from "../../UserProfileOverview/UserProfileOverview.module.scss";
import { useOutletContext } from "react-router";

const StarredImages = () => {
    const [data, userdata] = useOutletContext();
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    const capitalizeFirstLetter = function (str) {
        // converting first letter to uppercase
        if (str != "" && str != undefined && str != null) {
            const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
            return capitalized;
        } else {
            return;
        }
    }


    return (
        <div className={Style.User_profile_overview}>
            <div className={Style.User_profile_overview_main_col}>
                <div className={Style.User_profile_overview_sec}>
                    <h2 className={Style.User_profile_overview_title}>Bio</h2>
                    <p className={Style.User_profile_overview_p}>
                        {isReadMore && userdata?.family_members?.[0]?.bio?.length > 0
                            ? capitalizeFirstLetter(userdata?.family_members?.[0]?.bio?.slice(0, 400))
                            : capitalizeFirstLetter(userdata?.family_members?.[0]?.bio)}
                        {userdata?.family_members?.[0]?.bio?.length > 400 && (
                            <span onClick={toggleReadMore} className={Style.Read_or_hide}>
                                {isReadMore ? "...read more" : " show less"}
                            </span>
                        )}
                    </p>
                </div>
                {/* gallery */}
                <div className={Style.User_profile_overview_sec}>
                    <div className={`${Style.User_profile_overview_sec_head} ${data?.length > 0 ? Style.User_profile_overview_sec_errorWrap : ''}`}>
                        <h2 className={Style.User_profile_overview_title}>
                            Profile Gallery
                        </h2>
                        {data?.length === 0 &&
                            <div className={Style.errorMessage}>
                                <p>Your profile gallery is empty!</p>
                            </div>}
                    </div>

                    <ImageListMasonry
                        total={data?.length}
                        Images={data}
                        loadMore={{}}
                        NodataContent={{}}
                        options={{}}
                        moveListItem={{}}
                        disableControls={true}
                        isShare={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default StarredImages;
