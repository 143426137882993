import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addTags, removeTags } from "../../../../store/slices/tagSlice";

export const useTags = ({ data, currentIndex, albumIndex }) => {
    const dispatch = useDispatch()
    const [tags, setTags] = useState([]);


    useEffect(() => {
        setTags(data[currentIndex]?.tags);
    }, [currentIndex]);


    const handleDelete = async (i) => {
        const resultAction = await dispatch(
            removeTags({ image_id: data[currentIndex].id, tag: [tags[i]?.text], album_id: albumIndex })
        );

        if (removeTags.fulfilled.match(resultAction)) {
            setTags(tags.filter((tag, index) => index !== i));
        }

    };


    const handleAddition = async (tag) => {
        const resultAction = await dispatch(
            addTags({ image_id: data[currentIndex].id, tag: [tag.text], album_id: albumIndex })
        );
        if (addTags.fulfilled.match(resultAction)) {
            setTags([...tags, tag]);
        }
    };


    return {
        tags,
        setTags,
        handleDelete,
        handleAddition
    }
}