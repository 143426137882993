import React from "react";
import Style from "./SelectedImageItem.module.scss";
import Assets from "../../../../components/Layouts/Assets";
import Icons from "../../../Layouts/Icons";

const SelectedImageItem = ({ data }) => {
  return (
    <div className={`${Style.selected_image_item} ${data?.locked&&Style.locked}`}>
      <div className={Style.selected_image}>
        <img
          src={
            data?.src?.thumbnail ? data?.src?.thumbnail : Assets.upload_thumb
          }
          alt=""
        />
      </div>
      <div className={Style.selected_image_head}>
        <h4 className={Style.selected_img_title}>{data?.title}</h4>
        {data?.locked&&<div className={Style.lock_btn}>
          <Icons icon={"lock"} size={18} />
        </div>}
        {/* <p className={Style.selected_img_time}>Jan-30-2022</p> */}
      </div>
    </div>
  );
};

export default SelectedImageItem;
