import React from "react";
import { Link } from "react-router-dom";
import Icons from "../Layouts/Icons";
import Style from "./DemoCard.module.scss";

const DemoCard = ({ thumb, title, description, path }) => {
  return (
    <div className={Style.demo_card_wrap}>
      <Link to={`/quick-demo/${path}`}>
        <div className={Style.demo_card}>
          <figure className={Style.demo_card_image}>
            <img src={thumb} alt="demo-thumb" />
            <Icons icon={"slideshow-play-btn"} size={46} />
          </figure>
          <div className={Style.demo_card_details}>
            <h4 className={Style.demo_card_title}>{title}</h4>
            <p className={Style.demo_card_description}>{description}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default DemoCard;
