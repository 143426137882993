import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";


const initialState = {
  isLoading: false,
  searchQuery: "",
  results: [],
  option:"",
};



export const triggerSearchAPI = createAsyncThunk(
  "usersearchbar/triggerSearchAPI",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const { query, option,per_page } = obj;

      const packet = new FormData();
      packet.append("search", query);
      packet.append("page", 1);
      packet.append("type", option);
      packet.append("per_page", per_page);
      const response = await api.post(`search`, packet);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSearchSlice = createSlice({
  name: "usersearchbar",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
  },
  extraReducers: {
    [triggerSearchAPI.pending]: (state) => {
      state.isLoading = true;
    },
    [triggerSearchAPI.fulfilled]: (state, action) => {
      state.isLoading = false;
      let result = action.payload.data?.result?.data.filter(obj => obj.locked != 1)
      state.results = result;
      state.searchQuery = action.meta.arg.query;
      state.option = action.meta.arg.option;
    },
    [triggerSearchAPI.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { showLoading } = userSearchSlice.actions;

export default userSearchSlice.reducer;
