
import { useState } from "react";
import Style from "./VideoPreviewModal.module.scss";
import { Modal } from "react-bootstrap";
import { useRef } from "react";
import Assets from "../Layouts/Assets";
import VideoPlayer from "../../utils/VideoPlayer";
import ReactPlayer from "react-player";


const VideoPreviewModal = ({ open, close, data }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [play, setPlay] = useState(false);


  const videoRef = useRef();
  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };
  return (
    <div className={Style.VideoPreviewModal_sec}>
      <Modal
        show={open}
        onHide={close}
        contentLabel="Video Preview"
        className={Style.VideoPreviewModal}
        centered
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <div>

          {/* <video
            ref={videoRef}
            src={"https://youtu.be/2CPrn1PE5e0"}
            className={Style.VideoPreviewModal_video}
            // onTimeUpdate={handleTimeUpdate}
            controls
          /> */}
          {/* <p>Current Time: {currentTime}</p>
          <button onClick={hide}>Close</button> */}
        </div>
        <VideoPlayer
          play={play}
          setPlay={setPlay}
          vidUrl={data?.url}
          autoplay={true}
          loop={true}
          className={Style.VideoPreviewModal_video}
        />

      </Modal>
    </div>
  );
};

export default VideoPreviewModal