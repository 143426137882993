import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAlbums } from "../../../../store/slices/albumSlice";
import {
  addtoArchive,
  getAchiveList,
  removeArchive,
} from "../../../../store/slices/Archive/archiveSlice";
import { getFavoriteImages } from "../../../../store/slices/favoriteSlice";
import {
  getGalleryImages,
  LoadmoreImages,
  updateAllImagesAfterFacesUpdate,
} from "../../../../store/slices/GalleryImagesSlice";
import {
  enableImageSelection,
  disableImageSelection,
  SelectMultImage,
  DeSelectImage,
  currentSortOption,
  swtichGridOption,
  disableAlbumImageSelection,
  resetAlbumSelection,
  callFunction,
  openRearrange,
  closeRearrange,
  SelectMultAddToAlbumImage,
  DeSelectAddToAlbumImage,
} from "../../../../store/slices/GalleryTabSlice";
import { openSlideShow } from "../../../../store/slices/slideshow/QuickSliseShowSlice";
import { getSlideshows } from "../../../../store/slices/slideshow/slideshowSlice";
import { toast } from "react-toastify";
import {
  getTrashList,
  removeTrash,
} from "../../../../store/slices/Trash/trashSlice";
import { addSlideShow } from "../../../../store/slices/SlideShowDetailSlice";
import { useNavigate } from "react-router-dom";

import {
  addToStared,
  getStaredList,
} from "../../../../store/slices/ProfileGallery/profileGallerySlice";
import { useParams } from "react-router-dom";
import {
  imageAddToAlbum,
  getAlbumItem,
  loadMoreAlbumDetailImages,
} from "../../../../store/slices/Album/AlbumDetails";

const useRightFilteredCategory = (currentPath) => {
  const { albumId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selections = useSelector((state) => state.galleryTab);
  const slideDetails = useSelector((state) => state.slideShowDetail);

  const Images = useSelector((state) => state.galleryAllTab.resultData);
  const Favorites = useSelector((state) => state.favorite.resultData);
  const Album = useSelector((state) => state.album.resultData);
  const Slideshow = useSelector((state) => state.slideshow.resultData);
  const Archive = useSelector((state) => state.archive.resultData);
  const Trash = useSelector((state) => state.trash.resultData);

  const AlbumItem = useSelector((state) => state.albumDetails.resultData);

  const ProfileGallery = useSelector(
    (state) => state.profileGallery.resultData
  );

  const [selectAll, setSelectAll] = useState(false);
  const [filterlebel, setFilterLabel] = useState("");
  const [addToAlbum, setAddToAlbum] = useState({ popup: false, type: "add" });
  const [deleteModal, setDeleteModal] = useState({ popup: false });
  const [deleteAlbumModal, setDeleteAlbumModal] = useState({ popup: false });
  const [deleteSlideShowModal, setDeleteSlideShowModal] = useState({
    popup: false,
  });

  const [deleteAlbumImageModal, setDeleteAlbumImageModal] = useState({
    popup: false,
  });
  const [trashDeleteModal, setTrashDeleteModal] = useState({ popup: false });

  const [slideshowCreateModal, setSlideshowCreateModal] = useState({
    popup: false,
  });

  let currnetAlbumView = localStorage.getItem("albumView");

  useEffect(() => {
    dispatch(swtichGridOption(currnetAlbumView == "list" ? false : true));
  }, [currnetAlbumView]);

  let tempFavSort = [
    { name: "position", code: "pos" },
    { name: "latest", code: "desc" },
    { name: "oldest", code: "asc" },

  ];

  let tempSort = [
    { name: "latest", code: "desc" },
    { name: "oldest", code: "asc" },
    { name: "captured", code: "captured" },

  ]

  let tempAlbumDetailsSort = [
    { name: "position", code: "pos" },
    { name: "latest", code: "desc" },
    { name: "oldest", code: "asc" },
    { name: "captured", code: "captured" },
  ]

  const getSortLabel = (path) => {
    let label = "";
    if (path == "favorites") {
      label = tempFavSort?.[0]?.name;
    } else if (path == "album") {
      label = Album?.sorting_options?.[0]?.name;
    } else if (path == "slideshow") {
      label = Slideshow?.sorting_options?.[0]?.name;
    } else if (path == "archive") {
      label = Archive?.sorting_options?.[0]?.name;
    } else if (path == "trash") {
      label = Trash?.sorting_options?.[0]?.name;
    } else if (path == `album${albumId}`) {
      label = AlbumItem.data?.[albumId]?.sorting_options?.[2]?.name;
    } else {
      label = Images?.sorting_options?.[0]?.name;
    }
    return label;
  };

  // Favorites?.sorting_options;
  const getSortOptions = (path) => {
    let data = [];
    if (path == "favorites") {
      data = tempFavSort;
    } else if (path == "album") {
      data = Album?.sorting_options;
    } else if (path == "slideshow") {
      data = Slideshow?.sorting_options;
    } else if (path == "archive") {
      data = Archive?.sorting_options;
    } else if (path == "trash") {
      data = Trash?.sorting_options;
    } else if (path == `album${albumId}`) {
      data = tempAlbumDetailsSort
    } else {
      data = tempSort
    }
    return data;
  };
  //Images?.sorting_options;
  //AlbumItem?.data?.[albumId]?.sorting_options;
  useEffect(() => {
    setFilterLabel(getSortLabel(currentPath));
  }, [
    currentPath,
    Images?.sorting_options?.[0]?.name,
    Favorites?.sorting_options?.[0]?.name,
    Album?.sorting_options?.[0]?.name,
    Slideshow?.sorting_options?.[0]?.name,
    Archive?.sorting_options?.[0]?.name,
    Trash?.sorting_options?.[0]?.name,
    AlbumItem?.data?.[albumId]?.sorting_options?.[0]?.name,
  ]);

  const handleSelectClick = () => {
    dispatch(enableImageSelection({ tab: currentPath }));
  };

  const handleCloseSelection = () => {
    dispatch(disableImageSelection({ tab: currentPath }));
    setSelectAll(false);
  };

  const handleSlideShowImageSelection = () => {
    if (selections?.AddSlideShowSelection?.selectedImage?.length > 0) {
      navigate(`slideshow/edit/${slideDetails?.resultData?.slideshow?.id}`);
      dispatch(disableImageSelection({ tab: "addslideshow" }));
    } else {
      dispatch(disableImageSelection({ tab: "addslideshow" }));
    }
  };

  let getImgData = (path) => {
    let ImageIds = [];
    if (path == "") {
      ImageIds = Images?.data?.map((obj) => obj.id);
    } else if (path == "favorites") {
      ImageIds = Favorites?.data?.map((obj) => obj.id);
    } else if (path == "slideshow") {
      ImageIds = Slideshow?.data?.map((obj) => obj.id);
    } else if (path == "archive") {
      ImageIds = Archive?.data?.map((obj) => obj.id);
    } else if (path == "trash") {
      ImageIds = Trash?.data?.map((obj) => obj.id);
    } else if (path == `album${albumId}`) {
      ImageIds = AlbumItem?.data?.[albumId]?.images?.map((obj) => obj.id);
    } else if (path == "album") {
      ImageIds = Album?.data
        ?.filter((x) => !x.default && !x.locked && !x.photofind_album)
        ?.map((obj) => obj.id);
    }

    return ImageIds;
  };

  let getSectionData = (path) => {
    let ImageData = {};
    if (path == "") {
      ImageData = Images;
    } else if (path == "favorites") {
      ImageData = Favorites;
    } else if (path == "slideshow") {
      ImageData = Slideshow;
    } else if (path == "archive") {
      ImageData = Archive;
    } else if (path == "trash") {
      ImageData = Trash;
    } else if (path == `album${albumId}`) {
      ImageData = AlbumItem;
    } else if (path == "album") {
      ImageData = Album;
    }

    return ImageData;
  };


  let getSelectedData = (path) => {
    let ImageIds = [];
    if (path == "") {
      ImageIds = selections?.ImageAllTabSelection?.selectedImage;
    } else if (path == "slideshow") {
      ImageIds = selections?.ImageSlideshowTab?.selectedImage;
    } else if (path == "archive") {
      ImageIds = selections?.ImageArchiveTab?.selectedImage;
    } else if (path == "trash") {
      ImageIds = selections?.ImageTrashTab?.selectedImage;
    } else if (path == `album${albumId}`) {
      ImageIds = selections?.ImageAlbumDetailsTab?.selectedImage;
    } else if (path == "album") {
      ImageIds = selections?.ImageAlbumTab?.selectedImage;
    }

    return ImageIds;
  };

  const handleSelectAll = () => {
    if (
      getSelectedData(currentPath)?.length == getImgData(currentPath)?.length
    ) {
      setSelectAll(false);
      dispatch(DeSelectImage({ tab: currentPath }));
    } else {
      let ImageIds = getImgData(currentPath);
      dispatch(SelectMultImage({ tab: currentPath, ImageIds }));
      setSelectAll(true);
    }

    //FIXME when image paginate need to handle
  };

  const handleClickFilter = async (data) => {
    setFilterLabel(data.name);

    dispatch(
      currentSortOption({
        tab: currentPath,
        sortOption: data.code,
      })
    );

    let obj = {
      page: "1",
      slug: data?.code == "captured" ? "" : data?.code,
      param: data?.code == "captured" ? data.code : ""
    };

    if (currentPath == "favorites") {
      dispatch(getFavoriteImages(obj));
    } else if (currentPath == "album") {

      dispatch(getAlbums(obj));
    } else if (currentPath == "slideshow") {
      dispatch(getSlideshows(obj));
    } else if (currentPath == "archive") {
      dispatch(getAchiveList(obj));
    } else if (currentPath == "trash") {
      dispatch(getTrashList(obj));
    } else if (currentPath == `album${albumId}`) {
      let obj = {
        page: "1",
        slug: data.code,
        param: data.code,
        id: albumId,
      }
      const resultAction = await dispatch(getAlbumItem(obj));
      if (getAlbumItem.fulfilled.match(resultAction)) {
        loadMoreAlbum(resultAction?.payload?.data?.result, data);
      }
    } else {
      console.log(obj, "ob1...")
      const resultAction = await dispatch(getGalleryImages(obj));
      if (getGalleryImages.fulfilled.match(resultAction)) {
        loadMore(resultAction?.payload?.data?.result, data);
      }
    }
  };

  const loadMore = async (Images, data) => {
    if (Images?.current_page < Images?.last_page) {
      let obj = {
        page: Images?.current_page + 1,
        slug: data?.code == "captured" ? "" : data?.code,
        param: data?.code == "captured" ? "captured" : ""
      };
      const resultAction = await dispatch(LoadmoreImages(obj));
      if (LoadmoreImages.fulfilled.match(resultAction)) {
        loadMore(resultAction?.payload?.data?.result, data);
      }
    }
  };



  const loadMoreAlbum = async (Album) => {
    if (Album?.current_page < Album?.last_page) {
      let obj = {
        page: Album?.current_page + 1,
        slug: selections?.sort,
        id: albumId
      };
      const resultAction = await dispatch(loadMoreAlbumDetailImages(obj));
      if (loadMoreAlbumDetailImages.fulfilled.match(resultAction)) {
        loadMoreAlbum(resultAction?.payload?.data?.result);
      }
    }
  };


  const handleSwtichGridView = (gridView) => {
    if (gridView) {
      localStorage.setItem("albumView", "list");
    } else {
      localStorage.setItem("albumView", "grid");
    }
    dispatch(swtichGridOption(gridView ? false : true));
  };

  const handleOpenQuickSlideShow = () => {
    dispatch(openSlideShow({ open: true, type: "selected" }));
  };

  const handleAddToArchive = async () => {
    let image_id = selections.ImageAllTabSelection.selectedImage;
    let AllImages = Images?.data?.filter((item) => image_id.includes(item.id));

    const resultAction = await dispatch(
      addtoArchive({ image_id, data: AllImages })
    );
    if (addtoArchive.fulfilled.match(resultAction)) {
      toast.success("archive success !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleUnArchive = async () => {
    let image_id = selections.ImageArchiveTab.selectedImage;
    let AllImages = Archive?.data?.filter((item) => image_id.includes(item.id));

    const resultAction = await dispatch(
      removeArchive({ image_id, data: AllImages })
    );
    if (removeArchive.fulfilled.match(resultAction)) {
      toast.success("unarchive success !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleRestore = async () => {
    let image_id = selections.ImageTrashTab.selectedImage;
    let AllImages = Trash?.data?.filter((item) => image_id.includes(item.id));

    const resultAction = await dispatch(
      removeTrash({ image_id, data: AllImages })
    );
    if (removeTrash.fulfilled.match(resultAction)) {
      toast.success("restore success !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleClickProfileGallery = () => {
    let prevItems = ProfileGallery?.data?.map((item) => item.id);

    const openFavoritePreSelection = (state) => {
      state.ImageFavoriteTab = {
        ...state.ImageFavoriteTab,
        selction: true,
        selectedImage: prevItems,
      };
    };
    dispatch(callFunction(openFavoritePreSelection));
  };

  const handleClickAddToProfileGallery = async () => {
    let image_id = selections.ImageFavoriteTab.selectedImage;
    const resultAction = await dispatch(addToStared({ image_id }));
    if (addToStared.fulfilled.match(resultAction)) {
      toast.success("add to stared success", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleOpenReArrange = (path) => {
    dispatch(openRearrange({ tab: path }));
  };

  const handleCloseReArrange = (path) => {
    dispatch(closeRearrange({ tab: path }));
  };

  const handleOpenAddPhotosToAlbum = () => {
    let prevItems = AlbumItem.data[albumId]?.images?.map((item) => item.id);
    const openAddToAlbumImagePreSelection = (state) => {
      state.ImageAlbumAddToTab = {
        ...state.ImageAlbumAddToTab,
        selction: true,
        selectedImage: prevItems,
      };
    };
    dispatch(callFunction(openAddToAlbumImagePreSelection));
  };

  const handleCloseAddPhotosToAlbum = () => {
    const closeAddToAlbumImagePreSelection = (state) => {
      state.ImageAlbumAddToTab = {
        ...state.ImageAlbumAddToTab,
        selction: false,
        selectedImage: [],
      };
    };
    dispatch(callFunction(closeAddToAlbumImagePreSelection));
  };

  const handleSelectAllAddToAlbum = () => {
    let ImageIds = Images?.data?.map((obj) => obj.id);

    if (
      selections.ImageAlbumAddToTab.selectedImage?.length == ImageIds?.length
    ) {
      setSelectAll(false);
      dispatch(DeSelectAddToAlbumImage());
    } else {
      let ImageIds = Images?.data.map((obj) => obj.id);
      dispatch(SelectMultAddToAlbumImage({ ImageIds }));
      setSelectAll(true);
    }
  };

  const addSlideShowHandler = () => {
    dispatch(
      addSlideShow({
        images: selections?.AddSlideShowSelection?.selectedImageDetail,
      })
    );
    dispatch(resetAlbumSelection({ tab: "addslideshow" }));
    navigate(`slideshow/edit/${slideDetails?.resultData?.slideshow?.id}`);
  };

  const saveAlbumImages = async () => {
    let data = {
      album_id: albumId,
      image_id: selections.ImageAlbumAddToTab.selectedImage,
    };

    const resultAction = await dispatch(imageAddToAlbum(data));
    if (imageAddToAlbum.fulfilled.match(resultAction)) {
      toast.success("Image Added success", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (AlbumItem?.data[albumId]?.album?.default) {
        let obj = {
          page: "1",
          slug: "",
        };
        dispatch(
          updateAllImagesAfterFacesUpdate({
            image_ids: selections?.ImageAlbumAddToTab?.selectedImage,
          })
        );
        dispatch(getFavoriteImages(obj));
        dispatch(getStaredList());
      }
    }
  };

  return {
    handleSelectClick,
    selections,
    handleCloseSelection,
    handleSelectAll,
    selectAll,
    filterlebel,
    sortOptions: getSortOptions(currentPath),
    currentTabData: getImgData(currentPath),
    sectionData: getSectionData(currentPath),
    handleClickFilter,
    handleSwtichGridView,
    addToAlbum,
    setAddToAlbum,
    deleteModal,
    setDeleteModal,
    slideshowCreateModal,
    setSlideshowCreateModal,
    handleSlideShowImageSelection,
    handleOpenQuickSlideShow,
    handleAddToArchive,
    handleUnArchive,
    handleRestore,
    addSlideShowHandler,
    deleteAlbumModal,
    setDeleteAlbumModal,
    handleClickAddToProfileGallery,
    handleClickProfileGallery,
    deleteAlbumImageModal,
    setDeleteAlbumImageModal,
    handleOpenReArrange,
    handleCloseReArrange,
    handleOpenAddPhotosToAlbum,
    handleSelectAllAddToAlbum,
    handleCloseAddPhotosToAlbum,
    saveAlbumImages,
    deleteSlideShowModal,
    setDeleteSlideShowModal,
    getSelectedData,
    trashDeleteModal,
    setTrashDeleteModal,
  };
};

export default useRightFilteredCategory;
