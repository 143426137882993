const useAddRelations = (currentUserData, mainData, data) => {
  const getNewId = () => {
    const getIDs = data[0]?.allData.map((item) => {
      return item.id;
    });
    let newID = Math.max(...getIDs) + 1;
    return newID;
  };

  const relationBuilding = (connection, meta, connectionWithNode) => {
    switch (connection) {
      case "father":
        return handleAddFather();
      case "mother":
        return handleAddMother();
      case "sibling":
        return handleAddSibling(connectionWithNode);
      case "partner":
        return handleAddSpouse(meta);
      case "children":
        return handleAddChild(meta);
      case "pet":
        return handleAddPet();
    }
  };

  const isMotherExist = () => {
    if (currentUserData.mid) {
      let mother = mainData.filter((x) => x.id == currentUserData.mid);
      return mother;
    }
    return false;
  };

  const isFatherExist = () => {
    if (currentUserData.fid) {
      let father = mainData.filter((x) => x.id == currentUserData.fid);
      return father;
    }
    return false;
  };

  const isSiblingsExist = () => {
    let siblings = [];
    if (currentUserData.fid) {
      let [sibling] = mainData.filter(
        (x) => x.fid == currentUserData.fid && currentUserData.id != x.id
      );
      siblings.push(sibling);
    }
    if (currentUserData.mid) {
      let [sibling] = mainData.filter(
        (x) => x.mid == currentUserData.mid && currentUserData.id != x.id
      );
      siblings.push(sibling);
    }
    if (siblings.length != 0) {
      return siblings;
    }
    return false;
  };

  const handleAddFather = () => {
    if (!isFatherExist()) {
      let newID = getNewId();
      let mid = isMotherExist();
      let updateTree = [
        {
          id: currentUserData.id,
          change: {
            fid: newID,
          },
        },
      ];

      let NewTree = {
        id: newID,
        pids: [],
        connection: "father",
        gender: 1,
      };

      if (mid != false) {
        updateTree = [
          ...updateTree,
          { id: mid[0].id, change: { pids: newID } },
        ];
        NewTree = { ...NewTree, pids: [mid[0].id] };
      }

      if (isSiblingsExist() != false) {
        isSiblingsExist().map((value) => {
          updateTree = [
            ...updateTree,
            { id: value.id, change: { fid: newID } },
          ];
        });
      }

      let tempdata = checkExistingData(updateTree);
      return [tempdata, NewTree];
    } else {
      return [mainData, isFatherExist()[0]];
    }
  };

  const handleAddMother = () => {
    if (!isMotherExist()) {
      let newID = getNewId();
      let fid = isFatherExist();

      let updateTree = [
        {
          id: currentUserData.id,
          change: {
            mid: newID,
          },
        },
      ];

      let NewTree = {
        id: newID,
        pids: [],
        connection: "mother",
        gender: 2,
      };

      if (fid != false) {
        updateTree = [
          ...updateTree,
          { id: fid[0].id, change: { pids: newID } },
        ];
        NewTree = { ...NewTree, pids: [fid[0].id] };
      }

      if (isSiblingsExist() != false) {
        isSiblingsExist().map((value) => {
          updateTree = [
            ...updateTree,
            { id: value.id, change: { mid: newID } },
          ];
        });
      }

      let tempdata = checkExistingData(updateTree);
      return [tempdata, NewTree];
    } else {
      return [mainData, isMotherExist()[0]];
    }
  };

  const handleAddSibling = (connectionWithNode) => {
    let fid = isFatherExist();
    let mid = isMotherExist();
    let newID = getNewId();

    let NewTree = {
      id: newID,
      connection: "sibling",
    };
    if (fid != false || mid != false) {
      NewTree = {
        ...NewTree,
        mid: currentUserData.mid,
        fid: currentUserData.fid,
        gender:
          connectionWithNode == "brother"
            ? 1
            : connectionWithNode == "sister"
            ? 2
            : null,
      };
    }
    return [mainData, NewTree];
  };

  const handleAddSpouse = (children) => {
    let newID = getNewId();

    let NewTree = {
      id: newID,
      connection: "partner",
      pids: [currentUserData.id],
      gender: currentUserData.gender == 1 ? 2 : 1,
    };
    let updateTree = [{ id: currentUserData.id, change: { pids: newID } }];

    if (children) {
      children.map((c) => {
        if (c.fid == currentUserData.id) {
          updateTree.push({ id: c.id, change: { mid: newID } });
        } else if (c.mid == currentUserData.id) {
          updateTree.push({ id: c.id, change: { fid: newID } });
        }
      });
    }
    let tempData = checkExistingData(updateTree);

    return [tempData, NewTree];
  };

  const handleAddChild = (spouse) => {
    let newID = getNewId();

    let NewTree = {
      id: newID,
      connection: "children",
    };

    if (currentUserData.gender == 1) {
      NewTree = {
        ...NewTree,
        fid: currentUserData.id,
        mid: spouse?.id || null,
      };
    } else if (currentUserData.gender == 2) {
      NewTree = {
        ...NewTree,
        mid: currentUserData.id,
        fid: spouse?.id || null,
      };
    }
    return [mainData, NewTree];
  };

  const handleAddPet = () => {
    let newID = getNewId();

    let newTree;

    if (currentUserData.gender == 1) {
      newTree = { id: newID, fid: currentUserData.id, connection: "pet" };
    } else {
      newTree = { id: newID, mid: currentUserData.id, connection: "pet" };
    }
    return [mainData, newTree];
  };

  const checkExistingData = (ChangeData) => {
    let tempData = JSON.parse(JSON.stringify(mainData));
    mainData.map((value, index) => {
      let [data] = ChangeData.filter((x) => x.id == value.id);
      if (data) {
        tempData[index] = {
          ...value,
          ...data.change,
          pids: data?.change?.pids
            ? tempData[index]?.pids
              ? [data?.change?.pids, ...tempData?.[index]?.pids]
              : [data?.change?.pids]
            : tempData?.[index]?.pids
            ? tempData?.[index]?.pids
            : [],
        };
      }
    });
    return tempData;
  };
  return {
    relationBuilding,
  };
};

export default useAddRelations;
