import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectedImage } from "../../../../store/slices/GalleryTabSlice";

export const useStaredThumb = () => {
  const dispatch = useDispatch();
  const [onMouseHover, setOnMouseHover] = useState(false);
  const [onLoadState, setOnLoadState] = useState(false);
  const selections = useSelector((state) => state.galleryTab);
  const favorites = useSelector((state) => state?.favorite);

  const handleMouseEnter = () => {
    // if (!sections.favorites?.currentActions?.preselect) {
    setOnMouseHover(true);
    // }
  };

  const handleMouseLeave = () => {
    // if (!sections.favorites?.currentActions?.preselect) {
    setOnMouseHover(false);
    // }
  };

  const handleRemove = (e) => {
    setOnLoadState(true);
  };

  const handleImageSelect = (imageId, index) => {
    if (selections?.ImageProfileTab?.selction == true) {
      dispatch(SelectedImage({ tab: "user-profile", imageId }));
    }
    // else handleOpenImageFullView(imageId, index);
  };

  return {
    favorites,
    handleRemove,
    onMouseHover,
    setOnMouseHover,
    onLoadState,
    setOnLoadState,
    selections,
    handleMouseEnter,
    handleMouseLeave,
    handleImageSelect,
  };
};
