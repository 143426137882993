import React from "react";
import Style from "./btnLoader.module.scss";

export const BtnLoader = () => {
    return (
        <div
            className={`${Style.loader_spinner_wrap} ${Style.noData_loader
                } noData_loader`}
        >
            <div className={Style.loader_spinner}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};
