import { useState, useEffect } from "react";

export const useControls = ({
  swiperRef,
  trackIndex,
  playTrack,
  play,
  setPlay,
  mute,
  setMute,
  setSlideIndex,
}) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [showPlayPause, setShowPlayPause] = useState(false);
  const [slideCounter, setSlideCounter] = useState(0);

  useEffect(() => {
    if ((slideCounter > 0 || slideCounter < 0) && play) {
      const timer = setTimeout(() => {
        // swiperRef?.current?.swiper?.autoplay?.start();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [slideCounter]);

  const slideChangeHandler = (e) => {
    setCurrentIndex(e.realIndex);
    setSlideIndex(e.realIndex);
  };
  const playToggle = () => {
    setPlay(false);
    // setShowPlayPause(false);
    swiperRef.current.swiper.autoplay.stop();
    playTrack(trackIndex);
  };
  const pauseToggle = () => {
    setPlay(true);
    // setShowPlayPause(true);
    swiperRef.current.swiper.autoplay.start();
    playTrack(trackIndex);
  };

  const previousHandler = () => {
    swiperRef.current.swiper.slidePrev();
    setSlideCounter(slideCounter - 1);
  };
  const nextHandler = () => {
    swiperRef.current.swiper.slideNext();
    setSlideCounter(slideCounter + 1);
  };

  const handleInitialization = (e) => {
    if (play) {
      e.autoplay.start();
    }
  };

  const handleMute = () => {
    setMute(!mute);
  };

  return {
    currentIndex,
    showPlayPause,
    slideCounter,
    setShowPlayPause,
    slideChangeHandler,
    playToggle,
    pauseToggle,
    previousHandler,
    nextHandler,
    handleInitialization,
    handleMute,
  };
};
