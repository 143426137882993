import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  inititatePayment,
  closeModal,
  paymentCompletion,
  paymentModalAction,
} from "../../store/slices/checkoutSlice";
import { updateActivePlanAfterPusrchase } from "../../store/slices/userprofileSlice";
import { getAxiosInstance } from "../../api/index";

export const usePlans = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  const { status, data, paymentCompleteStatus } = useSelector(
    (state) => state.payment
  );
  const [selectedId, setSelectedId] = useState(null);
  const [country, setCountry] = useState([]);

  useEffect(() => {
    getCountryDialCodes();
  }, []);

  const getCountryDialCodes = async () => {
    const api = await getAxiosInstance();
    try {
      let data = await api.get("/country-dial-codes");
      setCountry(data?.data?.result);
    } catch (error) {
      setCountry([]);
    }
  };

  const handleInitialtePayment = (item, details) => {
    setSelectedId(item?.id);

    let data = {
      plan_id: item?.id,
      // amount: Number(item?.price),
      code: item?.plans_prices?.[0]?.countries?.code,
      country_dial_code: item?.plans_prices[0]?.countries?.id,
      currency: item.plans_prices[0].countries?.currency_code,
      ...details,
    };

    dispatch(inititatePayment(data));
  };

  const closePaymentModal = () => {
    dispatch(paymentModalAction(false));
  };

  const confirmPaymentAfterSuccess = async (item, type) => {
    let paymentData = {
      order_id: data.details.order_id,
      stripe_id: item?.id,
    };
    const resultAction = await dispatch(paymentCompletion(paymentData));
    if (paymentCompletion.fulfilled.match(resultAction)) {
      dispatch(
        updateActivePlanAfterPusrchase(
          resultAction.payload.data?.result?.activated_plan
        )
      )
      if (type == "error") {
        setTimeout(() => {
          setMessage(null)
        }, 3000);
      }
      if (type == "success") {
        setTimeout(() => {
          // closePaymentModal()
          window.location = "/get-started";
          // navigate("/dashboard");
        }, 1000);

      }



    } else {
      // console.log("hey payment completion failed....");
    }
  };

  return {
    handleInitialtePayment,
    status,
    selectedId,
    closePaymentModal,
    confirmPaymentAfterSuccess,
    paymentCompleteStatus,
    country,
    message,
    setMessage
  };
};
