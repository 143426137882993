import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPlansData,
  getTestimonials,
  getBrowsePhotos,
  getStatus,
  getLanguages,
} from "../store/slices/HomeSlice";

export const useHome = () => {
  const dispatch = useDispatch();
  const { availableLanguage, plansData, testimonialData, browsePhotosData, liveStatusData } =
    useSelector((state) => state.home);

  const plans = () => {
    dispatch(getPlansData());
  };

  const testimonials = () => {
    if (testimonialData == undefined) {
      dispatch(getTestimonials());
    }
  };

  const browsePhotos = () => {
    // dispatch(getBrowsePhotos());
  };

  const liveStatus = () => {
    if (liveStatusData == undefined) {
      dispatch(getStatus());
    }
  };
  return {
    plansData,
    testimonialData,
    browsePhotosData,
    liveStatusData,
    plans,
    testimonials,
    browsePhotos,
    liveStatus,
    availableLanguage
  };
};
