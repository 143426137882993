import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  isLoading: true,
};

export const coverPhotoUpload = createAsyncThunk(
  "userprofile/coverPhotoUpload",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const { photo } = obj;

      const packet = new FormData();
      packet.append("cover_photo", photo);

      const response = await api.post(`update-user-profile`, packet);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const RemoveCoverPhoto = createAsyncThunk(
  "userprofile/RemoveCoverPhoto",
  async (obj, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`remove-cover-photo`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
