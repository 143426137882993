import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DarkThemeToggler from "../../components/DarkThemeToggler";
import Home from "../../components/Home";
import PreLogin from "../../components/Layouts/PreLogin";
import useDarkmode from "../../utils/utilityHooks/useDarkmode";

const HomePage = () => { //FIXME error in window width while resize page
  const location = useLocation();
  const { theme, themeToggler } = useDarkmode();

  useEffect(() => {
    location.pathname === "/" && document.body.classList.add("small-header");
    return () => {
      document.body.classList.remove("small-header");
    };
  }, [location.pathname]);

  return (
    <>
      <PreLogin hasFooter={true}>
        <Home />
      </PreLogin>
      <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
    </>
  );
};

export default HomePage;
