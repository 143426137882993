import React, { useEffect } from "react";
import Icons from "../Layouts/Icons";
import Style from "./DarkThemeToggler.module.scss";
import { useTranslation } from "react-i18next";

const DarkThemeToggler = ({ theme,toggleTheme, className, text }) => {
  const { i18n, t } = useTranslation(["common"]);
  
  return (
    <button
      className={`${Style.darkmode_toggler} ${className} ${"btn_theme_toggle anim dark-toggler-anim btn_darkmode_theme_toggle"}`}
       onClick={()=>toggleTheme()}
    >
      {theme =="dark" ? (
        <div className={`${Style.light_mode} theme-mode theme-light`}>
          <Icons icon={"icon-sun"} size={30} />
          {
            (text !== 'none') ? <span>{t("light")}</span> : ''
          }
        </div>
      ) : (
        <div className={`${Style.dark_mode} theme-mode theme-dark`}>
          {
            (text !== 'none') ? <span>{t("dark")}</span> : ''
          }
          <Icons icon={"icon-moon"} size={26} />
        </div>
      )}
    </button>
  );
};

export default DarkThemeToggler;
