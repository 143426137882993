import { useRef, useEffect, useState } from "react";
import { useDimensions } from "../../Logic/Dimensions";

const useImageListMasonry = ({ loadMore, Images }) => {

  const breakpointColumnsObj = {
    default: 5,
    1599: 4,
    991: 3,
    575: 2,
    374: 1,
  };
  const observer = useRef();
  const imgRootRef = useRef(null);
  const imgItemRef = useRef(null);
  const windowDimensions = useDimensions();
  const [showFullView, setShowFullView] = useState({ index: 0, isOpen: false });

  useEffect(() => {//FIXME loadmore not working 
    if (imgItemRef.current) {
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries.every((entry) => {
            return entry.isIntersecting;
          })
        ) {
          loadMore()
        }
      });
      observer.current.observe(imgItemRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [Images, windowDimensions.width, imgItemRef?.current, imgRootRef?.current]);



  return {

    observer,
    imgRootRef,
    imgItemRef,
    breakpointColumnsObj,
    showFullView,
    setShowFullView
  };
};

export default useImageListMasonry;
