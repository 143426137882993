import { useEffect, useLayoutEffect, useRef, useState } from "react";

export const useControls = ({ data }) => {
  const swiperRef = useRef(null);
  const [slideCount, setSlideCount] = useState(null);
  const [menuVisible, setMenuVisible] = useState(true);
  const [navAreaLeftHover, setNavAreaLeftHover] = useState(false);
  const [navAreaRightHover, setNavAreaRightHover] = useState(false);
  const [show, setShow] = useState(false);
  const [navHover, setNavHover] = useState(false);

  let mouseTimer = null;
  const disableMenu = () => {
    mouseTimer = null;
    navHover ? setMenuVisible(true) : setMenuVisible(false);
  };

  window.addEventListener("mousemove", () => {
    mouseTimer && window.clearTimeout(mouseTimer);
    !menuVisible && setMenuVisible(true);
    mouseTimer = window.setTimeout(disableMenu, 3000);
  });
  window.addEventListener("click", () => {
    mouseTimer && window.clearTimeout(mouseTimer);
    !menuVisible && setMenuVisible(true);
    mouseTimer = window.setTimeout(disableMenu, 3000);
  });

  const previousHandler = () => {
    if (slideCount >= 1) {
      setSlideCount(slideCount - 1);
    }

    swiperRef.current?.swiper?.slidePrev();
  };

  const nextHandler = () => {
    if (slideCount < data?.length - 1) {
      setSlideCount(slideCount + 1);
    }
    swiperRef.current?.swiper?.slideNext();
  };

  const handleInfoClose = () => {
    setShow(false);
  };
  const handleInfoShow = () => {
    setShow(true);
  };

  return {
    swiperRef,
    slideCount,
    menuVisible,
    navAreaLeftHover,
    navAreaRightHover,
    show,
    navHover,
    setNavAreaLeftHover,
    setNavAreaRightHover,
    setShow,
    setMenuVisible,
    setSlideCount,
    previousHandler,
    nextHandler,
    setNavHover,
    handleInfoClose,
    handleInfoShow,
  };
};
