import React from "react";
import Style from "./SelectMother.module.scss";
const SelectMother = (props) => {
  const { data, handleNewNode } = props;
  const userData = data[0].userData;
  const userDataPids = userData[0]?.pids;
  const getAllData = data[0].allData;

  const motherOrFather = getAllData.filter((el) => {
    if (userDataPids) {
      return userDataPids.find((element) => {
        return el.id === element;
      });
    }
  });

  return (
    <div className={Style.root}>
      <div className={Style.innerWrapper}>
        <div className={Style.heading}>
          <h3>Add parents</h3>
        </div>

        <div className={Style.listWrapper}>
          <div className={Style.listItem}>
            {motherOrFather.map((item) => {
              return (
                <button
                  className={Style.item}
                  key={item.id}
                  onClick={() =>
                    handleNewNode({ connection: "children", spouse: item })
                  }
                >
                  <div className={Style.imageWrapper}>
                    <div className={Style.imageContainer}>
                      <img src={item.img} alt="" />
                    </div>
                  </div>
                  <div className={Style.textWrapper}>{item.name}</div>
                </button>
              );
            })}

            {/* <button
              className={Style.item}
              onClick={() => handleNewNode({ connection: "partner" })}
            >
              <div className={Style.imageWrapper}>
                <div className={Style.imageContainer}>
                  <img src={item.img} alt="" />
                </div>
              </div>
              <div className={Style.textWrapper}>New Partner</div>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectMother;
