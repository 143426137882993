
import { useState } from "react";
import Style from "./LoaderSuccessFailure.module.scss";


const LoaderSuccessFailure = ({ status }) => {
  return (
    <>
      <div className={`${Style.circle_loader} ${status === "success" ? Style.load_success : status === "error" ? Style.load_failure : ''} `}>
        <div className={`${Style.circle_loader_checkmark} ${status === "success" ? Style.circle_loader_checkmark_draw : ''}`}></div>
      </div>
    </>
  );
};

export default LoaderSuccessFailure;
