import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  isLoading: true,
  imageData:[]
};

export const getMediaByID = createAsyncThunk(
  "imageviewer/getMediaByID",
  async (media_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`image/${media_id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userPreferenceSlice = createSlice({
  name: "imageviewer",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
  },
  extraReducers: {
    [getMediaByID.pending]: (state) => {
      state.isLoading = true;
    },
    [getMediaByID.fulfilled]: (state, action) => {
      const { default_view } = action.payload.data.result;

      state.imageData = false;
    },
    [getMediaByID.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { showLoading } =
  userPreferenceSlice.actions;

export default userPreferenceSlice.reducer;
