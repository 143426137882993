import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDimensions } from "../Logic/Dimensions";
import { useTranslation } from "react-i18next";
import { logout } from "../store/slices/authSlice";
import { toast } from "react-toastify";

import { changeCurrentTabView } from "../store/slices/gallerySlice";

import {
  getCurrentPreferenceStatus,
  updatePreferenceStatus,
  checkPassowrdForDeleteUserAccount,
  DeleteUserAccount,
  getLanguages,
  updateDefaultView,
  ChangePasswordAPIcall,
  isDefaultViewActive,
  showLang,
  setAvailableLanguage,
} from "../store/slices/preferenceSlice";
import { useHome } from "./useHome";
import { getAxiosInstance } from "../api";
export const usePreference = () => {
  const dispatch = useDispatch();
  const windowDimensions = useDimensions();
  let navigate = useNavigate();
  const { i18n, t } = useTranslation(["common"]);
  const home = useSelector((state) => state.home);

  const {
    sign_me_automatically,
    auto_adjust_positions,
    enable_edit_access,
    availableLanguage,
    selected_language,
    background_theme,
    is_data_loaded_from_api,
    default_view,
  } = useSelector((state) => state.userpreference);

  const errorMessages = {
    1: "Please enter your password.",
    2: "Invalid Password!",
    3: "Something went wrong!",
    4: "Sorry, invalid password!",
    5: "Passwords should not be same as the current one.",
    6: "Password length must be grater than 6",
  };

  const [deleteFormObj, setDeleteFormObj] = useState({
    password: "",
    error: false,
  });

  const [changePasswordFormObj, setChangePasswordFormObjObj] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
    error: false,
    is_button_enabled: false,
    is_password_length_good: false,
  });

  const [view, setView] = useState(default_view);
  const [password, setPassword] = useState("");
  const [themes, setTheme] = useState(window.localStorage.getItem("theme"));
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedOneThree] = useState(false);
  //const [showLang, setShowLang] = useState(false);
  const [languageCategory, setLanguageCategoryCategory] = useState("English");
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [IsPasswordChanging, setIsPasswordChanging] = useState(false);
  const [wasPasswordUpdated, setWasPasswordUpdated] = useState(false);
  //const [isDefaultViewActive, setDefaultViewActive] = useState(false);

  const [isCurrentPassVisible, setIsCurrentPassVisible] = useState(false);
  const [isNewPassVisible, setIsNewPassVisible] = useState(false);
  const [isConfirmPassVisible, setIsConfirmPassVisible] = useState(false);

  const [openClearTreeModal, setOpenClearTreeModal] = useState(false);
  const [isClearingTree, SetIsClearingTree] = useState(false);

  const setDefaultViewActive = (status) => {
    dispatch(isDefaultViewActive(status));
  };

  const setShowLang = (status) => {
    dispatch(showLang(status));
  };

  const handleLanguageClick = () => setShowLang(true);

  const handleChangeCurrentView = (data) => {
    setView(data);
    dispatch(changeCurrentTabView(data));
  };

  const handleSignMeAutomatically = () => {
    dispatch(
      updatePreferenceStatus({
        action: "sign_me_auto",
        value: !checkedOne ? 1 : 0,
      })
    )
      .unwrap()
      .then((res) => {
        const { status } = res?.data;
        if (status && status !== undefined) {
          setCheckedOne(!checkedOne);
        }
      });
  };

  const handleAutoAdjustPositions = () => {
    dispatch(
      updatePreferenceStatus({
        action: "auto_adjust",
        value: !checkedTwo ? 1 : 0,
      })
    )
      .unwrap()
      .then((res) => {
        const { status } = res?.data;
        if (status && status !== undefined) {
          setCheckedTwo(!checkedTwo);
        }
      });
  };

  const handleEnableEditAccess = () => {
    dispatch(
      updatePreferenceStatus({
        action: "enable_edit_access",
        value: !checkedThree ? 1 : 0,
      })
    )
      .unwrap()
      .then((res) => {
        const { status } = res?.data;
        if (status && status !== undefined) {
          setCheckedOneThree(!checkedThree);
        }
      });
  };

  const defaultViewChange = (view) => {
    setView(view);
    dispatch(
      updateDefaultView({
        action: "default_view",
        value: view,
      })
    );
  };

  const PasswordMatchCheck = () => {
    let checkPassLength = changePasswordFormObj?.new_password?.length > 5 && changePasswordFormObj?.confirm_password?.length > 5
    if (
      changePasswordFormObj?.new_password === "" ||
      changePasswordFormObj?.confirm_password === ""
    ) {
      setChangePasswordFormObjObj((state) => {
        return { ...state, is_button_enabled: false };
      });
    }
    if (changePasswordFormObj?.confirm_password != "") {
      if (
        checkPassLength && changePasswordFormObj?.new_password ===
        changePasswordFormObj?.confirm_password
      ) {
        setChangePasswordFormObjObj((state) => {
          return { ...state, is_button_enabled: true };
        });
      } else {
        setChangePasswordFormObjObj((state) => {
          return { ...state, is_button_enabled: false };
        });
      }
    }
  };

  const PasswordLengthcheck = () => {
    if (
      changePasswordFormObj.new_password !== "" &&
      changePasswordFormObj.new_password.length >= 6
    ) {
      setChangePasswordFormObjObj((state) => {
        return { ...state, is_password_length_good: true };
      });
    } else {
      setChangePasswordFormObjObj((state) => {
        return { ...state, is_password_length_good: false };
      });
    }
  };

  const ShowPassword = function (seconds, ele) {
    if (ele.current.type === "password") {
      ele.current.type = "text";
    } else {
      ele.current.type = "password";
    }
  };

  const languageCategorySwitch = (category) => {
    if (languageCategory.name != category.name) {
      setLanguageCategoryCategory(category);
      dispatch(
        updatePreferenceStatus({
          action: "language_id",
          value: category.id,
        })
      );

      if (category.locale != "") {
        i18n.changeLanguage(category.locale);
      }
    }
  };

  const ChangeTheme = (event) => {
    dispatch(
      updatePreferenceStatus({
        action: "background_theme_id",
        value: themes == "light" ? 1 : 2, // (1 dark mode) (2 normal mode)
      })
    )
      .unwrap()
      .then((res) => {
        // setTheme(event.target.value)
      });
  };

  const ChangePasswordSubmit = () => {
    setIsPasswordChanging(true);

    dispatch(
      ChangePasswordAPIcall({
        old_password: changePasswordFormObj.old_password,
        new_password: changePasswordFormObj.new_password,
      })
    )
      .unwrap()
      .then((res) => {
        // Password matched condition & calling final delete API
        const { success, code } = res?.data;

        if (success === true) {
          //dispatch(logOut());
          setIsPasswordChanging(false);
          setWasPasswordUpdated(true);

          setChangePasswordFormObjObj((state, props) => {
            return {
              ...state,
              old_password: "",
              new_password: "",
              confirm_password: "",
            };
          });

          // Delete api call failed condition
        } else if (success === false && code == 401) {
          setIsPasswordChanging(false);
          // Password mismatch condition & throwing error
          setChangePasswordFormObjObj((state, props) => {
            return { ...state, error: true, error_code: 5, code: code };
          });
        } else if (success === false && code == 400) {
          setIsPasswordChanging(false);
          // Password mismatch condition & throwing error
          setChangePasswordFormObjObj((state, props) => {
            return { ...state, error: true, error_code: 4, code: code };
          });
        }
      })
      .catch((error) => {
        setIsPasswordChanging(false);
        setChangePasswordFormObjObj((state, props) => {
          return { ...state, error: true, error_code: 3 };
        });
      });
  };

  const LoginAgain = () => {
    dispatch(logout());
    navigate("/");
  };

  const deleteActionConfirmed = () => {
    // Password Validation

    if (deleteFormObj.password === "") {
      setDeleteFormObj({ ...deleteFormObj, error: true, error_code: 1 });
      return false;
    } else {
      setDeleteFormObj({ ...deleteFormObj, error: false, error_code: "" });
    }

    // Dispatching the API call for the verification of password

    dispatch(
      checkPassowrdForDeleteUserAccount({
        password: deleteFormObj.password,
      })
    )
      .unwrap()
      .then((res) => {
        // Password matched condition & calling final delete API
        const { success } = res?.data;

        if (success === true) {
          dispatch(DeleteUserAccount())
            .unwrap()
            .then((res) => {
              // Delete api call success condition
              const { success } = res?.data;
              if (success === true) {
                dispatch(logout());
                navigate("/");
              }
            })
            .catch((err) => {
              // Delete api call failed condition
              setDeleteFormObj((state, props) => {
                return { ...state, error: true, error_code: 3 };
              });
            });
        } else {
          // Password mismatch condition & throwing error
          setDeleteFormObj((state, props) => {
            return { ...state, error: true, error_code: 2 };
          });
        }
      })
      .catch((error) => {
        setDeleteFormObj((state, props) => {
          return { ...state, error: true, error_code: 3 };
        });
      });
  };

  useEffect(() => {
    // if (!is_data_loaded_from_api) {
    dispatch(getCurrentPreferenceStatus())
      .unwrap()
      .then((res) => {
        const {
          auto_adjust,
          background,
          enable_edit_access,
          language,
          sign_me_auto,
          default_view,
        } = res.data.result;

        setView(default_view);
        setLanguageCategoryCategory(language);
        setCheckedOne(sign_me_auto == 1 ? true : false);
        setCheckedTwo(auto_adjust == 1 ? true : false);
        setCheckedOneThree(enable_edit_access == 1 ? true : false);
      });

    // else {
    //   setLanguageCategoryCategory(selected_language);
    //   setCheckedOne(sign_me_automatically == 1 ? true : false);
    //   setCheckedTwo(auto_adjust_positions == 1 ? true : false);
    //   setCheckedOneThree(enable_edit_access == 1 ? true : false);
    // }
  }, []);

  const triggeruseEffect = () => {
    if (availableLanguage.length === 0 && home.availableLanguage.length === 0) {
      dispatch(getLanguages());
    } else {
      dispatch(setAvailableLanguage(home.availableLanguage));
    }
  };

  useEffect(() => {
    // Checking Password is matched or not
    PasswordMatchCheck();
    PasswordLengthcheck();
  }, [
    changePasswordFormObj.confirm_password,
    changePasswordFormObj.new_password,
  ]);

  const capitalizeFirstLetter = function (str) {
    // converting first letter to uppercase
    if (str != "" && str != undefined && str != null) {
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
      return capitalized;
    } else {
      return;
    }
  };

  const clearTree = async () => {
    SetIsClearingTree(true);
    let api = await getAxiosInstance();
    let newData = await api.get("/delete-tree");
    if (newData?.status == 200) {
      setTimeout(() => {
        SetIsClearingTree(false);
        setOpenClearTreeModal(false);
        toast.success("Tree Cleared Success !", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }, 1000);
    } else {
      SetIsClearingTree(false);
      // setOpenClearTreeModal(false);
    }
  };

  const handleRemovePasswordModal = () => {
    setWasPasswordUpdated(false)
    setChangePasswordModal(!changePasswordModal)
    setChangePasswordFormObjObj({ old_password: "",
    new_password: "",
    confirm_password: "",
    error: false,
    is_button_enabled: false,
    is_password_length_good: false,})

  }

  return {
    view,
    setView,
    deleteFormObj,
    password,
    themes,
    availableLanguage,
    languageCategory,
    checkedOne,
    checkedTwo,
    checkedThree,
    windowDimensions,
    showLang,
    errorMessages,
    changePasswordModal,
    changePasswordFormObj,
    wasPasswordUpdated,
    isDefaultViewActive,
    setDefaultViewActive,
    setChangePasswordFormObjObj,
    setDeleteFormObj,
    setTheme,
    setView,
    setPassword,
    setShowLang,
    deleteAccountModal,
    setDeleteAccountModal,
    setChangePasswordModal,
    handleLanguageClick,
    handleSignMeAutomatically,
    handleAutoAdjustPositions,
    handleEnableEditAccess,
    languageCategorySwitch,
    ChangeTheme,
    deleteActionConfirmed,
    capitalizeFirstLetter,
    defaultViewChange,
    PasswordMatchCheck,
    ShowPassword,
    ChangePasswordSubmit,
    IsPasswordChanging,
    LoginAgain,
    handleChangeCurrentView,
    default_view,
    triggeruseEffect,
    isCurrentPassVisible,
    setIsCurrentPassVisible,
    isNewPassVisible,
    setIsNewPassVisible,
    isConfirmPassVisible,
    setIsConfirmPassVisible,
    openClearTreeModal,
    setOpenClearTreeModal,
    clearTree,
    isClearingTree,
    SetIsClearingTree,
    handleRemovePasswordModal
  };
};
