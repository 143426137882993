import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useStopWindowScroll } from "../../../Logic/useStopWindowScroll";

const AuthenticationModal = ({ showModal, setShowModal, children, className }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);


  const stopScroll = useStopWindowScroll();
  useEffect(() => {
    stopScroll(isModalVisible);
  }, [isModalVisible]);


  return (
    <Modal
      show={showModal}
      onHide={setShowModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className={className}
      onExit={() => setIsModalVisible(false)}
      onEnter={() => setIsModalVisible(true)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">

        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  )

}

export default AuthenticationModal