function UploadImageCrop(imageObj,callback) {
    this.image = null;
    this.ImgWidth = null;
    this.ImgHeight = null;
    this.imageObject = imageObj;
    this.imageSrc = "";
    this.callback = callback;
    this.LoadImageUsingReader = (ImgURL)=>{
      return new Promise(
        function (resolve, reject) {
  
          var reader = new FileReader();
          reader.addEventListener("loadend", function (arg) {
            resolve({
              src:this.result
            });
          });
  
          reader.readAsDataURL(ImgURL)
        }
      )
    }
  
  
    this.createCanvas = function () {
      //var dpr = window.devicePixelRatio || 1;
      return new Promise((resolve, reject) => {
        this.canvas = document.createElement("canvas");
  
        this.canvas.width = this.image.width;
        this.canvas.height = this.image.height;
  
        this.canvas.setAttribute(
          "style",
          "object-fit: contain;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%); width: 100% !important"
        );
  
        this.canvas.getContext("2d").drawImage(this.image, 0, 0);
  
        if (this.image === null || this.image === undefined) {
          reject(false);
        }
  
        this.sourceImageData = this.canvas.getContext("2d").getImageData(
          0,
          0,
          this.ImgWidth,
          this.ImgHeight
        );
  
        this.outputImageData = this.canvas.getContext("2d").createImageData(
          this.ImgWidth,
          this.ImgHeight
        );
  
        if (this.ImageData == null) {
          this.ImageData = this.canvas.getContext("2d").getImageData(
            0,
            0,
            this.ImgWidth,
            this.ImgHeight
          )
        }
        resolve(true);
      });
    };
   
    this.setCropConfig = function (cropConfig) {
      this.cropConfig = cropConfig;
    };
  
    this.setCropRerImage = function (img) {
      this.thubnailObj = img;
    };
  
    this.getCroppedImage = function (cropConfig) {
      return new Promise((resolve, reject) => {
        // creating the cropped image from the source image
  
        try {
          const scaleX = this.image.width / this.thubnailObj.current.width; // 749
          const scaleY = this.image.height / this.thubnailObj.current.height; // 750
  
          this.canvas.width = this.cropConfig.width * scaleX;
          this.canvas.height = this.cropConfig.height * scaleX;
  
          this.canvas.getContext("2d").drawImage(
            this.image,
            this.cropConfig.x * scaleX,
            this.cropConfig.y * scaleY,
            this.cropConfig.width * scaleX,
            this.cropConfig.height * scaleY,
            0,
            0,
            this.cropConfig.width * scaleX,
            this.cropConfig.height * scaleY
          );
  
          this.sourceImageData = this.canvas.getContext("2d").getImageData(
            0,
            0,
            this.canvas.width * scaleX,
            this.canvas.height * scaleX
          );
  
          this.CroppedImageData =  this.canvas.getContext("2d").getImageData(
            0,
            0,
            this.canvas.width * scaleX,
            this.canvas.height * scaleX
          );
  
          resolve(true);
        } catch (error) {
          // console.log("error", error);
        }
      });
    };
  
    this.loadImage= (ImgURL) => {
      return new Promise(
        function (resolve, reject) {
          this.image = new Image();
          this.image.addEventListener(
            "load",
            function () {
              resolve({
                msg: "Image is ready",
                width: this.width,
                height: this.height,
              });
            },
            false
          );
          this.image.addEventListener(
            "error",
            function (error) {
              reject("Image loading failed!");
            },
            false
          );
          this.image.crossOrigin = "anonymous";
          this.image.setAttribute("src", ImgURL);
        }.bind(this)
      );
    };
  
    this.LoadImageUsingReader(this.imageObject).then(function (data) {
      this.imageSrc = data.src;
      this.loadImage(this.imageSrc).then(
        function (data) {
          this.ready = true;
          this.ImgWidth = data.width;
          this.ImgHeight = data.height;
          this.createCanvas();
          this.callback(this.imageSrc);
        }.bind(this)
      )
      .catch(
        function (error) {
          // console.log(error);
        }.bind(this)
      );
    }.bind(this))

    this.getImageURL = function () {
      const link = this.canvas.toDataURL("image/jpeg", 1);
      var newWindow = window.open();
          newWindow.document.write('<img style="height: 100%" src="' + link + '" />');
    };

    this.getImageBlob = function () {
   
      return new Promise(function(resolve, reject) {
  
        this.canvas.toBlob(function(blob){
          resolve(blob);
        },"image/jpeg", 1);
  
      }.bind(this));
  
    };
  
  }

  export default UploadImageCrop;