import React from 'react';
import { useState } from 'react';
import Icons from '../Layouts/Icons';
import Style from './KeyboardShortcutsList.module.scss';
import PreferenceInnerHeader from '../PreferenceInnerHeader';

const KeyboardShortcutsList = () => {
    const [isDeviceMac, setIsDeviceMac] = useState(true);

    return (
        <div className={Style.keyboard_shortcuts}>
            <PreferenceInnerHeader
                title={'Keyboard Shortcuts'}
                navigatePath={'/dashboard/preference'}
            />
            <div className={Style.keyboard_shortcuts_contents}>
                <ul className={Style.keyboard_shortcuts_list}>
                    {
                        shortcutsData.map((data) => {
                            return (
                                <li key={data.id}>
                                    <h5 className={Style.keyboard_shortcuts_description}>{data.description}</h5>
                                    {isDeviceMac ? (
                                        <ul className={Style.keyboard_shortcuts_keys}>
                                            {data.mac_keys.map((key, i) => {
                                                return (
                                                    <li className={Style.key} key={i}>
                                                        {
                                                            key === 'shift' ? (
                                                                <Icons
                                                                    icon={'key-shift'}
                                                                    size={16}
                                                                />
                                                            ) : key === 'cmd' ? (
                                                                <Icons
                                                                    icon={'key-command'}
                                                                    size={16}
                                                                />
                                                            ) : key === 'key-right' ? (
                                                                <Icons
                                                                    icon={'arrow-right2'}
                                                                    size={16}
                                                                />
                                                            ) : key === 'key-left' ? (
                                                                <Icons
                                                                    icon={'arrow-left2'}
                                                                    size={16}
                                                                />
                                                            ) : key
                                                        }
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    ) : (
                                        <ul className={Style.keyboard_shortcuts_keys}>
                                            {data.win_keys.map((key, i) => {
                                                return (
                                                    <li className={Style.key} key={i}>
                                                        {
                                                            key === 'key-right' ? (
                                                                <Icons
                                                                    icon={'arrow-right2'}
                                                                    size={16}
                                                                />
                                                            ) : key === 'key-left' ? (
                                                                <Icons
                                                                    icon={'arrow-left2'}
                                                                    size={16}
                                                                />
                                                            ) : key
                                                        }
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    )}
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default KeyboardShortcutsList;

const shortcutsData = [
    {
        id: 1,
        description: 'Toggle Selection',
        mac_keys: ['shift', 'S'],
        win_keys: ['Shift', 'S']
    },
    {
        id: 2,
        description: 'Select All',
        mac_keys: ['shift', 'A'],
        win_keys: ['Shift', 'A']
    },
    {
        id: 3,
        description: 'Deselect All',
        mac_keys: ['shift', 'X'],
        win_keys: ['Shift', 'X']
    },
    {
        id: 4,
        description: 'Close',
        mac_keys: ['Esc'],
        win_keys: ['Esc']
    },
    {
        id: 5,
        description: 'Next Slide',
        mac_keys: ['key-right'],
        win_keys: ['key-right']
    },
    {
        id: 6,
        description: 'Previous Slide',
        mac_keys: ['key-left'],
        win_keys: ['key-left']
    },
]