import Style from "./MyRequests.module.scss";
import { useDimensions } from "../../Logic/Dimensions";
import Icons from "../Layouts/Icons";



export const MyRequests = ({name, image, date}) => {

  const windowDimensions = useDimensions();

  return (
    <div className={Style.requests}>
      <div>
        <figure className={`${Style.requests_figure} mb-0`}>
          <img src={image} alt="profile-img" />
        </figure>
      </div>
      <div className={Style.requests_text}>
        <div className={Style.requests_p}>
          <strong className={Style.requests_user_name}>{name}</strong> sent an invitation to link his family tree with yours.
        </div>
        <div className={Style.requests_time}>{date}</div>
      </div>
      
      {(windowDimensions.width >= 992) ? (
          <>
          <div className={Style.requests_buttoncontainer}>
            <div className={Style.requests_buttoncancel}>
              <button className="btn btn-sm btn-outline-grey">Cancel</button>
            </div>
          </div>
          </>
        ) : (
          <>
            <div className={Style.requests_buttoncontainer_mob}>
              <div className={Style.requests_buttoncancel}>
                <button className="btn btn-sm btn-outline-grey">
                  <Icons icon={"icon-close"} size={11} />
                </button>
              </div>
            </div>          
          </>
      )}
    </div>
  )
};
