import Assets from "../components/Layouts/Assets";

const Advertisements = [
    {
        id: "adv1",
        image: Assets.ext_link_1,
        url: "familyphotosalbum.com"
    },
    {
        id: "adv2",
        image: Assets.ext_link_2,
        url: "familyphotosalbum.com"
    },
    {
        id: "adv3",
        image: Assets.ext_link_3,
        url: "familyphotosalbum.com"
    },
    {
        id: "adv4",
        image: Assets.ext_link_4,
        url: "familyphotosalbum.com"
    },
    {
        id: "adv5",
        image: Assets.ext_link_5,
        url: "familyphotosalbum.com"
    }
]

export default Advertisements;