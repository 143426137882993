import { useDispatch, useSelector } from "react-redux";

export const usePurchasedChecker = () => {
  const dispatch = useDispatch();
  const { userdata } = useSelector((state) => state.userprofile);

  // let planItem = userdata?.activated_plan ? userdata?.activated_plan : []
  let isPlanActive = userdata?.activated_plan?.length == 0 ? false : true;
  let planData = userdata?.activated_plan;

  return {
    planData,
    isPlanActive,
  };
};
