import Style from "./HomeMemoriesSlide.module.scss";
import Assets from "../../Layouts/Assets";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HomeMemoriesSlide = () => {
    return (
        <>
        <div className={Style.home_memories}>
        <div className={`${Style.home_memories_column} ${Style.memory_group} slider-column`}>
            <div className={Style.column_top}>
              <div className={Style.column_top_left}>
                <figure className={`${Style.home_memories_image} ${Style.aspect_1} anim load-anim`}>
                  <LazyLoadImage src={Assets.memory_1} alt="memory_1" />
                </figure>
                <figure className={`${Style.home_memories_image} ${Style.aspect_2} anim load-anim`}>
                  <LazyLoadImage src={Assets.memory_2} alt="memory_2" />
                </figure>
              </div>
              <div className={Style.column_top_right}>
                <figure className={`${Style.home_memories_image} ${Style.aspect_3} anim load-anim`}>
                  <LazyLoadImage src={Assets.memory_3} alt="memory_3" />
                </figure>
              </div>
            </div>
            <div className={Style.column_bottom}>
              <figure className={`${Style.home_memories_image} ${Style.aspect_4} anim load-anim`}>
                <LazyLoadImage src={Assets.memory_4} alt="memory_4" />
              </figure>
            </div>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_6} anim load-anim`}>
              <LazyLoadImage src={Assets.memory_6} alt="memory_6" />
            </figure>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_7} anim load-anim`}>
              <LazyLoadImage src={Assets.memory_7} alt="memory_7" />
            </figure>
            <figure className={`${Style.home_memories_image} ${Style.aspect_8} anim load-anim`}>
              <LazyLoadImage src={Assets.memory_8} alt="memory_8" />
            </figure>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_9} anim load-anim`}>
              <LazyLoadImage src={Assets.memory_9} alt="memory_9" />
            </figure>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_8} anim load-anim`}>
              <LazyLoadImage src={Assets.memory_10} alt="memory_10" />
            </figure>
            <figure className={`${Style.home_memories_image} ${Style.aspect_7} anim load-anim`}>
              <LazyLoadImage src={Assets.memory_11} alt="memory_11" />
            </figure>
          </div>

          <div className={`${Style.home_memories_column} slider-column`}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_6} anim load-anim`}>
              <LazyLoadImage src={Assets.memory_6} alt="memory_6" />
            </figure>
          </div>

          </div>
        
        </>
    );
};
export default HomeMemoriesSlide;