import { useEffect } from "react";
import { useDispatch, useSelector, } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAchiveList, LoadmoreImages } from "../../store/slices/Archive/archiveSlice";
import { endTabSession } from "../../store/slices/GalleryTabSlice";

export const useArchive = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const Archive = useSelector((state) => state?.archive);
    const selections = useSelector((state) => state.galleryTab)

    let currentPath = location.pathname
    .replace("/dashboard", "")
    .replaceAll("/", "");

    useEffect(() => {
        if (Archive?.status == 'idle') {
            let obj = {
                page: "1",
                slug: selections?.ImageArchiveTab?.sort,
            };
            dispatch(getAchiveList(obj));
        }
 
        return () => {
          dispatch(endTabSession({ tab: currentPath }))
        }
    }, [])



    const loadMore = (slug, type) => {
        if (Archive?.resultData?.current_page < Archive?.resultData?.last_page) {
            let obj = {
                page: Archive?.resultData?.current_page + 1,
                slug: selections?.ImageArchiveTab?.sort

            };
            dispatch(LoadmoreImages(obj));
        }
    };

    const NodataContent = {
        title: `You haven’t added any photos`,
        description: `Upload your photos now to bring your family story to life.`,
        // buttonText: `Upload Photos`
    }



    return {
        Archive,
        loadMore,
        NodataContent,
        status: Archive?.status
    }
}