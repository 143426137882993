import React from 'react'
import useDarkmode from '../../../utils/utilityHooks/useDarkmode';
import PreLogin from "../../../components/Layouts/PreLogin";

import DarkThemeToggler from '../../DarkThemeToggler';
import ProfileContainer from './ProfileContainer';
import { useParams } from 'react-router';
import { useShareProfile } from './useShareProfile';
import NoDataContainer from './NoDataContainer';

const ShareUserProfile = () => {
    const { theme, themeToggler } = useDarkmode();

    const { data, urlExpired } = useShareProfile()


    return (
        <div>
            <PreLogin hasFooter={true}>
                {urlExpired && Object?.keys(data)?.length == 0 ?

                    <NoDataContainer />
                    :
                    <ProfileContainer data={data} />

                }


            </PreLogin >
            <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
        </div>
    )
}

export default ShareUserProfile