import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Style from "./DemoVideo.module.scss";
import Assets from "../Layouts/Assets";
import { Link, useLocation, useParams } from "react-router-dom";
import HomeChooseAPlan from "../HomeChooseAPlan";
import VideoPlayer from "../../utils/VideoPlayer";
import Icons from "../../components/Layouts/Icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import demoData  from "../../utils/DemoVideosLinks/index";

const DemoVideoPage = () => {
  let { demoslug } = useParams();

  const [demoItem, setDemoItem] = useState({});
  const [play, setPlay] = useState(false);

  const { registerData } = useSelector((state) => state.auth);

  const { t } = useTranslation(["intro"]);

  const location = useLocation();

  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("visible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [location.pathname]);

  useEffect(() => {
    let data = demoData.filter((item) => item.type == demoslug);
    setDemoItem(data[0]);
  }, [demoslug]);

  return (
    <>
      <div className={Style.get_started}>
        <div className={Style.anim_elements}>
          <figure
            className={`${Style.anim_elem} ${Style.circle_yellow} anim_element anim`}
          >
            <img
              src={Assets.get_started_intro_circle_yellow}
              alt="circle_yellow"
            />
          </figure>
          <figure
            className={`${Style.anim_elem} ${Style.donut_grey} anim_element anim`}
          >
            <img src={Assets.get_started_intro_donut_grey} alt="donut_grey" />
          </figure>
          <figure
            className={`${Style.anim_elem} ${Style.donut_yellow} anim_element anim`}
          >
            <img
              src={Assets.get_started_intro_donut_yellow}
              alt="donut_yellow"
            />
          </figure>
          <figure
            className={`${Style.anim_elem} ${Style.donut_green} anim_element anim`}
          >
            <img src={Assets.get_started_intro_donut_green} alt="donut_green" />
          </figure>
        </div>
        <div className="container">
          <div className={Style.get_started_wrapper}>
            <div className="anim load-anim">
              <VideoPlayer
                play={play}
                setPlay={setPlay}
                vidUrl={demoItem?.url}
                autoplay={true}
                loop={true}
              />
            </div>
            <h1 className={`${Style.get_started_title} anim load-anim`}>
              {demoItem?.name}
            </h1>
            <p className={`${Style.get_started_description} anim load-anim`}>
              {demoItem?.description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoVideoPage;

