import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteSlideShowItems,
  removeSlideShowFromState,
} from "../../../../../store/slices/slideshow/slideshowSlice";

export const useDeleteSlideShowModal = ({ setDeleteModal }) => {
  const dispatch = useDispatch();
  const slideShow = useSelector((state) => state.galleryTab.ImageSlideshowTab);

  const handleDelete = async () => {
    let slideshow_id = slideShow?.selectedImage;

    const resultAction = await dispatch(deleteSlideShowItems({ slideshow_id }));
    if (deleteSlideShowItems.fulfilled.match(resultAction)) {
      dispatch(removeSlideShowFromState(slideshow_id));
      toast.success("SlideShow deleted !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setDeleteModal({ popup: false });
    }
  };

  return {
    slideShow,
    handleDelete,
  };
};
