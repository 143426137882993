import React from "react";
import Style from "./InfiniteDotLoader.module.scss";

const InfiniteDotLoader = () => {
  return (
    <div className={Style.InfiniteDotLoader}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default InfiniteDotLoader;
