import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRegisterData, loginCreateOtp } from "../../store/slices/authSlice";

export const useResendOtp = () => {
  const dispatch = useDispatch();
  const [otpCounter, setOtpCounter] = useState(59);
  const [otpResponse, setOtpResponse] = useState({
    message: "",
    success: null,
  });

  const { authData, currentAuthData } = useSelector((state) => state.auth);
  const [isResend, setIsResend]=useState(false)

  const resendOtp = () => {
    if (authData.pageFrom == "register") {
      resendRegisterOtp(authData);
      setIsResend(true)
    } else {
      resendLoginOtp();
      setIsResend(true)
    }
  };

  const resendRegisterOtp = async (data) => {
    const resultAction = await dispatch(getRegisterData(data));
    if (getRegisterData.fulfilled.match(resultAction)) {
      setOtpCounter(59);
      setOtpResponse("OTP Resend Success");
      setTimeout(() => {
        setOtpResponse({});
      }, 1500);
    } else {
      if (resultAction.payload) {
        setOtpCounter(59);

        setOtpResponse("Failed");
        setTimeout(() => {
          setOtpResponse({});
        }, 1500);
      }
    }
  };

  const resendLoginOtp = async () => {
    let data = {
      country_code: currentAuthData.country_code,
      country_dial_code: currentAuthData.country_dial_code,
      phone_number: currentAuthData?.phone_number,
    };
    const resultAction = await dispatch(loginCreateOtp(data));
    if (loginCreateOtp.fulfilled.match(resultAction)) {
      setOtpCounter(59);
      setOtpResponse(resultAction?.payload?.data);
      setTimeout(() => {
        setOtpResponse({});
      }, 1500);
    } else {
      if (resultAction.payload) {
        setOtpCounter(59);

        setOtpResponse(resultAction.payload);
        setTimeout(() => {
          setOtpResponse({});
        }, 1500);
      }
    }
  };

  return {
    resendOtp,
    otpCounter,
    setOtpCounter,
    otpResponse,
    setOtpResponse,
    isResend
  };
};
