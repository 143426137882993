import React from 'react'
import ImageListMasonry from '../../../ImageListMasonry'
import Style from "./AlbumImage.module.scss"
const AlbumImageContainer = ({ data }) => {
    return (

        <>
            <div className={Style.albumDetails}>
                <div className={Style.info}>
                    <h2>{data?.album?.title}
                        <span >
                            {`${data?.images?.length ? data?.images?.length : 0}`}
                        </span>
                    </h2>
                    <p>{data?.album?.description} </p>
                </div>

            </div>
            <div style={{ padding: "10px" }}>
                <ImageListMasonry
                    total={data?.images?.length}
                    Images={data?.images}
                    loadMore={{}}
                    NodataContent={{}}
                    options={[]}
                    moveListItem={{}}
                    albumData={data?.album}
                    frames={[]}
                    mode="album"
                    disableControls={true}
                    isShare={true}
                />
            </div>
        </>
    )
}

export default AlbumImageContainer