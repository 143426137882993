import CommonModal from "../../../../Modals/commonModal";
import Style from "./DeleteImageModal.module.scss";
import { useDeleteImageModal } from "./useDeleteImageModal";

const DeleteImageModal = ({ deleteModal, setDeleteModal }) => {
  const { selectedImage, handleDelete, status } = useDeleteImageModal({
    setDeleteModal,
  });


  const DeleteModalContent = () => {
    return (
      <>
        <h3 className={Style.delete_title}>{`Delete ${selectedImage?.selectedImage?.length > 1 ? "Photos" : "Photo"
          }`}</h3>
        <p className={Style.delete_content}>
          {`Are you sure you want to delete ${selectedImage?.selectedImage?.length
            } 
                ${selectedImage?.selectedImage?.length > 1 ? "Photos" : "Photo"
            // currentTab == "album"
            // ? "album"
            // : `${
            //     sections?.[currentTab]?.selectedImages?.length > 1
            //         ? "photos"
            //         : "photo"//FIXME delete album
            //     }`
            }
                ?`}
        </p>
        <div className={Style.delete_btn_wrap}>
          <button className={`${status == "loading" && Style.btn_loader}  btn-danger`} onClick={() => handleDelete()}  >
            {status !== "loading" && 'Continue'}
          </button>
          <button
            className="btn-outline-gray"
            onClick={() => setDeleteModal({ popup: false })}
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  return (
    <CommonModal
      showModal={deleteModal.popup}
      hide={() => setDeleteModal({ popup: false })}
      className={Style.delete_this_photo_modal}
      title={""}
      content={<DeleteModalContent />}
    />
  );
};

export default DeleteImageModal;
