import React from "react";
import { useDimensions } from "../../../Logic/Dimensions";
import { useLogin } from "../../../Logic/useLogin";
import { useRegister } from "../../../Logic/useRegister";
import Footer from "./Footer";
import PreLoginMenu from "./PreLoginMenu";
import PreLoginMobileMenu from "./PreLoginMobileMenu";

const PreLogin = ({ children, hasFooter }) => {
  const windowDimensions = useDimensions();
  const { showRegister, setShowRegister, showSignIn, setShowSignIn } =
    useRegister();
  const { showForgotPassword } = useLogin();

  return (
    <>
      {windowDimensions.width > 1280 ? (
        <PreLoginMenu
          setShowSignIn={setShowSignIn}
          setShowRegister={setShowRegister}
          showSignIn={showSignIn}
          showRegister={showRegister}
          showForgotPassword={showForgotPassword}
        />
      ) : (
        <PreLoginMobileMenu
          setShowSignIn={setShowSignIn}
          setShowRegister={setShowRegister}
          showSignIn={showSignIn}
          showRegister={showRegister}
          showForgotPassword={showForgotPassword}
        />
      )}
      {children}

      {hasFooter && <Footer />}
    </>
  );
};

export default PreLogin;
