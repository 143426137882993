import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getGalleryImages,
  LoadmoreImages,
  updateAllPageStatus,
} from "../../store/slices/GalleryImagesSlice";
import { endTabSession } from "../../store/slices/GalleryTabSlice";

export const useGalleryTab = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const Images = useSelector((state) => state?.galleryAllTab);
  const selections = useSelector((state) => state.galleryTab);
  let currentPath = location.pathname
    .replace("/dashboard", "")
    .replaceAll("/", "");


  useEffect(() => {
    if (Images?.status == "idle") {
      let obj = {
        page: "1",
        slug: selections?.ImageAllTabSelection?.sort == "captured" ? "" : selections?.ImageAllTabSelection?.sort,
        param: selections?.ImageAllTabSelection?.sort == "captured" ? selections?.ImageAllTabSelection?.sort : ""
      };
      getAllImages(obj);
    }
    return () => {
      dispatch(endTabSession({ tab: currentPath }));
    };
  }, []);

  const getAllImages = async (obj) => {
    const resultAction = await dispatch(getGalleryImages(obj));
    if (getGalleryImages.fulfilled.match(resultAction)) {
      dispatch(updateAllPageStatus("loading"));
      loadMore(resultAction?.payload?.data?.result);
    }
  };

  const loadMore = async (Images) => {
    if (Images?.current_page < Images?.last_page) {
      let obj = {
        page: Images?.current_page + 1,
        slug: selections?.ImageAllTabSelection?.sort == "captured" ? "" : selections?.ImageAllTabSelection?.sort,
        param: selections?.ImageAllTabSelection?.sort == "captured" ? "captured" : ""
      };
      const resultAction = await dispatch(LoadmoreImages(obj));
      if (LoadmoreImages.fulfilled.match(resultAction)) {
        loadMore(resultAction?.payload?.data?.result);
      }
    } else if (Images?.current_page === Images?.last_page) {
      dispatch(updateAllPageStatus("succeded"));
    }
  };

  const NodataContent = {
    title: `You haven’t added any photos`,
    description: `Upload your photos now to bring your family story to life.`,
    buttonText: `Upload Photos`,
  };

  return {
    Images,
    // loadMore,
    NodataContent,
    status: Images?.status,
  };
};
