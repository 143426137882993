import { useEffect } from "react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";


export const useRightClickModal = ({ setAddToAlbum, setDeleteModal, setOpenShare }) => {
    const selectedImage = useSelector((state) => state.galleryTab.ImageAllTabSelection)


    const handleShare = () => {
        setOpenShare(true)
    }

    const handleAddToAlbum = () => {
        setAddToAlbum({ popup: true, type: "add" })

    }

    const handleAddToFavorite = () => {

    }

    const handleDelete = () => {
        setDeleteModal({
            open: true,
            imageId: selectedImage?.selectedImage
        })
    }


    return {
        handleShare, handleAddToAlbum, handleAddToFavorite, handleDelete
    }
}