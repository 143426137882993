import React, { useState } from "react";
import Style from "./HomeBanner.module.scss";
import Assets from "../Layouts/Assets";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDimensions } from "../../Logic/Dimensions";
import { useSelector } from "react-redux";
import SearchBar from "../SearchBar";
import { useParams } from "react-router-dom";
import { useFullscreenPreviewActions } from "../Gallery/FullScreenPreviewNew/useFullScreenPreviewActions";
import { useQuickSlideShowActions } from "../Slideshow/QuickSlideShow/useQuickSlideShowActions";
import FullscreenPreviewNew from "../Gallery/FullScreenPreviewNew";
import QuickSlideShow1 from "../Slideshow/QuickSlideShow1";

const HomeBanner = ({ darkModeIcon, setDarkModeIcon }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  // const [bgLoaded, setBgLoaded] = useState(false);
  const windowDimensions = useDimensions();
  const { query, option } = useParams();

  const { registerData, showSignInComponent, showForgotComponent } =
    useSelector((state) => state.auth);

  const { t } = useTranslation();

  const handleClickToScroll = () => {
    const element = document.querySelector(".section_browse_photos");
    const offset = 63;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const { results, isLoading } = useSelector((state) => state.search);

  const { showFullView, setShowFullView, openPreview } =
    useFullscreenPreviewActions();

  const {
    quickSlideShow,
    selectedImageForSlideShow,
    openQuickSlideShow,
    closeQuickSlideShow,
  } = useQuickSlideShowActions(showFullView, setShowFullView);

  let previewData = {
    images: results,
    pathFrom: "searchlist",
    section: "all",
  };

  let slideData = {
    images: results,
    effect: "zoom-out",
    music: quickSlideShow.musicList,
  };

  return (
    <div className={`${Style.home_banner} banner-video`}>
      {windowDimensions.width < 768 && (
        <div className={Style.banner_gradient}></div>
      )}
      <video
        autoPlay
        muted
        loop
        playsInline
        onPlay={() => setVideoLoaded(true)}
        className={videoLoaded ? Style.loaded : ""}
        src={
          windowDimensions.width >= 576
            ? Assets.homeBannerVideo
            : Assets.homeBannerVideoMob
        }
      ></video>
      <Container className="h-100">
        <div className={Style.home_banner_content}>
          <h1 className={`${Style.home_banner_title} anim banner-anim`}>
            {t("homebanner_title_1") ? t("homebanner_title_1") : "hey"}
            <span className={Style.home_banner_title_bold}>
              {t("homebanner_title_2")}
            </span>
          </h1>
          <p className={`${Style.home_banner_description} anim banner-anim`}>
            {t("homebanner_desc")}
          </p>
          {registerData?.name ? (
            <SearchBar
              extraClass={"anim banner-anim"}
              noDark={true}
              hasMobile={false}
              openPreview={openPreview}
            />
          ) : (
            ""
          )}
          <button
            className={Style.home_banner_scrolldown}
            onClick={() => handleClickToScroll()}
          >
            <div className={Style.home_banner_scrolldown_anim}>
              <div className={Style.scroll_me}>
                <div className={Style.chevron}></div>
                <div className={Style.chevron}></div>
                <div className={Style.chevron}></div>
              </div>
            </div>
            {t("homebanner_scroll")}
          </button>
        </div>
      </Container>

      {showFullView.isOpen && (
        <FullscreenPreviewNew
          previewObj={previewData}
          openSlideShow={openQuickSlideShow}
          showFullView={showFullView}
          setShowFullView={setShowFullView}
          total={previewData?.images?.length}
        />
      )}

      {quickSlideShow?.slideActions?.isOpen &&
        slideData?.images?.length > 0 && (
          <QuickSlideShow1
            slideObj={slideData}
            close={closeQuickSlideShow}
            initialIndex={showFullView?.index}
          />
        )}
    </div>
  );
};

export default HomeBanner;
