import React from 'react'
import Style from "../../FullScreenPreviewNew.module.scss";
import Icons from "../../../../Layouts/Icons";
import { useDescription } from './useDescription';
import { useRef } from 'react';
import { useEffect } from 'react';

const Description = ({ data, albumIndex }) => {
    const { ref, isEdit, text, descriptionStatus, handleEdit, handleBlur, handleChangeText, handleKeyDown } = useDescription({ data, albumIndex })
    console.log(text, data, "text check")

    return (
        <div className={`${Style.info_tags} ${Style.info_sec}`} onBlur={(e) => handleBlur(e)}>
            <h4 className={`${Style.info_tags_title} ${Style.info_sec_title}`}>
                Description
            </h4>
            <div className={Style.add_section}>
                {descriptionStatus == "loading" ? <p>saving...</p> :
                    <>
                        <p className={isEdit && Style.isActiveLabel}>{data?.description ? data?.description : "Add Description"}</p>
                        <textarea ref={ref} className={`${Style.textare_section} ${isEdit && Style.isActiveTextarea}`} onChange={(e) => handleChangeText(e)} onBlur={(e) => handleBlur(e)} onKeyDown={handleKeyDown} >{text}</textarea>
                        {!isEdit && <Icons icon={"edit"} size={14} onClick={() => handleEdit()}></Icons>}
                    </>

                }

            </div>


        </div>
    )
}

export default Description