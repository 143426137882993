import React from 'react'
import Style from './DefaultMenu.module.scss';
import Languages from '../../../constants/languages';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useDimensions } from '../../../Logic/Dimensions';

const DefaultMenu = ({ view, setView, capitalizeFirstLetter, defaultViewChange, setDefaultViewActive }) => {

    const { i18n, t } = useTranslation(["common"]);
    const home = useSelector((state) => state.userpreference);
    const windowDimensions = useDimensions();
    return (
        <>
            {windowDimensions.width <= 768 ? (<>
                <div className={Style.language_overlay} onClick={() => { setDefaultViewActive(false) }}></div>
                <div className={Style.language_menu_mob}>
                    <div className={Style.language_menu}>
                        <div className='container'>
                            <h5 className={Style.language_menu_title}><button onClick={() => { setDefaultViewActive(false) }}>Default View</button></h5>
                            <ul className={Style.language_menu_list}>
                                {

                                    ["all", "album"].map((Item, i) => {
                                        return (
                                            <li key={i} onClick={() => defaultViewChange(Item)}>
                                                <input checked={Item == view} className={Style.language_radio_input} type="radio" id={Item} />
                                                <label className={Style.language} htmlFor={Item}>{capitalizeFirstLetter(Item)}</label>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </>) : (<></>)}
        </>
    )
}

export default DefaultMenu;