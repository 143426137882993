import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSlideDetails } from "../../../store/slices/SlideShowDetailSlice";

export const useSlideShowEdit1 = () => {
  const swiperRef = useRef(null);
  const params = useParams();
  const dispatch = useDispatch();
  const [effect, setEffect] = useState("fade-effect");
  const slideDetail = useSelector((state) => state.slideShowDetail);
  const [slideIndex, setSlideIndex] = useState(0);
  const [activeThumb, setActiveThumb] = useState(null);
  const [pausePlay, setPausePlay] = useState(false);
  const [time, setTime] = useState(0);
  const [loop, setLoop] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      slideDetail.status == "idle" ||
      slideDetail?.resultData?.slideshow?.id != params?.id
    ) {
      dispatch(getSlideDetails(params?.id));
    }
  }, [params?.id]);

  // useEffect(() => {
  //   if (params?.id == "10101" && !slideDetail?.resultData?.images) {
  //     navigate("/dashboard/slideshow");
  //   }
  // }, [slideDetail?.resultData?.images]);

  const slideChangeHandler = (e) => {
    setSlideIndex(e.realIndex);
  };

  useEffect(() => {
    let timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const tick = () => {
    let totalTime =
      slideDetail?.resultData?.slideshow?.duration *
      slideDetail?.resultData?.images?.length;
    if (!pausePlay) return;
    if (time < totalTime) {
      setTime(time + 1);
      if (
        (swiperRef.current?.swiper?.activeIndex + 1) *
        slideDetail?.resultData?.slideshow?.duration ==
        time + 1
      ) {
        swiperRef.current?.swiper?.slideNext();
      }
    } else if (loop) {
      setTime(0);
      swiperRef.current?.swiper?.slideTo(0);
    } else {
      swiperRef.current?.swiper?.slideTo(0);
      setTime(0);
      setPausePlay(false);
    }
  };

  return {
    swiperRef,
    effect,
    setEffect,
    slides: slideDetail?.resultData?.images,
    slideData: slideDetail?.resultData?.slideshow,
    slideChangeHandler,
    slideIndex,
    setSlideIndex,
    activeThumb,
    setActiveThumb,
    pausePlay,
    setPausePlay,
    time,
    setTime,
    loop,
    setLoop,
  };
};
