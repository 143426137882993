import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
    addToStatus: "idle",
    status: "idle",
    error: null,
    resultData: {}
};


export const getAchiveList = createAsyncThunk(
    "archive/getAchiveList",
    async (params, { rejectWithValue }) => {
        const api = await getAxiosInstance();
        try {
            const response = await api.get(`list-archive?page=${params.page}&&sort=${params.slug}&per_page=40`);
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const addtoArchive = createAsyncThunk(
    "archive/addtoArchive",
    async (params, { rejectWithValue }) => {
        const api = await getAxiosInstance();
        try {
            const response = await api.post(`add-archive`, params);
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeArchive = createAsyncThunk(
    "archive/removeArchive",
    async (params, { rejectWithValue }) => {
        const api = await getAxiosInstance();
        try {
            const response = await api.post(`remove-archive`, params);
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const LoadmoreImages = createAsyncThunk(
    "archive/LoadmoreImages",
    async (params, { rejectWithValue }) => {
        const api = await getAxiosInstance();
        try {
            const response = await api.get(
                `list-archive?page=${params.page}&&sort=${params.slug}&per_page=40`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);




const archiveSlice = createSlice({
    name: "archive",
    initialState,
    reducers: {


    },

    extraReducers: {
        [getAchiveList.pending]: (state, action) => {
            state.status = "loading";
            state.message = null;
        },
        [getAchiveList.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.resultData = action.payload.data.result;
        },
        [getAchiveList.rejected]: (state, action) => {
            state.status = "failed";
        },

        [addtoArchive.pending]: (state, action) => {
            state.addToStatus = "loading";
            state.message = null;
        },
        [addtoArchive.fulfilled]: (state, action) => {
            state.addToStatus = "succeeded";

            if (state.resultData.data) {
                state.resultData = {
                    ...state.resultData,
                    data: [...action?.meta?.arg?.data, ...state.resultData.data],
                    total: state.resultData?.total + action?.meta?.arg?.data?.length

                }
            }

        },
        [addtoArchive.rejected]: (state, action) => {
            state.addToStatus = "failed";
        },

        [removeArchive.pending]: (state, action) => {
            state.addToStatus = "loading";
            state.message = null;
        },
        [removeArchive.fulfilled]: (state, action) => {
            state.addToStatus = "succeeded";
            let images = [...state.resultData.data]
            state.resultData = {
                ...state.resultData,
                data: images.filter((item, index) => !action.payload.data.result.includes(item.id)),
                total: state.resultData.total - action.payload.data.result?.length
            }
        },
        [removeArchive.rejected]: (state, action) => {
            state.addToStatus = "failed";
        },

        [LoadmoreImages.fulfilled]: (state, action) => {
            state.resultData = { data: [...state.resultData?.data, ...action?.payload?.data?.result?.data], current_page: action?.payload?.data?.result?.current_page, total: action?.payload?.data?.result?.total, sorting_options: [...state.resultData?.sorting_options], last_page: action?.payload?.data?.result?.last_page };
        },
        ["auth/logout"]: (state, action) => {
            state.status = "idle"
            state.resultData = {}
        }
    }

})
// export const { openSlideShow } = archiveSlice.actions;

export default archiveSlice.reducer;
