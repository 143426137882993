export const transitions = [
    {
      name: "Classic",
      effect: "fade-effect",
    },
    {
      name: "Ken Burns",
      effect: "zoom-out",
    },
    {
      name: "Zoom in",
      effect: "zoom-in",
    },
    {
      name: "Slide In",
      effect: "slide-in",
    },
    {
      name: "Stretch",
      effect: "stretch",
    },
    {
      name: "Swing",
      effect: "swing",
    },
    {
      name: "Blink",
      effect: "blink",
    },
    {
      name: "Blur",
      effect: "blur",
    },
  ];