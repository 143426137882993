import Style from "../SignInModal/SignInModal.module.scss";
import { useEffect, useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useLogin } from "../../../Logic/useLogin";
import { Dropdown } from "react-bootstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Assets from "../../Layouts/Assets";
import { useTranslation } from "react-i18next";
import {
  changeScreen,
  storeCurrentAuthData,
} from "../../../store/slices/authSlice";
import { useStopWindowScroll } from "../../../Logic/useStopWindowScroll";
import CountryCodeInput from "../../../utils/CountryCodeInput";
import { useRegister } from "../../../Logic/useRegister";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import { existingProfile } from "../../../store/slices/authSlice";

let hasErrors = false;
let hasErrorsPhone = false;

const AccountListModal = ({ handleClickRegister, handleClickFP, status }) => {
  const {
    currentAuthData,
    loginCreateOtpStatus,
    mobileVerifyData,
    existingUser,
    existingAccounts,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { handlePasswordModal } = useLogin();

  let data = existingAccounts?.data;
  console.log(data);

  const handleUserProfile = async (user) => {
    const filterData = data.filter((item) => item?.user_id === user?.user_id);
    await dispatch(existingProfile(filterData));
    dispatch(changeScreen("emailPasswordModal"))

    // handlePasswordModal()
  };

  // const [seconds, setSeconds] = useState(5);
  // useEffect(() => {
  //     const intervalId = setInterval(() => {
  //         setSeconds((prevSeconds) => {
  //             const newSeconds = prevSeconds - 1;
  //             if (newSeconds === 1) {
  //                 clearInterval(intervalId);
  //                 handleNavigate()
  //             }
  //             return newSeconds;
  //         });
  //     }, 1000);

  //     return () => clearInterval(intervalId);
  // }, []);
  // const handleNavigate = async ()=>{
  //     dispatch(changeScreen("login"))
  // }

  return (
    <div className={`${Style.signin_modal}` }>
      <div
        className={Style.signin_modal_content}
        onClick={(e) => e.stopPropagation()}
      >
        <>
          <div className={Style.signin_modal_header}>
            <h4>Which account you need to login?</h4>
            <p>All of these accounts are linked with your phone number.</p>
          </div>
          <div className={Style.user_profiles}>
            <ul className="">
              {data.map((user) => (
                <li onClick={() => handleUserProfile(user)}>
                  <div className={Style.user_profiles_avatar}>
                    <img src={user?.profile_pic != null ? user?.profile_pic :Assets.AVATAR_01} />
                  </div>
                  <h4>{user.name}</h4>
                </li>
              ))}
            </ul>
          </div>
        </>
      </div>
    </div>
  );
};

export default AccountListModal;
