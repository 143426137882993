import { useState,useEffect } from "react";

export const useControls = ({ swiperRef, trackIndex, playTrack }) => {
    const [currentIndex, setCurrentIndex] = useState(1);
    const [showPlayPause, setShowPlayPause] = useState(false);
    const [slideCounter, setSlideCounter] = useState(0);

    useEffect(() => {
        if ((slideCounter > 0 || slideCounter < 0) && showPlayPause) {
            const timer = setTimeout(() => {
                swiperRef?.current?.swiper?.autoplay?.start();
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [slideCounter]);

    const slideChangeHandler = (e) => {
        setCurrentIndex(e.realIndex);
    };
    const playToggle = () => {
        setShowPlayPause(false);
        swiperRef.current.swiper.autoplay.stop();
        playTrack(trackIndex);
    };
    const pauseToggle = () => {
        setShowPlayPause(true);
        swiperRef.current.swiper.autoplay.start();
        playTrack(trackIndex);
    };

    const previousHandler = () => {
        swiperRef.current.swiper.slidePrev();
        setSlideCounter(slideCounter - 1);
    };
    const nextHandler = () => {
        swiperRef.current.swiper.slideNext();
        setSlideCounter(slideCounter + 1);
    };

    const handleInitialization = (e) => {
        if (showPlayPause) {
            e.autoplay.start();
        }
    }


    return {
        currentIndex,
        showPlayPause,
        slideCounter,
        setShowPlayPause,
        slideChangeHandler,
        playToggle,
        pauseToggle,
        previousHandler,
        nextHandler,
        handleInitialization,
    }
}