import { useLayoutEffect } from "react";
import { useEffect } from "react";


export const useStopWindowScroll = (props) => {
    const setAddClass = document.querySelector("html").classList;
    const stopWindowScroll = function(val){
        if(val === true){
            setAddClass.add("modal-shownn");
        }else{
            setAddClass.remove("modal-shownn");
        }
    }
    useEffect(() => {
      return () => {
        setAddClass.remove("modal-shownn");
      }
    }, [])
    
    return stopWindowScroll;
};
