import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
    musicList: [],
    slideActions: {
        isOpen: false,
        type: "all"
    }

};


export const getMusicList = createAsyncThunk(
    "quickSlideShow/getMusicList",
    async (params, { rejectWithValue }) => {
        const api = await getAxiosInstance();
        try {
            const response = await api.get(`music-list`);
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);



const quickSlideShowSlice = createSlice({
    name: "quickSlideShow",
    initialState,
    reducers: {
        openSlideShow: (state, action) => {
            state.slideActions = {
                isOpen: action.payload.open,
                type: action.payload.type
            }
        },

    },

    extraReducers: {
        [getMusicList.pending]: (state, action) => {
            state.status = "loading";
            state.message = null;
        },
        [getMusicList.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.musicList = action.payload.data.result;
        },
        [getMusicList.rejected]: (state, action) => {
            state.status = "failed";
        },
    }

})
export const { openSlideShow } = quickSlideShowSlice.actions;

export default quickSlideShowSlice.reducer;