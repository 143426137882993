
import { useGalleryTab } from "../../../Logic/GalleryHooks/useGalleryTab.js";
import ImageListMasonry from "../../ImageListMasonry";

const ImageGallery = () => {
  const { Images, loadMore, NodataContent, status } = useGalleryTab();
  return (
    <ImageListMasonry
      total={Images?.resultData?.total}
      Images={Images?.resultData?.data}
      loadMore={loadMore}
      NodataContent={NodataContent}
      status={status}
      path="all"
      mode="normal"
    />
  );
};

export default ImageGallery;
