import React from "react";
import Style from "../QuickSlideShow.module.scss";
import Icons from "../../../Layouts/Icons";


export const ControlTab = ({ onPreviousHandler, onNextHandler, onPauseToggle, onPlayToggle, onHandleMute, onClose, isVisible, showPlayPause, isMute, setVisible }) => {
    return (
        <div
            className={`${Style.slideshow_fullscreen_controls} ${isVisible && "controls-visible"
                }`}
                onMouseOver={()=>setVisible(true)}
                onMouseOut={()=>setVisible(true)}
        >
            <button className={Style.control_btn} onClick={onPreviousHandler}>
                <Icons icon={"prev-arrow"} size={15} />
            </button>
            {!showPlayPause ? (
                <button className={Style.control_btn} onClick={onPauseToggle}>
                    <Icons icon={"slideshow-play"} size={15} />
                </button>
            ) : (
                <button className={Style.control_btn} onClick={onPlayToggle}>
                    <Icons icon={"slideshow-pause"} size={15} />
                </button>
            )}
            <button className={Style.control_btn} onClick={onNextHandler}>
                <Icons icon={"next-arrow"} size={15} />
            </button>
            <button className={Style.control_btn} onClick={onHandleMute}>
                <Icons icon={isMute ? "icon_mute" : "icon-volume"} size={15} />
            </button>
            <button
                className={Style.control_btn}
                onClick={onClose}
            >
                <Icons icon={"icon-miniscreen"} size={15} />
            </button>
        </div>
    )
}

//controlsVisible